import ChatMentionList from '@components/Chat/ChatMentionList.vue';
import { VueRenderer } from '@tiptap/vue-3';
import tippy, { Props, Instance as TippyInstance } from 'tippy.js';

const ChatMentionSuggestion = {
  render: () => {
    let component: VueRenderer;
    let popup: TippyInstance<Props>;

    const closePopup = () => {
      component.editor.chain().closeMentionsPanel();
      component.destroy();
    };

    const insertMention = (mention: any, editor: any, range: number) => {
      editor
        .chain()
        .focus()
        .deleteRange(range) // Delete the queried text (e.g., `@here`)
        .insertContent({
          type: 'mention',
          attrs: {
            id: mention.id,
            label: mention.name,
          },
        })
        .run();

      closePopup();
    };

    return {
      onStart: (props: any) => {
        component = new VueRenderer(ChatMentionList, {
          props,
          editor: props.editor,
        });

        if (!props.clientRect) {
          return;
        }

        const editorElement = document.getElementById('message-editor');

        const setPopupWidth = (instance: TippyInstance<Props>) => {
          const tippyBox = instance.popper.querySelector('.tippy-box') as HTMLElement;
          if (tippyBox) {
            tippyBox.style.maxWidth = 'unset'; // Unset the max-width property
          }

          if (editorElement) {
            instance.popper.style.width = `${editorElement.getBoundingClientRect().width}px`;
            instance.popper.style.maxWidth = 'unset';
          }
        };

        //@ts-ignore
        popup = tippy(document.getElementById('composer-container'), {
          appendTo: () => document.body,
          content: component.element,
          showOnCreate: true,
          interactive: true,
          trigger: 'manual',
          placement: 'top',
          onShow: (instance) => {
            props.editor.chain().openMentionsPanel();
            setPopupWidth(instance);
          },
          onHide: () => {},
        });

        // Handle window resize to adjust popup width
        window.addEventListener('resize', () => setPopupWidth(popup));
      },

      onUpdate(props: any) {
        const { query, items, editor, range } = props;

        //Todo permissions check?
        const commandOptions = [{ id: 'everyone', name: 'everyone' }];

        const matchedCommand = commandOptions.find((command) => command.name.toLowerCase() === query.toLowerCase());

        if (matchedCommand) {
          insertMention(matchedCommand, editor, range);
          return;
        }

        const userMentions = items.filter((item: any) => item.name.toLowerCase().includes(query.toLowerCase()));

        component.updateProps({ ...props, items: userMentions });
      },

      onKeyDown(props: any) {
        if (props.event.key === 'Escape') {
          closePopup();
        }

        return component.ref?.onKeyDown(props);
      },

      onExit() {
        closePopup();
      },
    };
  },
};

export default ChatMentionSuggestion;
