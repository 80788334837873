export interface Dimensions {
  height: number;
  width: number;
}

export interface Size {
  height: number;
  width: number;
}

export function determineContainerSize(dimensions: Dimensions[]): Size {
  if (!dimensions[0]?.height) {
    return { height: 0, width: 0 };
  }

  if ([10, 1, 7, 5, 8].includes(dimensions.length)) {
    const widthScalingFactor = 550 / dimensions[0].width;
    const heightScalingFactor = 350 / dimensions[0].height;
    const scalingFactor = Math.min(widthScalingFactor, heightScalingFactor);
    return {
      height: dimensions[0].height * scalingFactor,
      width: dimensions[0].width * scalingFactor,
    };
  }

  if (dimensions.length === 3) {
    const widthScalingFactor = 550 / dimensions[0].width;
    const heightScalingFactor = 350 / dimensions[0].height;
    const scalingFactor = Math.min(widthScalingFactor, heightScalingFactor);
    return {
      height: dimensions[0].height * scalingFactor,
      width: 0, // Width is not set in this case
    };
  }

  if (dimensions.length === 2) {
    const widthScalingFactor = 550 / 2 / dimensions[0].width;
    const scalingFactor = widthScalingFactor;
    return {
      height: dimensions[0].height * scalingFactor,
      width: 0, // Width is not set in this case
    };
  }

  return { height: 0, width: 0 };
}
