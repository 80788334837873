<script setup lang="ts">
  import { onBeforeMount, onBeforeUnmount, ref } from 'vue';
  import { FaceFrownIcon } from '@heroicons/vue/24/outline';
  import { Logger } from '@utils/logger';
  const log = new Logger('CHAT_IMAGE_FILE');

  const props = withDefaults(
    defineProps<{
      src: string;
      file: { id: string; name: string; type: string; url: string };
      contain?: boolean;
    }>(),
    {
      contain: false,
    }
  );

  const emits = defineEmits(['click', 'download', 'hide-download']);
  const failedToLoadImage = ref(false);

  const loading = ref(true);

  const handleClick = () => {
    emits('click');
  };

  const checkImageLoad = () => {
    const img = new Image();

    img.onload = function () {
      loading.value = false;
    };

    img.onerror = function () {
      emits('hide-download');
      log.error('Image failed to load (404):', props.src);
      failedToLoadImage.value = true;
      loading.value = false;
    };

    img.src = props.src;
  };

  onBeforeUnmount(() => {
    try {
      // Fixes safari memory leak, via black magic, apparently.
      const img = new Image();
      img.src = '';
      img.src = props.src;
      img.onload = null;
      img.onerror = null;
      img.src = '';
      URL.revokeObjectURL(props.src);
    } catch (e) {
      // nothing, we just dont want to die.
    }
  });

  // try to find image from the cache
  onBeforeMount(() => {
    checkImageLoad();
  });
</script>

<template>
  <img v-if="!loading && !failedToLoadImage" :src="props.src" alt="file-thumbnail" draggable="false" class="cursor-pointer overflow-clip rounded-md" :class="[contain ? 'object-contain object-left' : 'object-cover w-full h-full']" @click="handleClick" />
  <div v-else-if="failedToLoadImage" class="w-full bg-surface-100 dark:bg-surface-900/50 flex flex-col items-center justify-center">
    <FaceFrownIcon class="size-20 stroke-1 text-surface-500 dark:text-surface-400"></FaceFrownIcon>
    <div class="text-xs dark:text-surface-300">Failed to load</div>
  </div>
  <div v-else class="w-full h-full bg-surface-100 dark:bg-surface-900 flex flex-col items-center justify-center animate-pulse">&nbsp;</div>
</template>
