import { defineStore } from 'pinia';

function getInitialState() {
  return {
    user: null,
    isLoggedIn: false,
  };
}
export const useUserStore = defineStore('user', {
  state: () => getInitialState(),
  actions: {},
});
