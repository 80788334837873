export const calculateTooltipPosition = (clickedElement: HTMLElement | MouseEvent, outerLayerId: string, layerInnerId: string, placement: 'left' | 'right' | 'top' | 'mouse' = 'right', paddingX: number = 20, paddingY: number = 20): boolean => {
  const layer = document.getElementById(outerLayerId) as HTMLElement;
  const tooltip = document.getElementById(layerInnerId) as HTMLElement;

  if (!layer || !tooltip) {
    return false;
  }

  const viewPortWidth = window.innerWidth;
  const viewPortHeight = window.innerHeight;

  const { width: toolTipWidth, height: toolTipHeight } = tooltip.getBoundingClientRect();

  if (placement === 'mouse') {
    //takes a mouse click event MouseEvent
    const mouseEvent = clickedElement as MouseEvent;

    // Check vertical placement
    if (mouseEvent.clientY + toolTipHeight > viewPortHeight) {
      const tooltipDistanceFromBottom = viewPortHeight - toolTipHeight - paddingY; //padding from bottom
      layer.style.top = `${tooltipDistanceFromBottom}px`;
    } else {
      layer.style.top = `${mouseEvent.clientY}px`;
    }

    // Check horizontal placement
    if (mouseEvent.clientX + toolTipWidth > viewPortWidth) {
      const tooltipDistanceFromRight = viewPortWidth - toolTipWidth - paddingX; // Padding from right
      layer.style.left = `${tooltipDistanceFromRight}px`;
    } else if (mouseEvent.clientX - toolTipWidth < 0) {
      const tooltipDistanceFromLeft = paddingX; // Padding from left
      layer.style.left = `${tooltipDistanceFromLeft}px`;
    } else {
      layer.style.left = `${mouseEvent.clientX}px`;
    }

    layer.style.visibility = 'visible';
    return true;
  }
  //takes a target div HTMLElement
  else {
    const element = clickedElement as HTMLElement;
    const { x: clickedElementX, y: clickedElementY, top: clickedElementTop, left: clickedElementLeft, right: clickedElementRight, height: clickedElementHeight } = element.getBoundingClientRect();
    const clickedElementWidth = clickedElementRight - clickedElementLeft;

    if (clickedElementY + toolTipHeight > viewPortHeight) {
      const tooltipDistanceFromBottom = viewPortHeight - toolTipHeight - paddingY; //padding from bottom
      layer.style.top = `${tooltipDistanceFromBottom}px`;
    } else {
      layer.style.top = `${clickedElementTop}px`;
    }

    if (placement === 'top') {
      layer.style.left = `${clickedElementX + clickedElementWidth / 2 - toolTipWidth / 2}px`;
      layer.style.top = `${clickedElementY - toolTipHeight - paddingY}px`;
      layer.style.visibility = 'visible';
      return true;
    }

    if (placement === 'right') {
      // Check if the tooltip overflows the right edge of the viewport
      if (clickedElementX + toolTipWidth + paddingX > viewPortWidth) {
        // Position the tooltip 10px from the right edge of the viewport
        layer.style.left = `${viewPortWidth - toolTipWidth - paddingX}px`;
      } else {
        // Position the tooltip to the right of the clicked element with padding
        const toolTipDistanceFromLeft = clickedElementX + clickedElementWidth + paddingX;
        layer.style.left = `${toolTipDistanceFromLeft}px`;
      }
    }
    if (placement === 'left') {
      // If the tooltip overflowed the left edge of the viewport, position it with padding from the left edge
      if (clickedElementX - toolTipWidth - paddingX < 0) {
        layer.style.left = `${paddingX}px`;
      } else {
        // Otherwise, position the tooltip to the left of the clicked element with padding
        const toolTipDistanceFromRight = clickedElementLeft - toolTipWidth - paddingX;
        layer.style.left = `${toolTipDistanceFromRight}px`;
      }
    }
  }

  // if (placement === 'bottom') {
  //   if (clickedElementX - toolTipWidth - paddingX < 0) {
  //     layer.style.left = `${paddingX}px`;
  //   } else {
  //     // Otherwise, position the tooltip to the left of the clicked element with padding
  //     const toolTipDistanceFromRight = clickedElementLeft - toolTipWidth - paddingX;
  //     layer.style.left = `${toolTipDistanceFromRight}px`;
  //   }
  //
  //   console.log(clickedElementY);
  //   console.log(clickedElementHeight);
  //   console.log(event.target.getBoundingClientRect());
  //   // Check if the tooltip overflows the bottom edge of the viewport
  //   if (clickedElementTop + clickedElementHeight + toolTipHeight + paddingY > viewPortHeight) {
  //     // Position the tooltip 10px from the bottom edge of the viewport
  //     layer.style.top = `${viewPortHeight - toolTipHeight - paddingY}px`;
  //   } else {
  //     // Position the tooltip below the clicked element with padding
  //     const toolTipDistanceFromTop = clickedElementTop + clickedElementHeight + paddingY;
  //     layer.style.top = `${toolTipDistanceFromTop}px`;
  //   }
  // }
  layer.style.visibility = 'visible';
  return true;
};
