import { DateTime } from 'luxon';

export default function useHelpersSpace() {
  function handleTextChange(e: KeyboardEvent, height = '100px') {
    const textarea = e.target as HTMLTextAreaElement;

    if (!textarea) {
      return;
    }

    // If the user pressed backspace or delete
    if (e.key === 'Backspace' || e.key === 'Delete') {
      // If the scrollHeight is less than the current height of the textarea, adjust it
      if (textarea.scrollHeight < textarea.clientHeight) {
        textarea.style.height = height;
      }
    }

    // Expand the textarea if the content overflows
    if (textarea.scrollHeight > textarea.clientHeight) {
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  }

  function toString(value: unknown) {
    if (value === null || value === undefined) {
      return '';
    }
    if (typeof value === 'object') {
      try {
        return JSON.stringify(value);
      } catch (e) {
        return '[Object with circular references]';
      }
    }
    return String(value);
  }

  function createFormData(form: Record<string, string | number | [] | object>) {
    const formData = new FormData();
    for (const [key, value] of Object.entries(form)) {
      if (value === null) {
        formData.append(key, '');
      } else if (typeof value === 'number') {
        formData.append(key, value.toString());
      } else if (typeof value === 'object') {
        if (value instanceof DateTime) {
          formData.append(key, value.toFormat('y-MM-dd'));
        } else {
          formData.append(key, value.toString());
        }
      } else {
        formData.append(key, value);
      }
    }
    return formData;
  }

  function checkOverflow(divElement: HTMLElement) {
    if (!divElement) {
      return;
    }

    const currentOverflow = divElement.style.overflow;

    if (!currentOverflow || currentOverflow === 'visible') {
      divElement.style.overflow = 'hidden';
    }

    const isOverflowing = divElement.clientWidth < divElement.scrollWidth || divElement.clientHeight < divElement.scrollHeight;
    divElement.style.overflow = currentOverflow;

    return isOverflowing;
  }

  function getRequestType(request: { [key: string]: any }): string {
    switch (request.request_type) {
      case '1':
      case '2':
      case '3':
        return 'Adjustment';
      case 'HPTO':
        return 'HPTO';
      case 'PTO':
        return 'PTO';
      case 'UTO':
      case 'UPTO':
        return 'UTO';
      case 'SL':
        return 'Sick';
      case 'ML':
        return 'Maternity';
      case 'BL':
        return 'Bereavement';
      case 'OT':
        return 'Overtime';
      case 'AD':
        return 'Adjustment';
      case 'MK':
        return 'Makeup';
      case 'Temporary':
      case 'Permanent':
        return 'Shift Change';
    }
    if (request.request === '2') {
      return 'Overtime';
    }
    if (request.request === '4') {
      return 'Makeup';
    }
    return 'Unknown(?)';
  }

  function getTagColor(status: number | string): string {
    switch (status) {
      case 0:
      case 'Late':
      case 'Undertime':
        return 'warning';
      case 1:
      case 'Active':
        return 'success';
      case 2:
      case 'Absent':
        return 'danger';
      case 3:
      case 'Adj. Pending':
        return 'secondary';
      default:
        return 'primary';
    }
  }

  function getRequestStateStyles(status: number) {
    switch (status) {
      case 0:
        return 'status-yellow';
      case 1:
        return 'status-green';
      case 2:
        return 'status-red';
      case 3:
        return 'status-gray';
      default:
        return 'status-indigo';
    }
  }

  function getRequestState(status: number) {
    switch (status) {
      case 0:
        return 'Pending';
      case 1:
        return 'Approved';
      case 2:
        return 'Rejected';
      case 3:
        return 'Canceled';
      default:
        return '';
    }
  }

  function createDateInLocale(date: string | null | undefined, options = {}, format: LuxonDateString = 'DATE_MED') {
    if (!date) {
      return '';
    }
    return DateTime.fromSQL(date, options).toLocaleString(DateTime[format]);
  }

  function createDateInLocalFromSeconds(seconds: number, options = {}, format: LuxonDateString = 'TIME_SIMPLE') {
    return DateTime.fromSeconds(seconds, options).toLocaleString(DateTime[format]);
  }

  function createDateInFormat(date: string, options = {}, format = 'y-MM-dd') {
    if (!date) {
      return 'Invalid Date';
    }
    return DateTime.fromSQL(date, options).toFormat(format);
  }

  function statusColor(status: string) {
    switch (status) {
      case 'HPTO':
      case 'UPTO':
      case 'UTO':
      case 'PTO':
        return 'status-yellow';
      case 'Active':
        return 'status-green';
      case 'SL':
      case 'ML':
      case 'BL':
        return 'status-indigo';
      case 'Late':
      case 'Undertime':
        return 'status-yellow';
      case 'Absent':
        return 'status-red';
      case 'Adjustment':
        return 'status-blue';
      default:
        return 'status-gray';
    }
  }

  function statusShrink(status: string) {
    switch (status.toLowerCase()) {
      case 'absent: no activity':
        return 'Absent';
      case 'late active':
        return 'Late';
      case 'adjustment pending':
        return 'Adj. Pending';
      default:
        return status;
    }
  }

  function truncate(stringToTruncate: string, length: number) {
    if (stringToTruncate.length > length) {
      return stringToTruncate.substring(0, length);
    }
    return stringToTruncate;
  }

  function toNumber(value: string | number | null | boolean): number {
    if (!value) {
      return -1;
    }
    if (typeof value === 'boolean') {
      if (value) {
        return 1;
      }
      return 0;
    }
    if (typeof value === 'string') {
      if (value === 'true') {
        return 1;
      }
      if (value === 'false') {
        return 0;
      }
      return parseInt(value);
    }
    return value;
  }

  function toTitleCase(str: string) {
    if (!str) {
      return '';
    }
    const updatedString = str.toLowerCase().split(' ');
    for (const i in updatedString) {
      updatedString[i] = updatedString[i].charAt(0).toUpperCase() + updatedString[i].slice(1);
    }
    return updatedString.join(' ');
  }

  function fullName(client: User | undefined): string {
    if (!client) {
      return '';
    }
    return client.first_name + ' ' + client.last_name;
  }

  function minutesToHoursMinutesString(minutes: number): string {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    return `${hours}h ${remainingMinutes}m`;
  }

  function minutesToHours(minutes: number): string {
    return (minutes / 60).toFixed(2);
  }

  function trimString(string: string, length = 34): string {
    if (!string) {
      return '';
    }
    if (length > string.length) {
      return string;
    }
    return string.substring(0, length) + '...';
  }

  function getInitials(name: string | undefined): string {
    if (!name || !name.length) {
      return '';
    }
    // Drop all non-alpha characters
    name = name.replace(/[^a-zA-Z\s]/g, '');
    // Convert any excess spaces to 1 space, and then trim the start and ends of the string
    name = name.replace(/\s+/g, ' ').trimStart().trimEnd();
    // Take the first character from each remaining word
    return name
      .split(' ')
      .map((n) => n[0])
      .join(' ')
      .substring(0, 3);
  }

  function shiftIndicatorColor(status: string): string {
    if (!status) {
      return 'bg-brand-900 text-white dark:bg-brand-500';
    }
    switch (status.toLowerCase()) {
      case 'late':
        return 'bg-yellow-500 dark:text-black';
      case 'active':
      case 'late active':
        return 'bg-green-400';
      case 'break':
        return 'bg-yellow-500';
      case 'off the clock':
      case 'yet to start':
        return 'bg-brand-500 text-white dark:bg-brand-500';
      case 'absent: no activity':
        return 'bg-red-500';
      default:
        return 'bg-brand-900 text-white dark:bg-brand-500';
    }
  }

  function camelCaseToWords(input?: string): string {
    if (!input) {
      return '';
    }
    return input.replace(/([a-z])([A-Z])/g, '$1 $2');
  }

  function isNumber(value: string | boolean | number): boolean {
    if (typeof value === 'number') {
      return true;
    }

    if (typeof value === 'boolean') {
      return false;
    }

    return /^-?\d+(\.\d+)?$/.test(value);
  }

  return {
    getRequestType,
    getRequestStateStyles,
    getRequestState,
    createDateInLocale,
    statusColor,
    statusShrink,
    truncate,
    toNumber,
    createDateInFormat,
    toTitleCase,
    fullName,
    minutesToHours,
    getInitials,
    trimString,
    createDateInLocalFromSeconds,
    shiftIndicatorColor,
    createFormData,
    checkOverflow,
    handleTextChange,
    camelCaseToWords,
    isNumber,
    minutesToHoursMinutesString,
    getTagColor,
    toString,
  };
}
