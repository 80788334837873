import { defineStore } from 'pinia';
import { useAppStore } from '@src/store/app';
import { withCatch } from '@utils/await-safe';
import { Logger } from '@utils/logger';
const log = new Logger('STORE:ALERT');

export const useAlertStore = defineStore('alert', {
  state: () => ({
    alerts: [] as string[],
    dashboard: [] as LastShiftWarning[],
    success: [] as string[],
    communicationErrors: [] as string[],
    noScheduleAvailable: false as boolean,
  }),
  actions: {
    addAlerts(alerts: string[]) {
      alerts.forEach((alert) => {
        this.alerts.push(alert);
      });
    },
    removeAlert(index: number) {
      this.alerts.splice(index, 1);
    },
    addClockDriftAlert() {
      // check if a clock drift alert exists and remove it
      const index = this.alerts.findIndex((alert) => alert.includes('Clock drift detected'));
      if (index >= 0) {
        return;
      }
      this.alerts.push('Clock drift detected. Please refresh the page to correct the time.');
    },
    async refreshWarnings() {
      const appStateStore = useAppStore();
      const [error, response] = await withCatch(appStateStore.api.getWarningsForLastShift());

      if (error || !response) {
        log.error('Failed to get warnings for last shift');
        log.error(error);
        return;
      }

      const { data } = response;
      this.dashboard = [];

      Object.values(data).forEach((warning) => {
        this.dashboard.push(warning as unknown as LastShiftWarning);
      });
    },
    clearWarning(index: number) {
      this.dashboard.splice(index, 1);
    },
    clearDashboardWarnings() {
      this.dashboard = [];
    },
    clearAll() {
      this.alerts = [];
      this.dashboard = [];
      this.success = [];
      this.communicationErrors = [];
    },
  },
  getters: {
    dashboardWarnings: (state) => state.dashboard,
  },
});
