<script setup lang="ts">
  import { ref } from 'vue';

  const hovering = ref(false);
</script>

<template>
  <button class="border border-transparent px-5 hover:px-4 py-2 text-xs hover:border-red-500 rounded-md text-center" @mouseenter="hovering = true" @mouseleave="hovering = false">
    <span v-if="hovering" class="font-semibold">Remove</span>
    <span v-else class="font-semibold">Invited</span>
  </button>
</template>

<style scoped></style>
