import { App } from 'vue';
import { Router } from 'vue-router';

export type MtiModule = {
  onVueAppInit?: (app: App) => void;
  onRouterInit?: (app: App, router: Router) => void;
};

export const MTI_MODULES = () => {
  const files = import.meta.glob('@modules/*.ts', { eager: true });
  return Object.values(files).map((module: any) => module.default) as MtiModule[];
};
