import { PiniaPluginContext } from 'pinia';
import { invoke } from '@tauri-apps/api/core';
import { Logger } from '@utils/logger';

interface TauriStateUpdate {
  capture_active_window: boolean;
  is_on_break: boolean;
  is_be_right_back: boolean;
  logged_in: boolean;
  on_shift: boolean;
}

const log = new Logger('TAURI_SYNC_PLUGIN');

/**
 * This fires an event to the Tauri backend to sync the state of the application whenever an action on the activeJobStore is called
 *
 * @param store
 * @constructor
 */
export function TauriSyncPlugin({ store }: PiniaPluginContext) {
  if (store.$id === 'activejob') {
    store.$onAction((action) => {
      action.after(() => {
        const updatedState: TauriStateUpdate = {
          capture_active_window: store.isClockedIn && store.shouldTrackApplications,
          is_on_break: store.additionalDetails.onBreak,
          is_be_right_back: store.additionalDetails.beRightBack,
          logged_in: store.hasActiveJob,
          on_shift: store.isClockedIn,
        };
        log.debug('sync_state');
        log.debug(updatedState);
        invoke('sync_state', { updatedState: updatedState })
          .then(() => {
            log.debug('sync_state completed');
          })
          .catch((error) => {
            log.error('sync_state failed');
            log.error(error);
          });
      });
    });
  }
}
