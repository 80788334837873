export const mimeTypes = [
  { extension: '123', mimeType: 'application/vnd.lotus-1-2-3' },
  {
    extension: '1km',
    mimeType: 'application/vnd.1000minds.decision-model+xml',
  },
  { extension: '3dml', mimeType: 'text/vnd.in3d.3dml' },
  { extension: '3ds', mimeType: 'image/x-3ds' },
  { extension: '3g2', mimeType: 'video/3gpp2' },
  { extension: '3gp', mimeType: 'video/3gpp' },
  { extension: '3gpp', mimeType: 'video/3gpp' },
  { extension: '3mf', mimeType: 'model/3mf' },
  { extension: '7z', mimeType: 'application/x-7z-compressed' },
  { extension: 'aab', mimeType: 'application/x-authorware-bin' },
  { extension: 'aac', mimeType: 'audio/x-aac' },
  { extension: 'aam', mimeType: 'application/x-authorware-map' },
  { extension: 'aas', mimeType: 'application/x-authorware-seg' },
  { extension: 'abw', mimeType: 'application/x-abiword' },
  { extension: 'ac', mimeType: 'application/vnd.nokia.n-gage.ac+xml' },
  { extension: 'acc', mimeType: 'application/vnd.americandynamics.acc' },
  { extension: 'ace', mimeType: 'application/x-ace-compressed' },
  { extension: 'acu', mimeType: 'application/vnd.acucobol' },
  { extension: 'acutc', mimeType: 'application/vnd.acucorp' },
  { extension: 'adp', mimeType: 'audio/adpcm' },
  { extension: 'adts', mimeType: 'audio/aac' },
  { extension: 'aep', mimeType: 'application/vnd.audiograph' },
  { extension: 'afm', mimeType: 'application/x-font-type1' },
  { extension: 'afp', mimeType: 'application/vnd.ibm.modcap' },
  { extension: 'age', mimeType: 'application/vnd.age' },
  { extension: 'ahead', mimeType: 'application/vnd.ahead.space' },
  { extension: 'ai', mimeType: 'application/postscript' },
  { extension: 'aif', mimeType: 'audio/x-aiff' },
  { extension: 'aifc', mimeType: 'audio/x-aiff' },
  { extension: 'aiff', mimeType: 'audio/x-aiff' },
  {
    extension: 'air',
    mimeType: 'application/vnd.adobe.air-application-installer-package+zip',
  },
  { extension: 'ait', mimeType: 'application/vnd.dvb.ait' },
  { extension: 'ami', mimeType: 'application/vnd.amiga.ami' },
  { extension: 'aml', mimeType: 'application/automationml-aml+xml' },
  { extension: 'amlx', mimeType: 'application/automationml-amlx+zip' },
  { extension: 'amr', mimeType: 'audio/amr' },
  { extension: 'apk', mimeType: 'application/vnd.android.package-archive' },
  { extension: 'apng', mimeType: 'image/apng' },
  { extension: 'appcache', mimeType: 'text/cache-manifest' },
  { extension: 'appinstaller', mimeType: 'application/appinstaller' },
  { extension: 'application', mimeType: 'application/x-ms-application' },
  { extension: 'appx', mimeType: 'application/appx' },
  { extension: 'appxbundle', mimeType: 'application/appxbundle' },
  { extension: 'apr', mimeType: 'application/vnd.lotus-approach' },
  { extension: 'arc', mimeType: 'application/x-freearc' },
  { extension: 'arj', mimeType: 'application/x-arj' },
  { extension: 'asc', mimeType: 'application/pgp-signature' },
  { extension: 'asf', mimeType: 'video/x-ms-asf' },
  { extension: 'asm', mimeType: 'text/x-asm' },
  { extension: 'aso', mimeType: 'application/vnd.accpac.simply.aso' },
  { extension: 'asx', mimeType: 'video/x-ms-asf' },
  { extension: 'atc', mimeType: 'application/vnd.acucorp' },
  { extension: 'atom', mimeType: 'application/atom+xml' },
  { extension: 'atomcat', mimeType: 'application/atomcat+xml' },
  { extension: 'atomdeleted', mimeType: 'application/atomdeleted+xml' },
  { extension: 'atomsvc', mimeType: 'application/atomsvc+xml' },
  { extension: 'atx', mimeType: 'application/vnd.antix.game-component' },
  { extension: 'au', mimeType: 'audio/basic' },
  { extension: 'avci', mimeType: 'image/avci' },
  { extension: 'avcs', mimeType: 'image/avcs' },
  { extension: 'avi', mimeType: 'video/x-msvideo' },
  { extension: 'avif', mimeType: 'image/avif' },
  { extension: 'aw', mimeType: 'application/applixware' },
  { extension: 'azf', mimeType: 'application/vnd.airzip.filesecure.azf' },
  { extension: 'azs', mimeType: 'application/vnd.airzip.filesecure.azs' },
  { extension: 'azv', mimeType: 'image/vnd.airzip.accelerator.azv' },
  { extension: 'azw', mimeType: 'application/vnd.amazon.ebook' },
  { extension: 'b16', mimeType: 'image/vnd.pco.b16' },
  { extension: 'bat', mimeType: 'application/x-msdownload' },
  { extension: 'bcpio', mimeType: 'application/x-bcpio' },
  { extension: 'bdf', mimeType: 'application/x-font-bdf' },
  { extension: 'bdm', mimeType: 'application/vnd.syncml.dm+wbxml' },
  { extension: 'bdoc', mimeType: 'application/x-bdoc' },
  { extension: 'bed', mimeType: 'application/vnd.realvnc.bed' },
  { extension: 'bh2', mimeType: 'application/vnd.fujitsu.oasysprs' },
  { extension: 'bin', mimeType: 'application/octet-stream' },
  { extension: 'blb', mimeType: 'application/x-blorb' },
  { extension: 'blorb', mimeType: 'application/x-blorb' },
  { extension: 'bmi', mimeType: 'application/vnd.bmi' },
  { extension: 'bmml', mimeType: 'application/vnd.balsamiq.bmml+xml' },
  { extension: 'bmp', mimeType: 'image/x-ms-bmp' },
  { extension: 'book', mimeType: 'application/vnd.framemaker' },
  { extension: 'box', mimeType: 'application/vnd.previewsystems.box' },
  { extension: 'boz', mimeType: 'application/x-bzip2' },
  { extension: 'bpk', mimeType: 'application/octet-stream' },
  { extension: 'bsp', mimeType: 'model/vnd.valve.source.compiled-map' },
  { extension: 'btf', mimeType: 'image/prs.btif' },
  { extension: 'btif', mimeType: 'image/prs.btif' },
  { extension: 'buffer', mimeType: 'application/octet-stream' },
  { extension: 'bz', mimeType: 'application/x-bzip' },
  { extension: 'bz2', mimeType: 'application/x-bzip2' },
  { extension: 'c', mimeType: 'text/x-c' },
  {
    extension: 'c11amc',
    mimeType: 'application/vnd.cluetrust.cartomobile-config',
  },
  {
    extension: 'c11amz',
    mimeType: 'application/vnd.cluetrust.cartomobile-config-pkg',
  },
  { extension: 'c4d', mimeType: 'application/vnd.clonk.c4group' },
  { extension: 'c4f', mimeType: 'application/vnd.clonk.c4group' },
  { extension: 'c4g', mimeType: 'application/vnd.clonk.c4group' },
  { extension: 'c4p', mimeType: 'application/vnd.clonk.c4group' },
  { extension: 'c4u', mimeType: 'application/vnd.clonk.c4group' },
  { extension: 'cab', mimeType: 'application/vnd.ms-cab-compressed' },
  { extension: 'caf', mimeType: 'audio/x-caf' },
  { extension: 'cap', mimeType: 'application/vnd.tcpdump.pcap' },
  { extension: 'car', mimeType: 'application/vnd.curl.car' },
  { extension: 'cat', mimeType: 'application/vnd.ms-pki.seccat' },
  { extension: 'cb7', mimeType: 'application/x-cbr' },
  { extension: 'cba', mimeType: 'application/x-cbr' },
  { extension: 'cbr', mimeType: 'application/x-cbr' },
  { extension: 'cbt', mimeType: 'application/x-cbr' },
  { extension: 'cbz', mimeType: 'application/x-cbr' },
  { extension: 'cc', mimeType: 'text/x-c' },
  { extension: 'cco', mimeType: 'application/x-cocoa' },
  { extension: 'cct', mimeType: 'application/x-director' },
  { extension: 'ccxml', mimeType: 'application/ccxml+xml' },
  { extension: 'cdbcmsg', mimeType: 'application/vnd.contact.cmsg' },
  { extension: 'cdf', mimeType: 'application/x-netcdf' },
  { extension: 'cdfx', mimeType: 'application/cdfx+xml' },
  { extension: 'cdkey', mimeType: 'application/vnd.mediastation.cdkey' },
  { extension: 'cdmia', mimeType: 'application/cdmi-capability' },
  { extension: 'cdmic', mimeType: 'application/cdmi-container' },
  { extension: 'cdmid', mimeType: 'application/cdmi-domain' },
  { extension: 'cdmio', mimeType: 'application/cdmi-object' },
  { extension: 'cdmiq', mimeType: 'application/cdmi-queue' },
  { extension: 'cdx', mimeType: 'chemical/x-cdx' },
  { extension: 'cdxml', mimeType: 'application/vnd.chemdraw+xml' },
  { extension: 'cdy', mimeType: 'application/vnd.cinderella' },
  { extension: 'cer', mimeType: 'application/pkix-cert' },
  { extension: 'cfs', mimeType: 'application/x-cfs-compressed' },
  { extension: 'cgm', mimeType: 'image/cgm' },
  { extension: 'chat', mimeType: 'application/x-chat' },
  { extension: 'chm', mimeType: 'application/vnd.ms-htmlhelp' },
  { extension: 'chrt', mimeType: 'application/vnd.kde.kchart' },
  { extension: 'cif', mimeType: 'chemical/x-cif' },
  {
    extension: 'cii',
    mimeType: 'application/vnd.anser-web-certificate-issue-initiation',
  },
  { extension: 'cil', mimeType: 'application/vnd.ms-artgalry' },
  { extension: 'cjs', mimeType: 'application/node' },
  { extension: 'cla', mimeType: 'application/vnd.claymore' },
  { extension: 'class', mimeType: 'application/java-vm' },
  { extension: 'cld', mimeType: 'model/vnd.cld' },
  { extension: 'clkk', mimeType: 'application/vnd.crick.clicker.keyboard' },
  { extension: 'clkp', mimeType: 'application/vnd.crick.clicker.palette' },
  { extension: 'clkt', mimeType: 'application/vnd.crick.clicker.template' },
  { extension: 'clkw', mimeType: 'application/vnd.crick.clicker.wordbank' },
  { extension: 'clkx', mimeType: 'application/vnd.crick.clicker' },
  { extension: 'clp', mimeType: 'application/x-msclip' },
  { extension: 'cmc', mimeType: 'application/vnd.cosmocaller' },
  { extension: 'cmdf', mimeType: 'chemical/x-cmdf' },
  { extension: 'cml', mimeType: 'chemical/x-cml' },
  { extension: 'cmp', mimeType: 'application/vnd.yellowriver-custom-menu' },
  { extension: 'cmx', mimeType: 'image/x-cmx' },
  { extension: 'cod', mimeType: 'application/vnd.rim.cod' },
  { extension: 'coffee', mimeType: 'text/coffeescript' },
  { extension: 'com', mimeType: 'application/x-msdownload' },
  { extension: 'conf', mimeType: 'text/plain' },
  { extension: 'cpio', mimeType: 'application/x-cpio' },
  { extension: 'cpl', mimeType: 'application/cpl+xml' },
  { extension: 'cpp', mimeType: 'text/x-c' },
  { extension: 'cpt', mimeType: 'application/mac-compactpro' },
  { extension: 'crd', mimeType: 'application/x-mscardfile' },
  { extension: 'crl', mimeType: 'application/pkix-crl' },
  { extension: 'crt', mimeType: 'application/x-x509-ca-cert' },
  { extension: 'crx', mimeType: 'application/x-chrome-extension' },
  { extension: 'cryptonote', mimeType: 'application/vnd.rig.cryptonote' },
  { extension: 'csh', mimeType: 'application/x-csh' },
  { extension: 'csl', mimeType: 'application/vnd.citationstyles.style+xml' },
  { extension: 'csml', mimeType: 'chemical/x-csml' },
  { extension: 'csp', mimeType: 'application/vnd.commonspace' },
  { extension: 'css', mimeType: 'text/css' },
  { extension: 'cst', mimeType: 'application/x-director' },
  { extension: 'csv', mimeType: 'text/csv' },
  { extension: 'cu', mimeType: 'application/cu-seeme' },
  { extension: 'curl', mimeType: 'text/vnd.curl' },
  { extension: 'cwl', mimeType: 'application/cwl' },
  { extension: 'cww', mimeType: 'application/prs.cww' },
  { extension: 'cxt', mimeType: 'application/x-director' },
  { extension: 'cxx', mimeType: 'text/x-c' },
  { extension: 'dae', mimeType: 'model/vnd.collada+xml' },
  { extension: 'daf', mimeType: 'application/vnd.mobius.daf' },
  { extension: 'dart', mimeType: 'application/vnd.dart' },
  { extension: 'dataless', mimeType: 'application/vnd.fdsn.seed' },
  { extension: 'davmount', mimeType: 'application/davmount+xml' },
  { extension: 'dbf', mimeType: 'application/vnd.dbf' },
  { extension: 'dbk', mimeType: 'application/docbook+xml' },
  { extension: 'dcr', mimeType: 'application/x-director' },
  { extension: 'dcurl', mimeType: 'text/vnd.curl.dcurl' },
  { extension: 'dd2', mimeType: 'application/vnd.oma.dd2+xml' },
  { extension: 'ddd', mimeType: 'application/vnd.fujixerox.ddd' },
  { extension: 'ddf', mimeType: 'application/vnd.syncml.dmddf+xml' },
  { extension: 'dds', mimeType: 'image/vnd.ms-dds' },
  { extension: 'deb', mimeType: 'application/x-debian-package' },
  { extension: 'def', mimeType: 'text/plain' },
  { extension: 'deploy', mimeType: 'application/octet-stream' },
  { extension: 'der', mimeType: 'application/x-x509-ca-cert' },
  { extension: 'dfac', mimeType: 'application/vnd.dreamfactory' },
  { extension: 'dgc', mimeType: 'application/x-dgc-compressed' },
  { extension: 'dib', mimeType: 'image/bmp' },
  { extension: 'dic', mimeType: 'text/x-c' },
  { extension: 'dir', mimeType: 'application/x-director' },
  { extension: 'dis', mimeType: 'application/vnd.mobius.dis' },
  {
    extension: 'disposition-notification',
    mimeType: 'message/disposition-notification',
  },
  { extension: 'dist', mimeType: 'application/octet-stream' },
  { extension: 'distz', mimeType: 'application/octet-stream' },
  { extension: 'djv', mimeType: 'image/vnd.djvu' },
  { extension: 'djvu', mimeType: 'image/vnd.djvu' },
  { extension: 'dll', mimeType: 'application/x-msdownload' },
  { extension: 'dmg', mimeType: 'application/x-apple-diskimage' },
  { extension: 'dmp', mimeType: 'application/vnd.tcpdump.pcap' },
  { extension: 'dms', mimeType: 'application/octet-stream' },
  { extension: 'dna', mimeType: 'application/vnd.dna' },
  { extension: 'doc', mimeType: 'application/msword' },
  {
    extension: 'docm',
    mimeType: 'application/vnd.ms-word.document.macroenabled.12',
  },
  {
    extension: 'docx',
    mimeType: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  },
  { extension: 'dot', mimeType: 'application/msword' },
  {
    extension: 'dotm',
    mimeType: 'application/vnd.ms-word.template.macroenabled.12',
  },
  {
    extension: 'dotx',
    mimeType: 'application/vnd.openxmlformats-officedocument.wordprocessingml.template',
  },
  { extension: 'dp', mimeType: 'application/vnd.osgi.dp' },
  { extension: 'dpg', mimeType: 'application/vnd.dpgraph' },
  { extension: 'dpx', mimeType: 'image/dpx' },
  { extension: 'dra', mimeType: 'audio/vnd.dra' },
  { extension: 'drle', mimeType: 'image/dicom-rle' },
  { extension: 'dsc', mimeType: 'text/prs.lines.tag' },
  { extension: 'dssc', mimeType: 'application/dssc+der' },
  { extension: 'dtb', mimeType: 'application/x-dtbook+xml' },
  { extension: 'dtd', mimeType: 'application/xml-dtd' },
  { extension: 'dts', mimeType: 'audio/vnd.dts' },
  { extension: 'dtshd', mimeType: 'audio/vnd.dts.hd' },
  { extension: 'dump', mimeType: 'application/octet-stream' },
  { extension: 'dvb', mimeType: 'video/vnd.dvb.file' },
  { extension: 'dvi', mimeType: 'application/x-dvi' },
  { extension: 'dwd', mimeType: 'application/atsc-dwd+xml' },
  { extension: 'dwf', mimeType: 'model/vnd.dwf' },
  { extension: 'dwg', mimeType: 'image/vnd.dwg' },
  { extension: 'dxf', mimeType: 'image/vnd.dxf' },
  { extension: 'dxp', mimeType: 'application/vnd.spotfire.dxp' },
  { extension: 'dxr', mimeType: 'application/x-director' },
  { extension: 'ear', mimeType: 'application/java-archive' },
  { extension: 'ecelp4800', mimeType: 'audio/vnd.nuera.ecelp4800' },
  { extension: 'ecelp7470', mimeType: 'audio/vnd.nuera.ecelp7470' },
  { extension: 'ecelp9600', mimeType: 'audio/vnd.nuera.ecelp9600' },
  { extension: 'ecma', mimeType: 'application/ecmascript' },
  { extension: 'edm', mimeType: 'application/vnd.novadigm.edm' },
  { extension: 'edx', mimeType: 'application/vnd.novadigm.edx' },
  { extension: 'efif', mimeType: 'application/vnd.picsel' },
  { extension: 'ei6', mimeType: 'application/vnd.pg.osasli' },
  { extension: 'elc', mimeType: 'application/octet-stream' },
  { extension: 'emf', mimeType: 'image/emf' },
  { extension: 'eml', mimeType: 'message/rfc822' },
  { extension: 'emma', mimeType: 'application/emma+xml' },
  { extension: 'emotionml', mimeType: 'application/emotionml+xml' },
  { extension: 'emz', mimeType: 'application/x-msmetafile' },
  { extension: 'eol', mimeType: 'audio/vnd.digital-winds' },
  { extension: 'eot', mimeType: 'application/vnd.ms-fontobject' },
  { extension: 'eps', mimeType: 'application/postscript' },
  { extension: 'epub', mimeType: 'application/epub+zip' },
  { extension: 'es3', mimeType: 'application/vnd.eszigno3+xml' },
  { extension: 'esa', mimeType: 'application/vnd.osgi.subsystem' },
  { extension: 'esf', mimeType: 'application/vnd.epson.esf' },
  { extension: 'et3', mimeType: 'application/vnd.eszigno3+xml' },
  { extension: 'etx', mimeType: 'text/x-setext' },
  { extension: 'eva', mimeType: 'application/x-eva' },
  { extension: 'evy', mimeType: 'application/x-envoy' },
  { extension: 'exe', mimeType: 'application/x-msdownload' },
  { extension: 'exi', mimeType: 'application/exi' },
  { extension: 'exp', mimeType: 'application/express' },
  { extension: 'exr', mimeType: 'image/aces' },
  { extension: 'ext', mimeType: 'application/vnd.novadigm.ext' },
  { extension: 'ez', mimeType: 'application/andrew-inset' },
  { extension: 'ez2', mimeType: 'application/vnd.ezpix-album' },
  { extension: 'ez3', mimeType: 'application/vnd.ezpix-package' },
  { extension: 'f', mimeType: 'text/x-fortran' },
  { extension: 'f4v', mimeType: 'video/x-f4v' },
  { extension: 'f77', mimeType: 'text/x-fortran' },
  { extension: 'f90', mimeType: 'text/x-fortran' },
  { extension: 'fbs', mimeType: 'image/vnd.fastbidsheet' },
  { extension: 'fcdt', mimeType: 'application/vnd.adobe.formscentral.fcdt' },
  { extension: 'fcs', mimeType: 'application/vnd.isac.fcs' },
  { extension: 'fdf', mimeType: 'application/vnd.fdf' },
  { extension: 'fdt', mimeType: 'application/fdt+xml' },
  {
    extension: 'fe_launch',
    mimeType: 'application/vnd.denovo.fcselayout-link',
  },
  { extension: 'fg5', mimeType: 'application/vnd.fujitsu.oasysgp' },
  { extension: 'fgd', mimeType: 'application/x-director' },
  { extension: 'fh', mimeType: 'image/x-freehand' },
  { extension: 'fh4', mimeType: 'image/x-freehand' },
  { extension: 'fh5', mimeType: 'image/x-freehand' },
  { extension: 'fh7', mimeType: 'image/x-freehand' },
  { extension: 'fhc', mimeType: 'image/x-freehand' },
  { extension: 'fig', mimeType: 'application/x-xfig' },
  { extension: 'fits', mimeType: 'image/fits' },
  { extension: 'flac', mimeType: 'audio/x-flac' },
  { extension: 'fli', mimeType: 'video/x-fli' },
  { extension: 'flo', mimeType: 'application/vnd.micrografx.flo' },
  { extension: 'flv', mimeType: 'video/x-flv' },
  { extension: 'flw', mimeType: 'application/vnd.kde.kivio' },
  { extension: 'flx', mimeType: 'text/vnd.fmi.flexstor' },
  { extension: 'fly', mimeType: 'text/vnd.fly' },
  { extension: 'fm', mimeType: 'application/vnd.framemaker' },
  { extension: 'fnc', mimeType: 'application/vnd.frogans.fnc' },
  { extension: 'fo', mimeType: 'application/vnd.software602.filler.form+xml' },
  { extension: 'for', mimeType: 'text/x-fortran' },
  { extension: 'fpx', mimeType: 'image/vnd.fpx' },
  { extension: 'frame', mimeType: 'application/vnd.framemaker' },
  { extension: 'fsc', mimeType: 'application/vnd.fsc.weblaunch' },
  { extension: 'fst', mimeType: 'image/vnd.fst' },
  { extension: 'ftc', mimeType: 'application/vnd.fluxtime.clip' },
  {
    extension: 'fti',
    mimeType: 'application/vnd.anser-web-funds-transfer-initiation',
  },
  { extension: 'fvt', mimeType: 'video/vnd.fvt' },
  { extension: 'fxp', mimeType: 'application/vnd.adobe.fxp' },
  { extension: 'fxpl', mimeType: 'application/vnd.adobe.fxp' },
  { extension: 'fzs', mimeType: 'application/vnd.fuzzysheet' },
  { extension: 'g2w', mimeType: 'application/vnd.geoplan' },
  { extension: 'g3', mimeType: 'image/g3fax' },
  { extension: 'g3w', mimeType: 'application/vnd.geospace' },
  { extension: 'gac', mimeType: 'application/vnd.groove-account' },
  { extension: 'gam', mimeType: 'application/x-tads' },
  { extension: 'gbr', mimeType: 'application/rpki-ghostbusters' },
  { extension: 'gca', mimeType: 'application/x-gca-compressed' },
  { extension: 'gdl', mimeType: 'model/vnd.gdl' },
  { extension: 'gdoc', mimeType: 'application/vnd.google-apps.document' },
  { extension: 'ged', mimeType: 'text/vnd.familysearch.gedcom' },
  { extension: 'geo', mimeType: 'application/vnd.dynageo' },
  { extension: 'geojson', mimeType: 'application/geo+json' },
  { extension: 'gex', mimeType: 'application/vnd.geometry-explorer' },
  { extension: 'ggb', mimeType: 'application/vnd.geogebra.file' },
  { extension: 'ggt', mimeType: 'application/vnd.geogebra.tool' },
  { extension: 'ghf', mimeType: 'application/vnd.groove-help' },
  { extension: 'gif', mimeType: 'image/gif' },
  { extension: 'gim', mimeType: 'application/vnd.groove-identity-message' },
  { extension: 'glb', mimeType: 'model/gltf-binary' },
  { extension: 'gltf', mimeType: 'model/gltf+json' },
  { extension: 'gml', mimeType: 'application/gml+xml' },
  { extension: 'gmx', mimeType: 'application/vnd.gmx' },
  { extension: 'gnumeric', mimeType: 'application/x-gnumeric' },
  { extension: 'gph', mimeType: 'application/vnd.flographit' },
  { extension: 'gpx', mimeType: 'application/gpx+xml' },
  { extension: 'gqf', mimeType: 'application/vnd.grafeq' },
  { extension: 'gqs', mimeType: 'application/vnd.grafeq' },
  { extension: 'gram', mimeType: 'application/srgs' },
  { extension: 'gramps', mimeType: 'application/x-gramps-xml' },
  { extension: 'gre', mimeType: 'application/vnd.geometry-explorer' },
  { extension: 'grv', mimeType: 'application/vnd.groove-injector' },
  { extension: 'grxml', mimeType: 'application/srgs+xml' },
  { extension: 'gsf', mimeType: 'application/x-font-ghostscript' },
  { extension: 'gsheet', mimeType: 'application/vnd.google-apps.spreadsheet' },
  {
    extension: 'gslides',
    mimeType: 'application/vnd.google-apps.presentation',
  },
  { extension: 'gtar', mimeType: 'application/x-gtar' },
  { extension: 'gtm', mimeType: 'application/vnd.groove-tool-message' },
  { extension: 'gtw', mimeType: 'model/vnd.gtw' },
  { extension: 'gv', mimeType: 'text/vnd.graphviz' },
  { extension: 'gxf', mimeType: 'application/gxf' },
  { extension: 'gxt', mimeType: 'application/vnd.geonext' },
  { extension: 'gz', mimeType: 'application/gzip' },
  { extension: 'h', mimeType: 'text/x-c' },
  { extension: 'h261', mimeType: 'video/h261' },
  { extension: 'h263', mimeType: 'video/h263' },
  { extension: 'h264', mimeType: 'video/h264' },
  { extension: 'hal', mimeType: 'application/vnd.hal+xml' },
  { extension: 'hbci', mimeType: 'application/vnd.hbci' },
  { extension: 'hbs', mimeType: 'text/x-handlebars-template' },
  { extension: 'hdd', mimeType: 'application/x-virtualbox-hdd' },
  { extension: 'hdf', mimeType: 'application/x-hdf' },
  { extension: 'heic', mimeType: 'image/heic' },
  { extension: 'heics', mimeType: 'image/heic-sequence' },
  { extension: 'heif', mimeType: 'image/heif' },
  { extension: 'heifs', mimeType: 'image/heif-sequence' },
  { extension: 'hej2', mimeType: 'image/hej2k' },
  { extension: 'held', mimeType: 'application/atsc-held+xml' },
  { extension: 'hh', mimeType: 'text/x-c' },
  { extension: 'hjson', mimeType: 'application/hjson' },
  { extension: 'hlp', mimeType: 'application/winhlp' },
  { extension: 'hpgl', mimeType: 'application/vnd.hp-hpgl' },
  { extension: 'hpid', mimeType: 'application/vnd.hp-hpid' },
  { extension: 'hps', mimeType: 'application/vnd.hp-hps' },
  { extension: 'hqx', mimeType: 'application/mac-binhex40' },
  { extension: 'hsj2', mimeType: 'image/hsj2' },
  { extension: 'htc', mimeType: 'text/x-component' },
  { extension: 'htke', mimeType: 'application/vnd.kenameaapp' },
  { extension: 'htm', mimeType: 'text/html' },
  { extension: 'html', mimeType: 'text/html' },
  { extension: 'hvd', mimeType: 'application/vnd.yamaha.hv-dic' },
  { extension: 'hvp', mimeType: 'application/vnd.yamaha.hv-voice' },
  { extension: 'hvs', mimeType: 'application/vnd.yamaha.hv-script' },
  { extension: 'i2g', mimeType: 'application/vnd.intergeo' },
  { extension: 'icc', mimeType: 'application/vnd.iccprofile' },
  { extension: 'ice', mimeType: 'x-conference/x-cooltalk' },
  { extension: 'icm', mimeType: 'application/vnd.iccprofile' },
  { extension: 'ico', mimeType: 'image/x-icon' },
  { extension: 'ics', mimeType: 'text/calendar' },
  { extension: 'ief', mimeType: 'image/ief' },
  { extension: 'ifb', mimeType: 'text/calendar' },
  { extension: 'ifm', mimeType: 'application/vnd.shana.informed.formdata' },
  { extension: 'iges', mimeType: 'model/iges' },
  { extension: 'igl', mimeType: 'application/vnd.igloader' },
  { extension: 'igm', mimeType: 'application/vnd.insors.igm' },
  { extension: 'igs', mimeType: 'model/iges' },
  { extension: 'igx', mimeType: 'application/vnd.micrografx.igx' },
  { extension: 'iif', mimeType: 'application/vnd.shana.informed.interchange' },
  { extension: 'img', mimeType: 'application/octet-stream' },
  { extension: 'imp', mimeType: 'application/vnd.accpac.simply.imp' },
  { extension: 'ims', mimeType: 'application/vnd.ms-ims' },
  { extension: 'in', mimeType: 'text/plain' },
  { extension: 'ini', mimeType: 'text/plain' },
  { extension: 'ink', mimeType: 'application/inkml+xml' },
  { extension: 'inkml', mimeType: 'application/inkml+xml' },
  { extension: 'install', mimeType: 'application/x-install-instructions' },
  { extension: 'iota', mimeType: 'application/vnd.astraea-software.iota' },
  { extension: 'ipfix', mimeType: 'application/ipfix' },
  { extension: 'ipk', mimeType: 'application/vnd.shana.informed.package' },
  { extension: 'irm', mimeType: 'application/vnd.ibm.rights-management' },
  { extension: 'irp', mimeType: 'application/vnd.irepository.package+xml' },
  { extension: 'iso', mimeType: 'application/x-iso9660-image' },
  { extension: 'itp', mimeType: 'application/vnd.shana.informed.formtemplate' },
  { extension: 'its', mimeType: 'application/its+xml' },
  { extension: 'ivp', mimeType: 'application/vnd.immervision-ivp' },
  { extension: 'ivu', mimeType: 'application/vnd.immervision-ivu' },
  { extension: 'jad', mimeType: 'text/vnd.sun.j2me.app-descriptor' },
  { extension: 'jade', mimeType: 'text/jade' },
  { extension: 'jam', mimeType: 'application/vnd.jam' },
  { extension: 'jar', mimeType: 'application/java-archive' },
  { extension: 'jardiff', mimeType: 'application/x-java-archive-diff' },
  { extension: 'java', mimeType: 'text/x-java-source' },
  { extension: 'jhc', mimeType: 'image/jphc' },
  { extension: 'jisp', mimeType: 'application/vnd.jisp' },
  { extension: 'jls', mimeType: 'image/jls' },
  { extension: 'jlt', mimeType: 'application/vnd.hp-jlyt' },
  { extension: 'jng', mimeType: 'image/x-jng' },
  { extension: 'jnlp', mimeType: 'application/x-java-jnlp-file' },
  { extension: 'joda', mimeType: 'application/vnd.joost.joda-archive' },
  { extension: 'jp2', mimeType: 'image/jp2' },
  { extension: 'jpe', mimeType: 'image/jpeg' },
  { extension: 'jpeg', mimeType: 'image/jpeg' },
  { extension: 'jpf', mimeType: 'image/jpx' },
  { extension: 'jpg', mimeType: 'image/jpeg' },
  { extension: 'jpg2', mimeType: 'image/jp2' },
  { extension: 'jpgm', mimeType: 'video/jpm' },
  { extension: 'jpgv', mimeType: 'video/jpeg' },
  { extension: 'jph', mimeType: 'image/jph' },
  { extension: 'jpm', mimeType: 'video/jpm' },
  { extension: 'jpx', mimeType: 'image/jpx' },
  { extension: 'js', mimeType: 'text/javascript' },
  { extension: 'json', mimeType: 'application/json' },
  { extension: 'json5', mimeType: 'application/json5' },
  { extension: 'jsonld', mimeType: 'application/ld+json' },
  { extension: 'jsonml', mimeType: 'application/jsonml+json' },
  { extension: 'jsx', mimeType: 'text/jsx' },
  { extension: 'jt', mimeType: 'model/jt' },
  { extension: 'jxr', mimeType: 'image/jxr' },
  { extension: 'jxra', mimeType: 'image/jxra' },
  { extension: 'jxrs', mimeType: 'image/jxrs' },
  { extension: 'jxs', mimeType: 'image/jxs' },
  { extension: 'jxsc', mimeType: 'image/jxsc' },
  { extension: 'jxsi', mimeType: 'image/jxsi' },
  { extension: 'jxss', mimeType: 'image/jxss' },
  { extension: 'kar', mimeType: 'audio/midi' },
  { extension: 'karbon', mimeType: 'application/vnd.kde.karbon' },
  { extension: 'kdbx', mimeType: 'application/x-keepass2' },
  { extension: 'key', mimeType: 'application/x-iwork-keynote-sffkey' },
  { extension: 'kfo', mimeType: 'application/vnd.kde.kformula' },
  { extension: 'kia', mimeType: 'application/vnd.kidspiration' },
  { extension: 'kml', mimeType: 'application/vnd.google-earth.kml+xml' },
  { extension: 'kmz', mimeType: 'application/vnd.google-earth.kmz' },
  { extension: 'kne', mimeType: 'application/vnd.kinar' },
  { extension: 'knp', mimeType: 'application/vnd.kinar' },
  { extension: 'kon', mimeType: 'application/vnd.kde.kontour' },
  { extension: 'kpr', mimeType: 'application/vnd.kde.kpresenter' },
  { extension: 'kpt', mimeType: 'application/vnd.kde.kpresenter' },
  { extension: 'kpxx', mimeType: 'application/vnd.ds-keypoint' },
  { extension: 'ksp', mimeType: 'application/vnd.kde.kspread' },
  { extension: 'ktr', mimeType: 'application/vnd.kahootz' },
  { extension: 'ktx', mimeType: 'image/ktx' },
  { extension: 'ktx2', mimeType: 'image/ktx2' },
  { extension: 'ktz', mimeType: 'application/vnd.kahootz' },
  { extension: 'kwd', mimeType: 'application/vnd.kde.kword' },
  { extension: 'kwt', mimeType: 'application/vnd.kde.kword' },
  { extension: 'lasxml', mimeType: 'application/vnd.las.las+xml' },
  { extension: 'latex', mimeType: 'application/x-latex' },
  {
    extension: 'lbd',
    mimeType: 'application/vnd.llamagraphics.life-balance.desktop',
  },
  {
    extension: 'lbe',
    mimeType: 'application/vnd.llamagraphics.life-balance.exchange+xml',
  },
  { extension: 'les', mimeType: 'application/vnd.hhe.lesson-player' },
  { extension: 'less', mimeType: 'text/less' },
  { extension: 'lgr', mimeType: 'application/lgr+xml' },
  { extension: 'lha', mimeType: 'application/x-lzh-compressed' },
  { extension: 'link66', mimeType: 'application/vnd.route66.link66+xml' },
  { extension: 'list', mimeType: 'text/plain' },
  { extension: 'list3820', mimeType: 'application/vnd.ibm.modcap' },
  { extension: 'listafp', mimeType: 'application/vnd.ibm.modcap' },
  { extension: 'litcoffee', mimeType: 'text/coffeescript' },
  { extension: 'lnk', mimeType: 'application/x-ms-shortcut' },
  { extension: 'log', mimeType: 'text/plain' },
  { extension: 'lostxml', mimeType: 'application/lost+xml' },
  { extension: 'lrf', mimeType: 'application/octet-stream' },
  { extension: 'lrm', mimeType: 'application/vnd.ms-lrm' },
  { extension: 'ltf', mimeType: 'application/vnd.frogans.ltf' },
  { extension: 'lua', mimeType: 'text/x-lua' },
  { extension: 'luac', mimeType: 'application/x-lua-bytecode' },
  { extension: 'lvp', mimeType: 'audio/vnd.lucent.voice' },
  { extension: 'lwp', mimeType: 'application/vnd.lotus-wordpro' },
  { extension: 'lzh', mimeType: 'application/x-lzh-compressed' },
  { extension: 'm13', mimeType: 'application/x-msmediaview' },
  { extension: 'm14', mimeType: 'application/x-msmediaview' },
  { extension: 'm1v', mimeType: 'video/mpeg' },
  { extension: 'm21', mimeType: 'application/mp21' },
  { extension: 'm2a', mimeType: 'audio/mpeg' },
  { extension: 'm2v', mimeType: 'video/mpeg' },
  { extension: 'm3a', mimeType: 'audio/mpeg' },
  { extension: 'm3u', mimeType: 'audio/x-mpegurl' },
  { extension: 'm3u8', mimeType: 'application/vnd.apple.mpegurl' },
  { extension: 'm4a', mimeType: 'audio/x-m4a' },
  { extension: 'm4p', mimeType: 'application/mp4' },
  { extension: 'm4s', mimeType: 'video/iso.segment' },
  { extension: 'm4u', mimeType: 'video/vnd.mpegurl' },
  { extension: 'm4v', mimeType: 'video/x-m4v' },
  { extension: 'ma', mimeType: 'application/mathematica' },
  { extension: 'mads', mimeType: 'application/mads+xml' },
  { extension: 'maei', mimeType: 'application/mmt-aei+xml' },
  { extension: 'mag', mimeType: 'application/vnd.ecowin.chart' },
  { extension: 'maker', mimeType: 'application/vnd.framemaker' },
  { extension: 'man', mimeType: 'text/troff' },
  { extension: 'manifest', mimeType: 'text/cache-manifest' },
  { extension: 'map', mimeType: 'application/json' },
  { extension: 'mar', mimeType: 'application/octet-stream' },
  { extension: 'markdown', mimeType: 'text/markdown' },
  { extension: 'mathml', mimeType: 'application/mathml+xml' },
  { extension: 'mb', mimeType: 'application/mathematica' },
  { extension: 'mbk', mimeType: 'application/vnd.mobius.mbk' },
  { extension: 'mbox', mimeType: 'application/mbox' },
  { extension: 'mc1', mimeType: 'application/vnd.medcalcdata' },
  { extension: 'mcd', mimeType: 'application/vnd.mcd' },
  { extension: 'mcurl', mimeType: 'text/vnd.curl.mcurl' },
  { extension: 'md', mimeType: 'text/markdown' },
  { extension: 'mdb', mimeType: 'application/x-msaccess' },
  { extension: 'mdi', mimeType: 'image/vnd.ms-modi' },
  { extension: 'mdx', mimeType: 'text/mdx' },
  { extension: 'me', mimeType: 'text/troff' },
  { extension: 'mesh', mimeType: 'model/mesh' },
  { extension: 'meta4', mimeType: 'application/metalink4+xml' },
  { extension: 'metalink', mimeType: 'application/metalink+xml' },
  { extension: 'mets', mimeType: 'application/mets+xml' },
  { extension: 'mfm', mimeType: 'application/vnd.mfmp' },
  { extension: 'mft', mimeType: 'application/rpki-manifest' },
  { extension: 'mgp', mimeType: 'application/vnd.osgeo.mapguide.package' },
  { extension: 'mgz', mimeType: 'application/vnd.proteus.magazine' },
  { extension: 'mid', mimeType: 'audio/midi' },
  { extension: 'midi', mimeType: 'audio/midi' },
  { extension: 'mie', mimeType: 'application/x-mie' },
  { extension: 'mif', mimeType: 'application/vnd.mif' },
  { extension: 'mime', mimeType: 'message/rfc822' },
  { extension: 'mj2', mimeType: 'video/mj2' },
  { extension: 'mjp2', mimeType: 'video/mj2' },
  { extension: 'mjs', mimeType: 'text/javascript' },
  { extension: 'mk3d', mimeType: 'video/x-matroska' },
  { extension: 'mka', mimeType: 'audio/x-matroska' },
  { extension: 'mkd', mimeType: 'text/x-markdown' },
  { extension: 'mks', mimeType: 'video/x-matroska' },
  { extension: 'mkv', mimeType: 'video/x-matroska' },
  { extension: 'mlp', mimeType: 'application/vnd.dolby.mlp' },
  { extension: 'mmd', mimeType: 'application/vnd.chipnuts.karaoke-mmd' },
  { extension: 'mmf', mimeType: 'application/vnd.smaf' },
  { extension: 'mml', mimeType: 'text/mathml' },
  { extension: 'mmr', mimeType: 'image/vnd.fujixerox.edmics-mmr' },
  { extension: 'mng', mimeType: 'video/x-mng' },
  { extension: 'mny', mimeType: 'application/x-msmoney' },
  { extension: 'mobi', mimeType: 'application/x-mobipocket-ebook' },
  { extension: 'mods', mimeType: 'application/mods+xml' },
  { extension: 'mov', mimeType: 'video/quicktime' },
  { extension: 'movie', mimeType: 'video/x-sgi-movie' },
  { extension: 'mp2', mimeType: 'audio/mpeg' },
  { extension: 'mp21', mimeType: 'application/mp21' },
  { extension: 'mp2a', mimeType: 'audio/mpeg' },
  { extension: 'mp3', mimeType: 'audio/mpeg' },
  { extension: 'mp4', mimeType: 'video/mp4' },
  { extension: 'mp4a', mimeType: 'audio/mp4' },
  { extension: 'mp4s', mimeType: 'application/mp4' },
  { extension: 'mp4v', mimeType: 'video/mp4' },
  { extension: 'mpc', mimeType: 'application/vnd.mophun.certificate' },
  { extension: 'mpd', mimeType: 'application/dash+xml' },
  { extension: 'mpe', mimeType: 'video/mpeg' },
  { extension: 'mpeg', mimeType: 'video/mpeg' },
  { extension: 'mpf', mimeType: 'application/media-policy-dataset+xml' },
  { extension: 'mpg', mimeType: 'video/mpeg' },
  { extension: 'mpg4', mimeType: 'video/mp4' },
  { extension: 'mpga', mimeType: 'audio/mpeg' },
  { extension: 'mpkg', mimeType: 'application/vnd.apple.installer+xml' },
  { extension: 'mpm', mimeType: 'application/vnd.blueice.multipass' },
  { extension: 'mpn', mimeType: 'application/vnd.mophun.application' },
  { extension: 'mpp', mimeType: 'application/vnd.ms-project' },
  { extension: 'mpt', mimeType: 'application/vnd.ms-project' },
  { extension: 'mpy', mimeType: 'application/vnd.ibm.minipay' },
  { extension: 'mqy', mimeType: 'application/vnd.mobius.mqy' },
  { extension: 'mrc', mimeType: 'application/marc' },
  { extension: 'mrcx', mimeType: 'application/marcxml+xml' },
  { extension: 'ms', mimeType: 'text/troff' },
  { extension: 'mscml', mimeType: 'application/mediaservercontrol+xml' },
  { extension: 'mseed', mimeType: 'application/vnd.fdsn.mseed' },
  { extension: 'mseq', mimeType: 'application/vnd.mseq' },
  { extension: 'msf', mimeType: 'application/vnd.epson.msf' },
  { extension: 'msg', mimeType: 'application/vnd.ms-outlook' },
  { extension: 'msh', mimeType: 'model/mesh' },
  { extension: 'msi', mimeType: 'application/x-msdownload' },
  { extension: 'msix', mimeType: 'application/msix' },
  { extension: 'msixbundle', mimeType: 'application/msixbundle' },
  { extension: 'msl', mimeType: 'application/vnd.mobius.msl' },
  { extension: 'msm', mimeType: 'application/octet-stream' },
  { extension: 'msp', mimeType: 'application/octet-stream' },
  { extension: 'msty', mimeType: 'application/vnd.muvee.style' },
  { extension: 'mtl', mimeType: 'model/mtl' },
  { extension: 'mts', mimeType: 'model/vnd.mts' },
  { extension: 'mus', mimeType: 'application/vnd.musician' },
  { extension: 'musd', mimeType: 'application/mmt-usd+xml' },
  { extension: 'musicxml', mimeType: 'application/vnd.recordare.musicxml+xml' },
  { extension: 'mvb', mimeType: 'application/x-msmediaview' },
  { extension: 'mvt', mimeType: 'application/vnd.mapbox-vector-tile' },
  { extension: 'mwf', mimeType: 'application/vnd.mfer' },
  { extension: 'mxf', mimeType: 'application/mxf' },
  { extension: 'mxl', mimeType: 'application/vnd.recordare.musicxml' },
  { extension: 'mxmf', mimeType: 'audio/mobile-xmf' },
  { extension: 'mxml', mimeType: 'application/xv+xml' },
  { extension: 'mxs', mimeType: 'application/vnd.triscape.mxs' },
  { extension: 'mxu', mimeType: 'video/vnd.mpegurl' },
  {
    extension: 'n-gage',
    mimeType: 'application/vnd.nokia.n-gage.symbian.install',
  },
  { extension: 'n3', mimeType: 'text/n3' },
  { extension: 'nb', mimeType: 'application/mathematica' },
  { extension: 'nbp', mimeType: 'application/vnd.wolfram.player' },
  { extension: 'nc', mimeType: 'application/x-netcdf' },
  { extension: 'ncx', mimeType: 'application/x-dtbncx+xml' },
  { extension: 'nfo', mimeType: 'text/x-nfo' },
  { extension: 'ngdat', mimeType: 'application/vnd.nokia.n-gage.data' },
  { extension: 'nitf', mimeType: 'application/vnd.nitf' },
  { extension: 'nlu', mimeType: 'application/vnd.neurolanguage.nlu' },
  { extension: 'nml', mimeType: 'application/vnd.enliven' },
  { extension: 'nnd', mimeType: 'application/vnd.noblenet-directory' },
  { extension: 'nns', mimeType: 'application/vnd.noblenet-sealer' },
  { extension: 'nnw', mimeType: 'application/vnd.noblenet-web' },
  { extension: 'npx', mimeType: 'image/vnd.net-fpx' },
  { extension: 'nq', mimeType: 'application/n-quads' },
  { extension: 'nsc', mimeType: 'application/x-conference' },
  { extension: 'nsf', mimeType: 'application/vnd.lotus-notes' },
  { extension: 'nt', mimeType: 'application/n-triples' },
  { extension: 'ntf', mimeType: 'application/vnd.nitf' },
  { extension: 'numbers', mimeType: 'application/x-iwork-numbers-sffnumbers' },
  { extension: 'nzb', mimeType: 'application/x-nzb' },
  { extension: 'oa2', mimeType: 'application/vnd.fujitsu.oasys2' },
  { extension: 'oa3', mimeType: 'application/vnd.fujitsu.oasys3' },
  { extension: 'oas', mimeType: 'application/vnd.fujitsu.oasys' },
  { extension: 'obd', mimeType: 'application/x-msbinder' },
  { extension: 'obgx', mimeType: 'application/vnd.openblox.game+xml' },
  { extension: 'obj', mimeType: 'model/obj' },
  { extension: 'oda', mimeType: 'application/oda' },
  { extension: 'odb', mimeType: 'application/vnd.oasis.opendocument.database' },
  { extension: 'odc', mimeType: 'application/vnd.oasis.opendocument.chart' },
  { extension: 'odf', mimeType: 'application/vnd.oasis.opendocument.formula' },
  {
    extension: 'odft',
    mimeType: 'application/vnd.oasis.opendocument.formula-template',
  },
  { extension: 'odg', mimeType: 'application/vnd.oasis.opendocument.graphics' },
  { extension: 'odi', mimeType: 'application/vnd.oasis.opendocument.image' },
  {
    extension: 'odm',
    mimeType: 'application/vnd.oasis.opendocument.text-master',
  },
  {
    extension: 'odp',
    mimeType: 'application/vnd.oasis.opendocument.presentation',
  },
  {
    extension: 'ods',
    mimeType: 'application/vnd.oasis.opendocument.spreadsheet',
  },
  { extension: 'odt', mimeType: 'application/vnd.oasis.opendocument.text' },
  { extension: 'oga', mimeType: 'audio/ogg' },
  { extension: 'ogex', mimeType: 'model/vnd.opengex' },
  { extension: 'ogg', mimeType: 'audio/ogg' },
  { extension: 'ogv', mimeType: 'video/ogg' },
  { extension: 'ogx', mimeType: 'application/ogg' },
  { extension: 'omdoc', mimeType: 'application/omdoc+xml' },
  { extension: 'onepkg', mimeType: 'application/onenote' },
  { extension: 'onetmp', mimeType: 'application/onenote' },
  { extension: 'onetoc', mimeType: 'application/onenote' },
  { extension: 'onetoc2', mimeType: 'application/onenote' },
  { extension: 'opf', mimeType: 'application/oebps-package+xml' },
  { extension: 'opml', mimeType: 'text/x-opml' },
  { extension: 'oprc', mimeType: 'application/vnd.palm' },
  { extension: 'opus', mimeType: 'audio/ogg' },
  { extension: 'org', mimeType: 'text/x-org' },
  { extension: 'osf', mimeType: 'application/vnd.yamaha.openscoreformat' },
  {
    extension: 'osfpvg',
    mimeType: 'application/vnd.yamaha.openscoreformat.osfpvg+xml',
  },
  { extension: 'osm', mimeType: 'application/vnd.openstreetmap.data+xml' },
  {
    extension: 'otc',
    mimeType: 'application/vnd.oasis.opendocument.chart-template',
  },
  { extension: 'otf', mimeType: 'font/otf' },
  {
    extension: 'otg',
    mimeType: 'application/vnd.oasis.opendocument.graphics-template',
  },
  { extension: 'oth', mimeType: 'application/vnd.oasis.opendocument.text-web' },
  {
    extension: 'oti',
    mimeType: 'application/vnd.oasis.opendocument.image-template',
  },
  {
    extension: 'otp',
    mimeType: 'application/vnd.oasis.opendocument.presentation-template',
  },
  {
    extension: 'ots',
    mimeType: 'application/vnd.oasis.opendocument.spreadsheet-template',
  },
  {
    extension: 'ott',
    mimeType: 'application/vnd.oasis.opendocument.text-template',
  },
  { extension: 'ova', mimeType: 'application/x-virtualbox-ova' },
  { extension: 'ovf', mimeType: 'application/x-virtualbox-ovf' },
  { extension: 'owl', mimeType: 'application/rdf+xml' },
  { extension: 'oxps', mimeType: 'application/oxps' },
  { extension: 'oxt', mimeType: 'application/vnd.openofficeorg.extension' },
  { extension: 'p', mimeType: 'text/x-pascal' },
  { extension: 'p10', mimeType: 'application/pkcs10' },
  { extension: 'p12', mimeType: 'application/x-pkcs12' },
  { extension: 'p7b', mimeType: 'application/x-pkcs7-certificates' },
  { extension: 'p7c', mimeType: 'application/pkcs7-mime' },
  { extension: 'p7m', mimeType: 'application/pkcs7-mime' },
  { extension: 'p7r', mimeType: 'application/x-pkcs7-certreqresp' },
  { extension: 'p7s', mimeType: 'application/pkcs7-signature' },
  { extension: 'p8', mimeType: 'application/pkcs8' },
  { extension: 'pac', mimeType: 'application/x-ns-proxy-autoconfig' },
  { extension: 'pages', mimeType: 'application/x-iwork-pages-sffpages' },
  { extension: 'pas', mimeType: 'text/x-pascal' },
  { extension: 'paw', mimeType: 'application/vnd.pawaafile' },
  { extension: 'pbd', mimeType: 'application/vnd.powerbuilder6' },
  { extension: 'pbm', mimeType: 'image/x-portable-bitmap' },
  { extension: 'pcap', mimeType: 'application/vnd.tcpdump.pcap' },
  { extension: 'pcf', mimeType: 'application/x-font-pcf' },
  { extension: 'pcl', mimeType: 'application/vnd.hp-pcl' },
  { extension: 'pclxl', mimeType: 'application/vnd.hp-pclxl' },
  { extension: 'pct', mimeType: 'image/x-pict' },
  { extension: 'pcurl', mimeType: 'application/vnd.curl.pcurl' },
  { extension: 'pcx', mimeType: 'image/x-pcx' },
  { extension: 'pdb', mimeType: 'application/x-pilot' },
  { extension: 'pde', mimeType: 'text/x-processing' },
  { extension: 'pdf', mimeType: 'application/pdf' },
  { extension: 'pem', mimeType: 'application/x-x509-ca-cert' },
  { extension: 'pfa', mimeType: 'application/x-font-type1' },
  { extension: 'pfb', mimeType: 'application/x-font-type1' },
  { extension: 'pfm', mimeType: 'application/x-font-type1' },
  { extension: 'pfr', mimeType: 'application/font-tdpfr' },
  { extension: 'pfx', mimeType: 'application/x-pkcs12' },
  { extension: 'pgm', mimeType: 'image/x-portable-graymap' },
  { extension: 'pgn', mimeType: 'application/x-chess-pgn' },
  { extension: 'pgp', mimeType: 'application/pgp-encrypted' },
  { extension: 'php', mimeType: 'application/x-httpd-php' },
  { extension: 'pic', mimeType: 'image/x-pict' },
  { extension: 'pkg', mimeType: 'application/octet-stream' },
  { extension: 'pki', mimeType: 'application/pkixcmp' },
  { extension: 'pkipath', mimeType: 'application/pkix-pkipath' },
  { extension: 'pkpass', mimeType: 'application/vnd.apple.pkpass' },
  { extension: 'pl', mimeType: 'application/x-perl' },
  { extension: 'plb', mimeType: 'application/vnd.3gpp.pic-bw-large' },
  { extension: 'plc', mimeType: 'application/vnd.mobius.plc' },
  { extension: 'plf', mimeType: 'application/vnd.pocketlearn' },
  { extension: 'pls', mimeType: 'application/pls+xml' },
  { extension: 'pm', mimeType: 'application/x-perl' },
  { extension: 'pml', mimeType: 'application/vnd.ctc-posml' },
  { extension: 'png', mimeType: 'image/png' },
  { extension: 'pnm', mimeType: 'image/x-portable-anymap' },
  { extension: 'portpkg', mimeType: 'application/vnd.macports.portpkg' },
  { extension: 'pot', mimeType: 'application/vnd.ms-powerpoint' },
  {
    extension: 'potm',
    mimeType: 'application/vnd.ms-powerpoint.template.macroenabled.12',
  },
  {
    extension: 'potx',
    mimeType: 'application/vnd.openxmlformats-officedocument.presentationml.template',
  },
  {
    extension: 'ppam',
    mimeType: 'application/vnd.ms-powerpoint.addin.macroenabled.12',
  },
  { extension: 'ppd', mimeType: 'application/vnd.cups-ppd' },
  { extension: 'ppm', mimeType: 'image/x-portable-pixmap' },
  { extension: 'pps', mimeType: 'application/vnd.ms-powerpoint' },
  {
    extension: 'ppsm',
    mimeType: 'application/vnd.ms-powerpoint.slideshow.macroenabled.12',
  },
  {
    extension: 'ppsx',
    mimeType: 'application/vnd.openxmlformats-officedocument.presentationml.slideshow',
  },
  { extension: 'ppt', mimeType: 'application/vnd.ms-powerpoint' },
  {
    extension: 'pptm',
    mimeType: 'application/vnd.ms-powerpoint.presentation.macroenabled.12',
  },
  {
    extension: 'pptx',
    mimeType: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  },
  { extension: 'pqa', mimeType: 'application/vnd.palm' },
  { extension: 'prc', mimeType: 'model/prc' },
  { extension: 'pre', mimeType: 'application/vnd.lotus-freelance' },
  { extension: 'prf', mimeType: 'application/pics-rules' },
  { extension: 'provx', mimeType: 'application/provenance+xml' },
  { extension: 'ps', mimeType: 'application/postscript' },
  { extension: 'psb', mimeType: 'application/vnd.3gpp.pic-bw-small' },
  { extension: 'psd', mimeType: 'image/vnd.adobe.photoshop' },
  { extension: 'psf', mimeType: 'application/x-font-linux-psf' },
  { extension: 'pskcxml', mimeType: 'application/pskc+xml' },
  { extension: 'pti', mimeType: 'image/prs.pti' },
  { extension: 'ptid', mimeType: 'application/vnd.pvi.ptid1' },
  { extension: 'pub', mimeType: 'application/x-mspublisher' },
  { extension: 'pvb', mimeType: 'application/vnd.3gpp.pic-bw-var' },
  { extension: 'pwn', mimeType: 'application/vnd.3m.post-it-notes' },
  { extension: 'pya', mimeType: 'audio/vnd.ms-playready.media.pya' },
  { extension: 'pyo', mimeType: 'model/vnd.pytha.pyox' },
  { extension: 'pyox', mimeType: 'model/vnd.pytha.pyox' },
  { extension: 'pyv', mimeType: 'video/vnd.ms-playready.media.pyv' },
  { extension: 'qam', mimeType: 'application/vnd.epson.quickanime' },
  { extension: 'qbo', mimeType: 'application/vnd.intu.qbo' },
  { extension: 'qfx', mimeType: 'application/vnd.intu.qfx' },
  { extension: 'qps', mimeType: 'application/vnd.publishare-delta-tree' },
  { extension: 'qt', mimeType: 'video/quicktime' },
  { extension: 'qwd', mimeType: 'application/vnd.quark.quarkxpress' },
  { extension: 'qwt', mimeType: 'application/vnd.quark.quarkxpress' },
  { extension: 'qxb', mimeType: 'application/vnd.quark.quarkxpress' },
  { extension: 'qxd', mimeType: 'application/vnd.quark.quarkxpress' },
  { extension: 'qxl', mimeType: 'application/vnd.quark.quarkxpress' },
  { extension: 'qxt', mimeType: 'application/vnd.quark.quarkxpress' },
  { extension: 'ra', mimeType: 'audio/x-realaudio' },
  { extension: 'ram', mimeType: 'audio/x-pn-realaudio' },
  { extension: 'raml', mimeType: 'application/raml+yaml' },
  { extension: 'rapd', mimeType: 'application/route-apd+xml' },
  { extension: 'rar', mimeType: 'application/x-rar-compressed' },
  { extension: 'ras', mimeType: 'image/x-cmu-raster' },
  { extension: 'rcprofile', mimeType: 'application/vnd.ipunplugged.rcprofile' },
  { extension: 'rdf', mimeType: 'application/rdf+xml' },
  { extension: 'rdz', mimeType: 'application/vnd.data-vision.rdz' },
  { extension: 'relo', mimeType: 'application/p2p-overlay+xml' },
  { extension: 'rep', mimeType: 'application/vnd.businessobjects' },
  { extension: 'res', mimeType: 'application/x-dtbresource+xml' },
  { extension: 'rgb', mimeType: 'image/x-rgb' },
  { extension: 'rif', mimeType: 'application/reginfo+xml' },
  { extension: 'rip', mimeType: 'audio/vnd.rip' },
  { extension: 'ris', mimeType: 'application/x-research-info-systems' },
  { extension: 'rl', mimeType: 'application/resource-lists+xml' },
  { extension: 'rlc', mimeType: 'image/vnd.fujixerox.edmics-rlc' },
  { extension: 'rld', mimeType: 'application/resource-lists-diff+xml' },
  { extension: 'rm', mimeType: 'application/vnd.rn-realmedia' },
  { extension: 'rmi', mimeType: 'audio/midi' },
  { extension: 'rmp', mimeType: 'audio/x-pn-realaudio-plugin' },
  { extension: 'rms', mimeType: 'application/vnd.jcp.javame.midlet-rms' },
  { extension: 'rmvb', mimeType: 'application/vnd.rn-realmedia-vbr' },
  { extension: 'rnc', mimeType: 'application/relax-ng-compact-syntax' },
  { extension: 'rng', mimeType: 'application/xml' },
  { extension: 'roa', mimeType: 'application/rpki-roa' },
  { extension: 'roff', mimeType: 'text/troff' },
  { extension: 'rp9', mimeType: 'application/vnd.cloanto.rp9' },
  { extension: 'rpm', mimeType: 'application/x-redhat-package-manager' },
  { extension: 'rpss', mimeType: 'application/vnd.nokia.radio-presets' },
  { extension: 'rpst', mimeType: 'application/vnd.nokia.radio-preset' },
  { extension: 'rq', mimeType: 'application/sparql-query' },
  { extension: 'rs', mimeType: 'application/rls-services+xml' },
  { extension: 'rsat', mimeType: 'application/atsc-rsat+xml' },
  { extension: 'rsd', mimeType: 'application/rsd+xml' },
  { extension: 'rsheet', mimeType: 'application/urc-ressheet+xml' },
  { extension: 'rss', mimeType: 'application/rss+xml' },
  { extension: 'rtf', mimeType: 'text/rtf' },
  { extension: 'rtx', mimeType: 'text/richtext' },
  { extension: 'run', mimeType: 'application/x-makeself' },
  { extension: 'rusd', mimeType: 'application/route-usd+xml' },
  { extension: 's', mimeType: 'text/x-asm' },
  { extension: 's3m', mimeType: 'audio/s3m' },
  { extension: 'saf', mimeType: 'application/vnd.yamaha.smaf-audio' },
  { extension: 'sass', mimeType: 'text/x-sass' },
  { extension: 'sbml', mimeType: 'application/sbml+xml' },
  { extension: 'sc', mimeType: 'application/vnd.ibm.secure-container' },
  { extension: 'scd', mimeType: 'application/x-msschedule' },
  { extension: 'scm', mimeType: 'application/vnd.lotus-screencam' },
  { extension: 'scq', mimeType: 'application/scvp-cv-request' },
  { extension: 'scs', mimeType: 'application/scvp-cv-response' },
  { extension: 'scss', mimeType: 'text/x-scss' },
  { extension: 'scurl', mimeType: 'text/vnd.curl.scurl' },
  { extension: 'sda', mimeType: 'application/vnd.stardivision.draw' },
  { extension: 'sdc', mimeType: 'application/vnd.stardivision.calc' },
  { extension: 'sdd', mimeType: 'application/vnd.stardivision.impress' },
  { extension: 'sdkd', mimeType: 'application/vnd.solent.sdkm+xml' },
  { extension: 'sdkm', mimeType: 'application/vnd.solent.sdkm+xml' },
  { extension: 'sdp', mimeType: 'application/sdp' },
  { extension: 'sdw', mimeType: 'application/vnd.stardivision.writer' },
  { extension: 'sea', mimeType: 'application/x-sea' },
  { extension: 'see', mimeType: 'application/vnd.seemail' },
  { extension: 'seed', mimeType: 'application/vnd.fdsn.seed' },
  { extension: 'sema', mimeType: 'application/vnd.sema' },
  { extension: 'semd', mimeType: 'application/vnd.semd' },
  { extension: 'semf', mimeType: 'application/vnd.semf' },
  { extension: 'senmlx', mimeType: 'application/senml+xml' },
  { extension: 'sensmlx', mimeType: 'application/sensml+xml' },
  { extension: 'ser', mimeType: 'application/java-serialized-object' },
  { extension: 'setpay', mimeType: 'application/set-payment-initiation' },
  { extension: 'setreg', mimeType: 'application/set-registration-initiation' },
  { extension: 'sfd-hdstx', mimeType: 'application/vnd.hydrostatix.sof-data' },
  { extension: 'sfs', mimeType: 'application/vnd.spotfire.sfs' },
  { extension: 'sfv', mimeType: 'text/x-sfv' },
  { extension: 'sgi', mimeType: 'image/sgi' },
  { extension: 'sgl', mimeType: 'application/vnd.stardivision.writer-global' },
  { extension: 'sgm', mimeType: 'text/sgml' },
  { extension: 'sgml', mimeType: 'text/sgml' },
  { extension: 'sh', mimeType: 'application/x-sh' },
  { extension: 'shar', mimeType: 'application/x-shar' },
  { extension: 'shex', mimeType: 'text/shex' },
  { extension: 'shf', mimeType: 'application/shf+xml' },
  { extension: 'shtml', mimeType: 'text/html' },
  { extension: 'sid', mimeType: 'image/x-mrsid-image' },
  { extension: 'sieve', mimeType: 'application/sieve' },
  { extension: 'sig', mimeType: 'application/pgp-signature' },
  { extension: 'sil', mimeType: 'audio/silk' },
  { extension: 'silo', mimeType: 'model/mesh' },
  { extension: 'sis', mimeType: 'application/vnd.symbian.install' },
  { extension: 'sisx', mimeType: 'application/vnd.symbian.install' },
  { extension: 'sit', mimeType: 'application/x-stuffit' },
  { extension: 'sitx', mimeType: 'application/x-stuffitx' },
  { extension: 'siv', mimeType: 'application/sieve' },
  { extension: 'skd', mimeType: 'application/vnd.koan' },
  { extension: 'skm', mimeType: 'application/vnd.koan' },
  { extension: 'skp', mimeType: 'application/vnd.koan' },
  { extension: 'skt', mimeType: 'application/vnd.koan' },
  {
    extension: 'sldm',
    mimeType: 'application/vnd.ms-powerpoint.slide.macroenabled.12',
  },
  {
    extension: 'sldx',
    mimeType: 'application/vnd.openxmlformats-officedocument.presentationml.slide',
  },
  { extension: 'slim', mimeType: 'text/slim' },
  { extension: 'slm', mimeType: 'text/slim' },
  { extension: 'sls', mimeType: 'application/route-s-tsid+xml' },
  { extension: 'slt', mimeType: 'application/vnd.epson.salt' },
  { extension: 'sm', mimeType: 'application/vnd.stepmania.stepchart' },
  { extension: 'smf', mimeType: 'application/vnd.stardivision.math' },
  { extension: 'smi', mimeType: 'application/smil+xml' },
  { extension: 'smil', mimeType: 'application/smil+xml' },
  { extension: 'smv', mimeType: 'video/x-smv' },
  { extension: 'smzip', mimeType: 'application/vnd.stepmania.package' },
  { extension: 'snd', mimeType: 'audio/basic' },
  { extension: 'snf', mimeType: 'application/x-font-snf' },
  { extension: 'so', mimeType: 'application/octet-stream' },
  { extension: 'spc', mimeType: 'application/x-pkcs7-certificates' },
  { extension: 'spdx', mimeType: 'text/spdx' },
  { extension: 'spf', mimeType: 'application/vnd.yamaha.smaf-phrase' },
  { extension: 'spl', mimeType: 'application/x-futuresplash' },
  { extension: 'spot', mimeType: 'text/vnd.in3d.spot' },
  { extension: 'spp', mimeType: 'application/scvp-vp-response' },
  { extension: 'spq', mimeType: 'application/scvp-vp-request' },
  { extension: 'spx', mimeType: 'audio/ogg' },
  { extension: 'sql', mimeType: 'application/x-sql' },
  { extension: 'src', mimeType: 'application/x-wais-source' },
  { extension: 'srt', mimeType: 'application/x-subrip' },
  { extension: 'sru', mimeType: 'application/sru+xml' },
  { extension: 'srx', mimeType: 'application/sparql-results+xml' },
  { extension: 'ssdl', mimeType: 'application/ssdl+xml' },
  { extension: 'sse', mimeType: 'application/vnd.kodak-descriptor' },
  { extension: 'ssf', mimeType: 'application/vnd.epson.ssf' },
  { extension: 'ssml', mimeType: 'application/ssml+xml' },
  { extension: 'st', mimeType: 'application/vnd.sailingtracker.track' },
  { extension: 'stc', mimeType: 'application/vnd.sun.xml.calc.template' },
  { extension: 'std', mimeType: 'application/vnd.sun.xml.draw.template' },
  { extension: 'stf', mimeType: 'application/vnd.wt.stf' },
  { extension: 'sti', mimeType: 'application/vnd.sun.xml.impress.template' },
  { extension: 'stk', mimeType: 'application/hyperstudio' },
  { extension: 'stl', mimeType: 'model/stl' },
  { extension: 'stpx', mimeType: 'model/step+xml' },
  { extension: 'stpxz', mimeType: 'model/step-xml+zip' },
  { extension: 'stpz', mimeType: 'model/step+zip' },
  { extension: 'str', mimeType: 'application/vnd.pg.format' },
  { extension: 'stw', mimeType: 'application/vnd.sun.xml.writer.template' },
  { extension: 'styl', mimeType: 'text/stylus' },
  { extension: 'stylus', mimeType: 'text/stylus' },
  { extension: 'sub', mimeType: 'text/vnd.dvb.subtitle' },
  { extension: 'sus', mimeType: 'application/vnd.sus-calendar' },
  { extension: 'susp', mimeType: 'application/vnd.sus-calendar' },
  { extension: 'sv4cpio', mimeType: 'application/x-sv4cpio' },
  { extension: 'sv4crc', mimeType: 'application/x-sv4crc' },
  { extension: 'svc', mimeType: 'application/vnd.dvb.service' },
  { extension: 'svd', mimeType: 'application/vnd.svd' },
  { extension: 'svg', mimeType: 'image/svg+xml' },
  { extension: 'svgz', mimeType: 'image/svg+xml' },
  { extension: 'swa', mimeType: 'application/x-director' },
  { extension: 'swf', mimeType: 'application/x-shockwave-flash' },
  { extension: 'swi', mimeType: 'application/vnd.aristanetworks.swi' },
  { extension: 'swidtag', mimeType: 'application/swid+xml' },
  { extension: 'sxc', mimeType: 'application/vnd.sun.xml.calc' },
  { extension: 'sxd', mimeType: 'application/vnd.sun.xml.draw' },
  { extension: 'sxg', mimeType: 'application/vnd.sun.xml.writer.global' },
  { extension: 'sxi', mimeType: 'application/vnd.sun.xml.impress' },
  { extension: 'sxm', mimeType: 'application/vnd.sun.xml.math' },
  { extension: 'sxw', mimeType: 'application/vnd.sun.xml.writer' },
  { extension: 't', mimeType: 'text/troff' },
  { extension: 't3', mimeType: 'application/x-t3vm-image' },
  { extension: 't38', mimeType: 'image/t38' },
  { extension: 'taglet', mimeType: 'application/vnd.mynfc' },
  { extension: 'tao', mimeType: 'application/vnd.tao.intent-module-archive' },
  { extension: 'tap', mimeType: 'image/vnd.tencent.tap' },
  { extension: 'tar', mimeType: 'application/x-tar' },
  { extension: 'tcap', mimeType: 'application/vnd.3gpp2.tcap' },
  { extension: 'tcl', mimeType: 'application/x-tcl' },
  { extension: 'td', mimeType: 'application/urc-targetdesc+xml' },
  { extension: 'teacher', mimeType: 'application/vnd.smart.teacher' },
  { extension: 'tei', mimeType: 'application/tei+xml' },
  { extension: 'teicorpus', mimeType: 'application/tei+xml' },
  { extension: 'tex', mimeType: 'application/x-tex' },
  { extension: 'texi', mimeType: 'application/x-texinfo' },
  { extension: 'texinfo', mimeType: 'application/x-texinfo' },
  { extension: 'text', mimeType: 'text/plain' },
  { extension: 'tfi', mimeType: 'application/thraud+xml' },
  { extension: 'tfm', mimeType: 'application/x-tex-tfm' },
  { extension: 'tfx', mimeType: 'image/tiff-fx' },
  { extension: 'tga', mimeType: 'image/x-tga' },
  { extension: 'thmx', mimeType: 'application/vnd.ms-officetheme' },
  { extension: 'tif', mimeType: 'image/tiff' },
  { extension: 'tiff', mimeType: 'image/tiff' },
  { extension: 'tk', mimeType: 'application/x-tcl' },
  { extension: 'tmo', mimeType: 'application/vnd.tmobile-livetv' },
  { extension: 'toml', mimeType: 'application/toml' },
  { extension: 'torrent', mimeType: 'application/x-bittorrent' },
  { extension: 'tpl', mimeType: 'application/vnd.groove-tool-template' },
  { extension: 'tpt', mimeType: 'application/vnd.trid.tpt' },
  { extension: 'tr', mimeType: 'text/troff' },
  { extension: 'tra', mimeType: 'application/vnd.trueapp' },
  { extension: 'trig', mimeType: 'application/trig' },
  { extension: 'trm', mimeType: 'application/x-msterminal' },
  { extension: 'ts', mimeType: 'video/mp2t' },
  { extension: 'tsd', mimeType: 'application/timestamped-data' },
  { extension: 'tsv', mimeType: 'text/tab-separated-values' },
  { extension: 'ttc', mimeType: 'font/collection' },
  { extension: 'ttf', mimeType: 'font/ttf' },
  { extension: 'ttl', mimeType: 'text/turtle' },
  { extension: 'ttml', mimeType: 'application/ttml+xml' },
  { extension: 'twd', mimeType: 'application/vnd.simtech-mindmapper' },
  { extension: 'twds', mimeType: 'application/vnd.simtech-mindmapper' },
  { extension: 'txd', mimeType: 'application/vnd.genomatix.tuxedo' },
  { extension: 'txf', mimeType: 'application/vnd.mobius.txf' },
  { extension: 'txt', mimeType: 'text/plain' },
  { extension: 'u32', mimeType: 'application/x-authorware-bin' },
  { extension: 'u3d', mimeType: 'model/u3d' },
  { extension: 'u8dsn', mimeType: 'message/global-delivery-status' },
  { extension: 'u8hdr', mimeType: 'message/global-headers' },
  { extension: 'u8mdn', mimeType: 'message/global-disposition-notification' },
  { extension: 'u8msg', mimeType: 'message/global' },
  { extension: 'ubj', mimeType: 'application/ubjson' },
  { extension: 'udeb', mimeType: 'application/x-debian-package' },
  { extension: 'ufd', mimeType: 'application/vnd.ufdl' },
  { extension: 'ufdl', mimeType: 'application/vnd.ufdl' },
  { extension: 'ulx', mimeType: 'application/x-glulx' },
  { extension: 'umj', mimeType: 'application/vnd.umajin' },
  { extension: 'unityweb', mimeType: 'application/vnd.unity' },
  { extension: 'uo', mimeType: 'application/vnd.uoml+xml' },
  { extension: 'uoml', mimeType: 'application/vnd.uoml+xml' },
  { extension: 'uri', mimeType: 'text/uri-list' },
  { extension: 'uris', mimeType: 'text/uri-list' },
  { extension: 'urls', mimeType: 'text/uri-list' },
  { extension: 'usda', mimeType: 'model/vnd.usda' },
  { extension: 'usdz', mimeType: 'model/vnd.usdz+zip' },
  { extension: 'ustar', mimeType: 'application/x-ustar' },
  { extension: 'utz', mimeType: 'application/vnd.uiq.theme' },
  { extension: 'uu', mimeType: 'text/x-uuencode' },
  { extension: 'uva', mimeType: 'audio/vnd.dece.audio' },
  { extension: 'uvd', mimeType: 'application/vnd.dece.data' },
  { extension: 'uvf', mimeType: 'application/vnd.dece.data' },
  { extension: 'uvg', mimeType: 'image/vnd.dece.graphic' },
  { extension: 'uvh', mimeType: 'video/vnd.dece.hd' },
  { extension: 'uvi', mimeType: 'image/vnd.dece.graphic' },
  { extension: 'uvm', mimeType: 'video/vnd.dece.mobile' },
  { extension: 'uvp', mimeType: 'video/vnd.dece.pd' },
  { extension: 'uvs', mimeType: 'video/vnd.dece.sd' },
  { extension: 'uvt', mimeType: 'application/vnd.dece.ttml+xml' },
  { extension: 'uvu', mimeType: 'video/vnd.uvvu.mp4' },
  { extension: 'uvv', mimeType: 'video/vnd.dece.video' },
  { extension: 'uvva', mimeType: 'audio/vnd.dece.audio' },
  { extension: 'uvvd', mimeType: 'application/vnd.dece.data' },
  { extension: 'uvvf', mimeType: 'application/vnd.dece.data' },
  { extension: 'uvvg', mimeType: 'image/vnd.dece.graphic' },
  { extension: 'uvvh', mimeType: 'video/vnd.dece.hd' },
  { extension: 'uvvi', mimeType: 'image/vnd.dece.graphic' },
  { extension: 'uvvm', mimeType: 'video/vnd.dece.mobile' },
  { extension: 'uvvp', mimeType: 'video/vnd.dece.pd' },
  { extension: 'uvvs', mimeType: 'video/vnd.dece.sd' },
  { extension: 'uvvt', mimeType: 'application/vnd.dece.ttml+xml' },
  { extension: 'uvvu', mimeType: 'video/vnd.uvvu.mp4' },
  { extension: 'uvvv', mimeType: 'video/vnd.dece.video' },
  { extension: 'uvvx', mimeType: 'application/vnd.dece.unspecified' },
  { extension: 'uvvz', mimeType: 'application/vnd.dece.zip' },
  { extension: 'uvx', mimeType: 'application/vnd.dece.unspecified' },
  { extension: 'uvz', mimeType: 'application/vnd.dece.zip' },
  { extension: 'vbox', mimeType: 'application/x-virtualbox-vbox' },
  {
    extension: 'vbox-extpack',
    mimeType: 'application/x-virtualbox-vbox-extpack',
  },
  { extension: 'vcard', mimeType: 'text/vcard' },
  { extension: 'vcd', mimeType: 'application/x-cdlink' },
  { extension: 'vcf', mimeType: 'text/x-vcard' },
  { extension: 'vcg', mimeType: 'application/vnd.groove-vcard' },
  { extension: 'vcs', mimeType: 'text/x-vcalendar' },
  { extension: 'vcx', mimeType: 'application/vnd.vcx' },
  { extension: 'vdi', mimeType: 'application/x-virtualbox-vdi' },
  { extension: 'vds', mimeType: 'model/vnd.sap.vds' },
  { extension: 'vhd', mimeType: 'application/x-virtualbox-vhd' },
  { extension: 'vis', mimeType: 'application/vnd.visionary' },
  { extension: 'viv', mimeType: 'video/vnd.vivo' },
  { extension: 'vmdk', mimeType: 'application/x-virtualbox-vmdk' },
  { extension: 'vob', mimeType: 'video/x-ms-vob' },
  { extension: 'vor', mimeType: 'application/vnd.stardivision.writer' },
  { extension: 'vox', mimeType: 'application/x-authorware-bin' },
  { extension: 'vrml', mimeType: 'model/vrml' },
  { extension: 'vsd', mimeType: 'application/vnd.visio' },
  { extension: 'vsf', mimeType: 'application/vnd.vsf' },
  { extension: 'vss', mimeType: 'application/vnd.visio' },
  { extension: 'vst', mimeType: 'application/vnd.visio' },
  { extension: 'vsw', mimeType: 'application/vnd.visio' },
  { extension: 'vtf', mimeType: 'image/vnd.valve.source.texture' },
  { extension: 'vtt', mimeType: 'text/vtt' },
  { extension: 'vtu', mimeType: 'model/vnd.vtu' },
  { extension: 'vxml', mimeType: 'application/voicexml+xml' },
  { extension: 'w3d', mimeType: 'application/x-director' },
  { extension: 'wad', mimeType: 'application/x-doom' },
  { extension: 'wadl', mimeType: 'application/vnd.sun.wadl+xml' },
  { extension: 'war', mimeType: 'application/java-archive' },
  { extension: 'wasm', mimeType: 'application/wasm' },
  { extension: 'wav', mimeType: 'audio/x-wav' },
  { extension: 'wax', mimeType: 'audio/x-ms-wax' },
  { extension: 'wbmp', mimeType: 'image/vnd.wap.wbmp' },
  { extension: 'wbs', mimeType: 'application/vnd.criticaltools.wbs+xml' },
  { extension: 'wbxml', mimeType: 'application/vnd.wap.wbxml' },
  { extension: 'wcm', mimeType: 'application/vnd.ms-works' },
  { extension: 'wdb', mimeType: 'application/vnd.ms-works' },
  { extension: 'wdp', mimeType: 'image/vnd.ms-photo' },
  { extension: 'weba', mimeType: 'audio/webm' },
  { extension: 'webapp', mimeType: 'application/x-web-app-manifest+json' },
  { extension: 'webm', mimeType: 'video/webm' },
  { extension: 'webmanifest', mimeType: 'application/manifest+json' },
  { extension: 'webp', mimeType: 'image/webp' },
  { extension: 'wg', mimeType: 'application/vnd.pmi.widget' },
  { extension: 'wgsl', mimeType: 'text/wgsl' },
  { extension: 'wgt', mimeType: 'application/widget' },
  { extension: 'wif', mimeType: 'application/watcherinfo+xml' },
  { extension: 'wks', mimeType: 'application/vnd.ms-works' },
  { extension: 'wm', mimeType: 'video/x-ms-wm' },
  { extension: 'wma', mimeType: 'audio/x-ms-wma' },
  { extension: 'wmd', mimeType: 'application/x-ms-wmd' },
  { extension: 'wmf', mimeType: 'image/wmf' },
  { extension: 'wml', mimeType: 'text/vnd.wap.wml' },
  { extension: 'wmlc', mimeType: 'application/vnd.wap.wmlc' },
  { extension: 'wmls', mimeType: 'text/vnd.wap.wmlscript' },
  { extension: 'wmlsc', mimeType: 'application/vnd.wap.wmlscriptc' },
  { extension: 'wmv', mimeType: 'video/x-ms-wmv' },
  { extension: 'wmx', mimeType: 'video/x-ms-wmx' },
  { extension: 'wmz', mimeType: 'application/x-msmetafile' },
  { extension: 'woff', mimeType: 'font/woff' },
  { extension: 'woff2', mimeType: 'font/woff2' },
  { extension: 'wpd', mimeType: 'application/vnd.wordperfect' },
  { extension: 'wpl', mimeType: 'application/vnd.ms-wpl' },
  { extension: 'wps', mimeType: 'application/vnd.ms-works' },
  { extension: 'wqd', mimeType: 'application/vnd.wqd' },
  { extension: 'wri', mimeType: 'application/x-mswrite' },
  { extension: 'wrl', mimeType: 'model/vrml' },
  { extension: 'wsc', mimeType: 'message/vnd.wfa.wsc' },
  { extension: 'wsdl', mimeType: 'application/wsdl+xml' },
  { extension: 'wspolicy', mimeType: 'application/wspolicy+xml' },
  { extension: 'wtb', mimeType: 'application/vnd.webturbo' },
  { extension: 'wvx', mimeType: 'video/x-ms-wvx' },
  { extension: 'x32', mimeType: 'application/x-authorware-bin' },
  { extension: 'x3d', mimeType: 'model/x3d+xml' },
  { extension: 'x3db', mimeType: 'model/x3d+fastinfoset' },
  { extension: 'x3dbz', mimeType: 'model/x3d+binary' },
  { extension: 'x3dv', mimeType: 'model/x3d-vrml' },
  { extension: 'x3dvz', mimeType: 'model/x3d+vrml' },
  { extension: 'x3dz', mimeType: 'model/x3d+xml' },
  { extension: 'x_b', mimeType: 'model/vnd.parasolid.transmit.binary' },
  { extension: 'x_t', mimeType: 'model/vnd.parasolid.transmit.text' },
  { extension: 'xaml', mimeType: 'application/xaml+xml' },
  { extension: 'xap', mimeType: 'application/x-silverlight-app' },
  { extension: 'xar', mimeType: 'application/vnd.xara' },
  { extension: 'xav', mimeType: 'application/xcap-att+xml' },
  { extension: 'xbap', mimeType: 'application/x-ms-xbap' },
  { extension: 'xbd', mimeType: 'application/vnd.fujixerox.docuworks.binder' },
  { extension: 'xbm', mimeType: 'image/x-xbitmap' },
  { extension: 'xca', mimeType: 'application/xcap-caps+xml' },
  { extension: 'xcs', mimeType: 'application/calendar+xml' },
  { extension: 'xdf', mimeType: 'application/xcap-diff+xml' },
  { extension: 'xdm', mimeType: 'application/vnd.syncml.dm+xml' },
  { extension: 'xdp', mimeType: 'application/vnd.adobe.xdp+xml' },
  { extension: 'xdssc', mimeType: 'application/dssc+xml' },
  { extension: 'xdw', mimeType: 'application/vnd.fujixerox.docuworks' },
  { extension: 'xel', mimeType: 'application/xcap-el+xml' },
  { extension: 'xenc', mimeType: 'application/xenc+xml' },
  { extension: 'xer', mimeType: 'application/patch-ops-error+xml' },
  { extension: 'xfdf', mimeType: 'application/xfdf' },
  { extension: 'xfdl', mimeType: 'application/vnd.xfdl' },
  { extension: 'xht', mimeType: 'application/xhtml+xml' },
  { extension: 'xhtm', mimeType: 'application/vnd.pwg-xhtml-print+xml' },
  { extension: 'xhtml', mimeType: 'application/xhtml+xml' },
  { extension: 'xhvml', mimeType: 'application/xv+xml' },
  { extension: 'xif', mimeType: 'image/vnd.xiff' },
  { extension: 'xla', mimeType: 'application/vnd.ms-excel' },
  {
    extension: 'xlam',
    mimeType: 'application/vnd.ms-excel.addin.macroenabled.12',
  },
  { extension: 'xlc', mimeType: 'application/vnd.ms-excel' },
  { extension: 'xlf', mimeType: 'application/xliff+xml' },
  { extension: 'xlm', mimeType: 'application/vnd.ms-excel' },
  { extension: 'xls', mimeType: 'application/vnd.ms-excel' },
  {
    extension: 'xlsb',
    mimeType: 'application/vnd.ms-excel.sheet.binary.macroenabled.12',
  },
  {
    extension: 'xlsm',
    mimeType: 'application/vnd.ms-excel.sheet.macroenabled.12',
  },
  {
    extension: 'xlsx',
    mimeType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  },
  { extension: 'xlt', mimeType: 'application/vnd.ms-excel' },
  {
    extension: 'xltm',
    mimeType: 'application/vnd.ms-excel.template.macroenabled.12',
  },
  {
    extension: 'xltx',
    mimeType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.template',
  },
  { extension: 'xlw', mimeType: 'application/vnd.ms-excel' },
  { extension: 'xm', mimeType: 'audio/xm' },
  { extension: 'xml', mimeType: 'text/xml' },
  { extension: 'xns', mimeType: 'application/xcap-ns+xml' },
  { extension: 'xo', mimeType: 'application/vnd.olpc-sugar' },
  { extension: 'xop', mimeType: 'application/xop+xml' },
  { extension: 'xpi', mimeType: 'application/x-xpinstall' },
  { extension: 'xpl', mimeType: 'application/xproc+xml' },
  { extension: 'xpm', mimeType: 'image/x-xpixmap' },
  { extension: 'xpr', mimeType: 'application/vnd.is-xpr' },
  { extension: 'xps', mimeType: 'application/vnd.ms-xpsdocument' },
  { extension: 'xpw', mimeType: 'application/vnd.intercon.formnet' },
  { extension: 'xpx', mimeType: 'application/vnd.intercon.formnet' },
  { extension: 'xsd', mimeType: 'application/xml' },
  { extension: 'xsf', mimeType: 'application/prs.xsf+xml' },
  { extension: 'xsl', mimeType: 'application/xslt+xml' },
  { extension: 'xslt', mimeType: 'application/xslt+xml' },
  { extension: 'xsm', mimeType: 'application/vnd.syncml+xml' },
  { extension: 'xspf', mimeType: 'application/xspf+xml' },
  { extension: 'xul', mimeType: 'application/vnd.mozilla.xul+xml' },
  { extension: 'xvm', mimeType: 'application/xv+xml' },
  { extension: 'xvml', mimeType: 'application/xv+xml' },
  { extension: 'xwd', mimeType: 'image/x-xwindowdump' },
  { extension: 'xyz', mimeType: 'chemical/x-xyz' },
  { extension: 'xz', mimeType: 'application/x-xz' },
  { extension: 'yaml', mimeType: 'text/yaml' },
  { extension: 'yang', mimeType: 'application/yang' },
  { extension: 'yin', mimeType: 'application/yin+xml' },
  { extension: 'yml', mimeType: 'text/yaml' },
  { extension: 'ymp', mimeType: 'text/x-suse-ymp' },
  { extension: 'z1', mimeType: 'application/x-zmachine' },
  { extension: 'z2', mimeType: 'application/x-zmachine' },
  { extension: 'z3', mimeType: 'application/x-zmachine' },
  { extension: 'z4', mimeType: 'application/x-zmachine' },
  { extension: 'z5', mimeType: 'application/x-zmachine' },
  { extension: 'z6', mimeType: 'application/x-zmachine' },
  { extension: 'z7', mimeType: 'application/x-zmachine' },
  { extension: 'z8', mimeType: 'application/x-zmachine' },
  { extension: 'zaz', mimeType: 'application/vnd.zzazz.deck+xml' },
  { extension: 'zip', mimeType: 'application/zip' },
  { extension: 'zir', mimeType: 'application/vnd.zul' },
  { extension: 'zirz', mimeType: 'application/vnd.zul' },
  { extension: 'zmm', mimeType: 'application/vnd.handheld-entertainment+xml' },
];
