<script setup lang="ts">
  import TextArea from 'primevue/textarea';
  import Button from 'primevue/button';
  import { ref } from 'vue';
  import { useActiveJobStore } from '@src/store/activejob';
  import { useToast } from 'primevue/usetoast';
  import injectStrict from '@utils/vue/utils';
  import { SentinelKey } from '@utils/vue/symbols';
  import FormLabel from '@components/Shared/FormLabel.vue';
  import { useChatStore } from '@src/store/chat';
  import { Logger } from '@utils/logger';
  import { withCatch } from '@utils/await-safe';
  import useHelpersSpace from '@use/helpers';
  import DashboardWarnings from '@components/Dashboard/DashboardWarnings.vue';

  const { toString } = useHelpersSpace();
  const log = new Logger('ConfirmShift');
  const chatStore = useChatStore();
  const emits = defineEmits(['shift-started', 'close']);
  const comments = ref('');
  const loading = ref(false);
  const commentErrors = ref(false);
  const activeJobStore = useActiveJobStore();
  const sentinel = injectStrict(SentinelKey);
  const toast = useToast();

  async function clockIn(): Promise<void> {
    commentErrors.value = false;
    comments.value = comments.value ? comments.value.trim() : '';

    if (!comments.value || comments.value === '') {
      commentErrors.value = true;
      return;
    }

    const payload: ShiftEvent = { message: comments.value };

    loading.value = true;
    const [startShiftError] = await withCatch(activeJobStore.startShift(payload));
    loading.value = false;

    if (startShiftError) {
      log.error('Failed to start shift');
      log.error(startShiftError);

      toast.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Failed to start shift. ' + toString(startShiftError),
        life: 5000,
      });
      return;
    }
    sentinel.reportMachineInformation().catch();

    chatStore.composerFocusStealOverride = false;
    comments.value = '';

    emits('shift-started', true);
    emits('close');
  }

  function cancel(): void {
    emits('close');
  }
</script>

<template>
  <div>
    <div class="my-4">
      <DashboardWarnings />
    </div>
    <form @submit.prevent="clockIn">
      <FormLabel for-id="clock-in-text">Tasks for today</FormLabel>
      <TextArea id="clock-in-text" v-model="comments" :invalid="commentErrors" rows="5" class="w-full" auto-resize />
      <p v-if="commentErrors" id="startOfDayErrorText" class="text-sm text-red-500">Please fill out this field before continuing</p>
      <div class="mt-4 flex flex-col-reverse justify-end gap-2 sm:flex-row sm:flex-wrap md:space-x-2 md:space-y-0">
        <Button id="startShiftStartBtn" type="submit" severity="info" :loading="loading" @click="clockIn"> Start Shift </Button>
        <Button id="startShiftCancelBtn" severity="secondary" :loading="loading" outlined @click="cancel"> Cancel </Button>
      </div>
    </form>
  </div>
</template>
