import { defineStore } from 'pinia';
function getInitialState() {
  return {
    performingMeetingCheck: false,
    uploadingScreenshots: false,
    uploadingProcesses: false,
    uploadingMouseData: false,
    uploadingApplicationActivity: false,
    uploadingWebsites: false,
    connectingToMti: false,
  };
}
export const useOperationsStore = defineStore('operations', {
  state: () => getInitialState(),
  actions: {},
  getters: {
    isIdle: (state) => {
      return state.uploadingScreenshots === false && state.uploadingProcesses === false && state.uploadingMouseData === false && state.uploadingApplicationActivity === false && state.uploadingWebsites === false && state.connectingToMti === false;
    },
  },
});
