/**
 * Adds a value to an array in a map. If the key does not exist or the value at the key is not an array,
 * initializes the key with an array containing the new value.
 *
 * @template K - The type of the keys in the map.
 * @template V - The type of the values in the array.
 * @param {Map<K, V[]>} map - The map to which the value should be added.
 * @param {K} key - The key at which the value should be added.
 * @param {V} value - The value to add to the array at the specified key.
 */
export const addToArrayInMap = <K, V>(map: Map<K, V[]>, key: K, value: V) => {
  if (map.has(key) && Array.isArray(map.get(key))) {
    map.set(key, [...(map.get(key) ?? []), value]);
  } else {
    map.set(key, [value]);
  }
};

/**
 * Removes a value from an array in a map. If the key does not exist or the value at the key is not an array,
 * does nothing. If the resulting array is empty, removes the key from the map.
 *
 * @template K - The type of the keys in the map.
 * @template V - The type of the values in the array.
 * @param {Map<K, V[]>} map - The map from which the value should be removed.
 * @param {K} key - The key at which the value should be removed.
 * @param {V} value - The value to remove from the array at the specified key.
 */
export const removeFromArrayInMap = <K, V>(map: Map<K, V[]>, key: K, value: V) => {
  if (map.has(key) && Array.isArray(map.get(key))) {
    const updatedArray = (map.get(key) ?? []).filter((item) => item !== value);
    if (updatedArray.length > 0) {
      map.set(key, updatedArray);
    } else {
      map.delete(key);
    }
  }
};

/**
 * Removes values from an array in a map using a predicate function. If the key does not exist or the value at the key is not an array,
 * does nothing. If the resulting array is empty, removes the key from the map.
 *
 * @template K - The type of the keys in the map.
 * @template V - The type of the values in the array.
 * @param {Map<K, V[]>} map - The map from which the values should be removed.
 * @param {K} key - The key at which the values should be removed.
 * @param {(value: V) => boolean} predicate - The predicate function to determine which values to remove.
 */
export const removeFromArrayInMapWithPredicate = <K, V>(map: Map<K, V[]>, key: K, predicate: (value: V) => boolean) => {
  if (map.has(key) && Array.isArray(map.get(key))) {
    const updatedArray = (map.get(key) ?? []).filter((item) => !predicate(item));
    if (updatedArray.length > 0) {
      map.set(key, updatedArray);
    } else {
      map.delete(key);
    }
  }
};

/**
 * Adds a value to a map if the key is not already present.
 *
 * @template K - The type of the keys in the map.
 * @template V - The type of the values in the map.
 * @param {Map<K, V>} map - The map to which the value should be added.
 * @param {K} key - The key at which the value should be added.
 * @param {V} value - The value to add to the map if the key is not present.
 * @returns {boolean} - Returns true if the value was added, false if the key was already present.
 */
export const addToMapIfNotPresent = <K, V>(map: Map<K, V>, key: K, value: V): boolean => {
  if (!map.has(key)) {
    map.set(key, value);
    return true;
  }
  return false;
};
