<script setup lang="ts">
  import { EmojiManager } from '@utils/chat/emoji-manager';
  import { useChatStore } from '@src/store/chat';

  const emojiManager = EmojiManager.getInstance();
  const chatStore = useChatStore();

  const { reactionEmoji, emojiReactionTooltipMessage } = chatStore;

  const { normalizeEmojiInput } = emojiManager;
</script>

<template>
  <div class="bg-white p-4 shadow-md dark:bg-surface-950 dark:border-transparent dark:shadow-none max-w-64">
    <div class="flex items-center gap-4">
      <div class="shrink-0 reaction-lg">
        <div class="emojiSprite-32" :style="emojiManager.getBackgroundPosition(normalizeEmojiInput(reactionEmoji as string), 32)"></div>
      </div>
      <div class="text-sm text-surface-600 dark:text-surface-100">
        {{ emojiReactionTooltipMessage }}
      </div>
    </div>
    <!-- Arrow below -->
    <div class="absolute -translate-x-1/2 w-0 h-0 border-t-surface-0 dark:border-t-surface-950 -bottom-[8px] border-l-transparent border-r-transparent border-[8px] border-b-0 left-1/2"></div>
  </div>
</template>
