<script setup lang="ts">
  import { ChevronDownIcon } from '@heroicons/vue/20/solid';
  import { computed, nextTick, onMounted, onUnmounted, ref, watch } from 'vue';
  import { DateTime } from 'luxon';
  import ChatMessage from '@components/Chat/ChatMessage.vue';
  import { useChatStore } from '@src/store/chat';
  import { Channel, Message, MessageMentionedUsers, TimetokenUtils, User as ChatUser } from '@pubnub/chat';
  import ChatAdminMessage from '@components/Chat/ChatAdminMessage.vue';
  import PendingChatMessage from '@components/Chat/PendingChatMessage.vue';
  import ChatSkeletonMessage from '@components/Chat/ChatSkeletonMessage.vue';
  import ChatModerationMessage from '@components/Chat/ChatModerationMessage.vue';
  import { Logger } from '@utils/logger';
  import ModScroller from '@components/Scroll/ModScroller.vue';
  import { KnownChatUser, MyTimeInChatMessage } from '@src/types/ChatAndMessaging';
  import Skeleton from 'primevue/skeleton';

  const log = new Logger('CHAT_CHANNEL');

  const emit = defineEmits(['update-channel', 'delete-channel', 'refresh-participants', 'send-message']);

  interface ModScrollerInterface {
    scrollToBottom: () => void;
  }

  const modScroller = ref<ModScrollerInterface | null>(null);
  const chatStore = useChatStore();
  const globalResizeObserver = ref<ResizeObserver>();
  const channelContainer = ref<HTMLDivElement>();
  const updatingBlocked = ref(true);

  const currentUpdatingBlockedTimeout = ref<NodeJS.Timeout | null>(null);

  const rangeEnd = ref(0);
  const boundariesExistAtTimetokens = ref<Map<string, string>>(new Map<string, string>());

  const props = defineProps<{
    channel: Channel;
    currentUserId: string;
    loading: boolean;
    operatorMode: boolean;
  }>();

  /**
   * Returns skeleton loaders to help balance the chat during the first draw cycle, or messages and pending messages
   */
  const messages = computed(() => {
    return [...(chatStore.channelMessages.get(props.channel.id) ?? []), ...chatStore.currentPendingMessages];
  });

  watch(
    /* prettier-ignore */
    [
      () => props.channel.id,
      () => chatStore.firstDraw,
      () => chatStore.currentChannelMutations,
      () => chatStore.currentPendingMessages.length
    ],
    (newValues, oldValues) => {
      const [newChannelUrl, updatedFirstDraw, _newMutations, newPendingSends] = newValues;
      const [oldChannelUrl, oldFirstDraw, _oldMutations, oldPendingSends] = oldValues;
      if (oldFirstDraw !== updatedFirstDraw || newChannelUrl !== oldChannelUrl) {
        log.debug(`[chat] channel url changed, firing scroll to bottom`);
        blockUpdatingMomentarily();
        scrollToBottom();
        // reset the ranges (start value, end value)
        rangeEnd.value = 0;
        boundariesExistAtTimetokens.value = new Map<string, string>();
        calculateBoundaries();
        return;
      }
      if (newPendingSends !== oldPendingSends) {
        scrollToBottom();
        return;
      }
      // scrollToBottom();
      calculateBoundaries();
    },
    { flush: 'post' }
  );

  /**
   * Keeps a 1.5s block on calling update again when loading new history. Side effect of WebKit drawling issues.
   */
  const blockUpdatingMomentarily = () => {
    if (currentUpdatingBlockedTimeout.value) {
      clearTimeout(currentUpdatingBlockedTimeout.value);
    }
    updatingBlocked.value = true;
    currentUpdatingBlockedTimeout.value = setTimeout(() => {
      updatingBlocked.value = false;
    }, 2000);
  };

  /**
   * Calculates day boundaries based on the timetokens of the messages
   */
  const calculateBoundaries = () => {
    let boundariesExistAt = new Map<string, string>();
    let currentBoundary: string | null = null;
    for (let message of messages.value) {
      if (!message.timetoken) {
        return;
      }
      let messageDate = TimetokenUtils.timetokenToDate(message.timetoken);
      let currentFormattedDate = messageDate.toLocaleDateString();
      if (boundariesExistAt.size === 0) {
        boundariesExistAt.set(`${message.timetoken}`, currentFormattedDate);
        currentBoundary = `${message.timetoken}`;
        currentBoundary = currentFormattedDate;
      }
      if (currentBoundary === currentFormattedDate) {
        continue;
      }
      boundariesExistAt.set(`${message.timetoken}`, currentFormattedDate);
      currentBoundary = currentFormattedDate;
    }
    boundariesExistAtTimetokens.value = boundariesExistAt;
  };

  /**
   * This attempts to scroll to the bottom, using three paints to do it
   * This is a bit wonky, but because we have some items resizing during the initial load we can't always be sure that
   * we had enough frames to pin to the bottom
   *
   * Webkit has another issue where it does not paint when you think it normally would, so we have to give it a few attempts
   */
  const scrollToBottom = async () => {
    nextTick(() => {
      modScroller.value?.scrollToBottom();
      nextTick(() => {
        modScroller.value?.scrollToBottom();
        nextTick(() => {});
        requestAnimationFrame(() => {
          modScroller.value?.scrollToBottom();
        });
      });
    });
  };

  /**
   * This loads more while blocking updates for a moment due to a bug in safari where scroll inertia causes the container
   * to jump quickly to the top, which would trigger it multiple times.
   */
  const loadWithUpdateBlock = () => {
    console.log('LOADING PREVIOUS WITH UPDATE BLOCK?');
    if (updatingBlocked.value) {
      return;
    }
    loadMoreForChannel();
    blockUpdatingMomentarily();
  };

  /**
   * Instructs the chat store to fetch more messages from PubNub
   */
  const loadMoreForChannel = () => {
    chatStore.loadChannelMessages(props.channel.id);
  };

  /**
   * Watches for window resize events to keep the chat pinned to the bottom when container height changes
   */
  const installGlobalResizeObserver = () => {
    globalResizeObserver.value = new ResizeObserver(() => {
      if (!isScrolledAboveThreshold.value) {
        scrollToBottom();
      }
    });
    globalResizeObserver.value.observe(channelContainer.value);
  };

  const getMessageBorderClasses = (message: MyTimeInChatMessage) => {
    let classes = moderationClasses(message);
    if (!classes) {
      classes = mentionClasses(message);
    }
    return classes;
  };

  /**
   * Gives messages a special flourish when they mention the current user
   *
   * @param message
   */
  const mentionClasses = (message: MyTimeInChatMessage): string => {
    const mentionedUsers = message.mentionedUsers as MessageMentionedUsers;
    const isUserMentioned = Object.values(mentionedUsers).some((user) => user.id === props.currentUserId);
    const mentionEveryone = message.meta?.mentionEveryone;
    const mentionHere = message.meta?.mentionHere;

    if (isUserMentioned || mentionHere || mentionEveryone) {
      return 'border-l-2 border-inset bg-orange-50 border-yellow-500 dark:bg-orange-300/10 rounded-none';
    }
    return 'border-l-2 border-transparent hover:bg-surface-50 dark:hover:bg-surface-900/30';
  };

  const moderationClasses = (message: MyTimeInChatMessage): string => {
    if (message.userId === 'PUBNUB_INTERNAL_MODERATOR') {
      return 'border-l-2 border-inset bg-primary-50 border-primary-500 dark:bg-primary-300/10 rounded-none';
    }
    return '';
  };

  const getCurrentUser = (userId: string) => {
    return chatStore.knownUsersByUuid.get(userId);
  };

  /**
   * TODO: this is not reactive to updates on the mytimein-broadcast channel
   *
   * @param userId
   */
  const getNameFromUserId = (userId: string) => {
    return getCurrentUser(userId)?.name ?? 'Unknown';
  };

  /**
   * Return true if we are close enough to the bottom to push downward when a new message comes in, or show the "jump to current" button
   *
   * This could, realistically, be swapped out with *shift* as they are measuring a bit of the same thing
   */
  const isScrolledAboveThreshold = computed(() => {
    return rangeEnd.value + 10 < messages.value.length;
  });

  /**
   * TODO: this is not reactive to updates on the mytimein-broadcast channel
   *
   * @param userId
   */
  const getUserFromId = (userId: string) => {
    return chatStore.knownUsersByUuid.get(userId);
  };

  const handleScrollToMessage = ({ message }: { message: Message }) => {
    if (message.timetoken) {
      const index = messages.value.findIndex((msg) => msg.timetoken === message.timetoken);

      // TODO: use raw dom for this
    }
  };

  const sendMessageFromToolTip = (payload: { user: ChatUser; message: string }) => {
    emit('send-message', payload);
  };

  /**
   * Blocks updating for a moment due to scroll race conditions, and installs the resize observer
   */
  onMounted(() => {
    blockUpdatingMomentarily();
    installGlobalResizeObserver();
  });

  /**
   * Cleans up our resize observer when the component is unmounted
   */
  onUnmounted(() => {
    globalResizeObserver.value?.disconnect();
  });

  const updateRange = ({ start, end }: { start: number; end: number }) => {
    rangeEnd.value = end;
  };
</script>

<template>
  <div v-if="channel" ref="channelContainer" class="bg-white dark:bg-surface-800 channel-container relative" :data-url="channel.id" @contextmenu.stop.prevent="chatStore.closeAllOpenMenus()">
    <!--    <ChannelEmptyNotice v-if="messages?.length === 0" @load="loadMoreForChannel" />-->
    <Teleport v-if="isScrolledAboveThreshold" to="#composer-container">
      <div class="-top-3 left-0 absolute w-full text-center px-4">
        <button type="button" class="rounded-t-md bg-surface-500 dark:bg-surface-600 text-white w-full text-xs font-medium text-left px-4 h-8 flex items-center" @click="scrollToBottom">
          <span class="grow font-semibold"> You are viewing older messages </span>
          <span class="shrink-0 font-bold flex items-center gap-1 text-sm">
            <span>Go to Current</span>
            <ChevronDownIcon class="size-5" />
          </span>
        </button>
      </div>
    </Teleport>
    <ModScroller v-if="!loading" ref="modScroller" :items="messages" :emit-range-update="true" class="w-full" @range="updateRange" @load-prev="loadWithUpdateBlock" @first-draw="blockUpdatingMomentarily">
      <template #default="{ item }">
        <div v-if="boundariesExistAtTimetokens.has(item.timetoken)" class="relative flex items-center justify-center px-4 text-2xs py-2" @contextmenu.stop.prevent="chatStore.closeAllOpenMenus()">
          <div class="flex-grow border-t border-surface-300 dark:border-surface-700"></div>
          <span class="px-2 bg-white dark:bg-surface-800 text-surface-600 dark:text-surface-400 font-bold">{{ DateTime.fromFormat(boundariesExistAtTimetokens.get(item.timetoken) as string, 'M/d/yyyy').toLocaleString(DateTime['DATE_MED']) }}</span>
          <div class="flex-grow border-t border-surface-300 dark:border-surface-700"></div>
        </div>
        <div :class="[getMessageBorderClasses(item)]" class="mb-4">
          <suspense>
            <ChatModerationMessage v-if="item.userId === 'PUBNUB_INTERNAL_MODERATOR'" :message="item as Message" />
            <ChatAdminMessage v-else-if="item.meta?.type === 'admin'" :message="item as unknown as Message" />
            <PendingChatMessage v-else-if="item.meta?.type === 'pending'" :sender-name="getNameFromUserId(item.userId)" :sender-avatar="getUserFromId(item.userId)?.profileUrl" :custom-status="item.customStatus" />
            <ChatSkeletonMessage v-else-if="item.meta?.type === 'skeleton'" width="100%" height="72px" style="margin-top: 10px" />
            <ChatMessage
              v-else
              class="mb-1"
              :message="item as unknown as MyTimeInChatMessage"
              :current-user-id="currentUserId"
              :reactions="true"
              :sender-avatar="getUserFromId(item.userId)?.profileUrl"
              @scroll-to-message="handleScrollToMessage"
              @send-message="sendMessageFromToolTip"
            />
          </suspense>
        </div>
      </template>
    </ModScroller>
    <div v-else class="bg-white dark:bg-surface-800 w-full">
      <div class="w-full">
        <div v-for="i in 30" :key="i" class="flex items-center p-4">
          <Skeleton shape="circle" size="2.5rem" class="mr-2"></Skeleton>
          <div class="w-full">
            <Skeleton width="5rem" class="mb-2"></Skeleton>
            <Skeleton height="2rem" class="mb-2"></Skeleton>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
