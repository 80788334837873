import { DateTime } from 'luxon';
import { defineStore } from 'pinia';

function getInitialState() {
  return {
    localTime: DateTime.now(),
    clientTime: DateTime.now(),
    utcTime: DateTime.local({ zone: 'utc' }),

    manilaTime: DateTime.local({ zone: 'Asia/Manila' }),
    breakReminderAt: null as DateTime | null,
    shiftReminderAt: null as DateTime | null,
    nextMeetingCheckAt: null as DateTime | null,
    checkIfPastShiftEndAt: null as DateTime | null,
    beRightBackReturnTime: null as DateTime | null,
    overBreakSnoozeReturnTime: null as DateTime | null,

    breakStartedAt: null as DateTime | null,
    brbStartedAt: null as DateTime | null,

    // preset configurations for timers
    shiftAfterEndReminderTime: 15,

    // flags
    playedStartingShiftReminder: false as boolean,
    playedBreakReminder: false as boolean,
    playedHalfwayThroughShiftReminder: false as boolean,
    playedBreakAlarm: false as boolean,
    playedLateAlarm: false as boolean,

    // tracker
    nextCaptureTime: null as DateTime | null,

    machineLockedAt: null as DateTime | null,
  };
}
export const useTimerStore = defineStore('timer', {
  state: () => getInitialState(),
  actions: {
    updateCaptureTime(minutes = 5) {
      this.nextCaptureTime = DateTime.now().plus({ minutes: minutes });
    },
    setMachineLockedTime() {
      this.machineLockedAt = DateTime.now();
    },
    clearMachineLockedTime() {
      this.machineLockedAt = null;
    },
  },
  getters: {
    machineLockedTooLong: (state) => {
      if (!state.machineLockedAt) return false;
      return DateTime.now().diff(state.machineLockedAt, 'minutes').minutes > 15;
    },
  },
});
