<script setup lang="ts">
  import { DocumentTextIcon } from '@heroicons/vue/24/outline';
  import { TrashIcon, PencilSquareIcon } from '@heroicons/vue/16/solid';
  import MessageOptionButton from '@components/Chat/MessageOptionButton.vue';
  import { computed } from 'vue';

  const props = defineProps<{
    fileDetails: FileUploadDetails;
  }>();

  const emits = defineEmits(['delete', 'edit']);

  const deleteAttachment = () => {
    emits('delete');
  };

  const edit = () => {
    emits('edit');
  };

  const isImageFile = computed(() => {
    return props.fileDetails.file && props.fileDetails.file.type.startsWith('image/');
  });
  const isVideoFile = computed(() => {
    return props.fileDetails.file && props.fileDetails.file.type.startsWith('video/');
  });

  const thumbnail = computed(() => {
    return props.fileDetails.preview;
  });

  const isPending = computed(() => {
    return props.fileDetails.pending;
  });
</script>

<template>
  <div class="relative flex flex-col gap-2 rounded-md p-2 bg-surface-50 dark:bg-surface-800/60 w-[12.5rem] h-[12.5rem]">
    <div class="hover:shadow-md dark:hover:shadow-surface-900 absolute rounded-md border bg-white shadow-sm dark:shadow-none top-0 -right-5 dark:bg-surface-700/30 dark:border-surface-950">
      <div class="flex h-8 items-center justify-center">
        <MessageOptionButton tooltip="Edit" @click="edit">
          <template #icon>
            <PencilSquareIcon class="size-5" />
          </template>
        </MessageOptionButton>
        <MessageOptionButton tooltip="Remove Attachment" @click="deleteAttachment">
          <template #icon>
            <TrashIcon class="text-red-500 size-5" />
          </template>
        </MessageOptionButton>
      </div>
    </div>
    <div class="mb-2 flex-1 flex flex-col items-center justify-center h-36 w-full">
      <template v-if="!isPending">
        <div v-if="isImageFile" class="w-full">
          <div class="flex items-center justify-center rounded-md bg-surface-200 dark:bg-surface-700/80 w-full">
            <img :src="thumbnail" alt="File Preview" class="object-contain w-full max-h-[10rem]" />
          </div>
        </div>
        <div v-else-if="isVideoFile" class="w-full">
          <div class="flex items-center justify-center rounded-md bg-surface-200 dark:bg-surface-700/80 w-full">
            <video :src="thumbnail" alt="Video Preview" class="object-contain w-full max-h-[10rem]" />
          </div>
        </div>
        <div v-else class="flex h-full items-center justify-center w-full max-h-40">
          <DocumentTextIcon class="size-24" />
        </div>
      </template>
      <template v-else>
        <div class="flex items-center justify-center rounded-md bg-surface-200 dark:bg-surface-700/80 w-full max-h-[10rem]"></div>
      </template>
    </div>
    <div class="h-5 justify-end shrink-0 text-sm text-black line-clamp-1 dark:text-surface-200">
      {{ fileDetails.file.name }}
    </div>
  </div>
</template>
