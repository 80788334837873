<script setup lang="ts">
  import { ref } from 'vue';
  import EmojiRenderer from '@components/EmojiPicker/EmojiRenderer.vue';
  import { EmojiManager } from '@utils/chat/emoji-manager';
  import { useAppStore } from '@src/store/app';
  import { useChatStore } from '@src/store/chat';

  const emits = defineEmits(['emoji-selected', 'close-picker']);

  const appStore = useAppStore();
  const chatStore = useChatStore();
  const selectedCategory = ref<SimpleCategory>('People');
  const picker = ref<HTMLDivElement | null>(null);
  const emojiManager = EmojiManager.getInstance();
  const emojiGroups = emojiManager.getUiGroups();
  const tonePickerOpen = ref(false);

  const categoryMap = emojiManager.getCategoryMap();

  const setEmojiGroup = (key: SimpleCategory) => {
    selectedCategory.value = key;
  };

  const selectEmoji = (emoji: Emoji) => {
    appStore.updateEmojiStats(emoji.unified);
    emits('emoji-selected', emoji);
    chatStore.composerFocusStealOverride = false;
    chatStore.emojiPickerOpen = false;
    picker.value?.dispatchEvent(new CustomEvent('emoji-selected', { bubbles: true, detail: emoji }));
  };

  const close = (e: Event) => {
    emits('close-picker');

    if (chatStore.emojiPickerOpen) {
      chatStore.composerFocusStealOverride = false;
      chatStore.emojiPickerOpen = false;
      picker.value?.dispatchEvent(new CustomEvent('close-picker', { bubbles: true, detail: null }));
    }
  };

  const toggleTonePicker = (payload: { open: boolean }) => {
    tonePickerOpen.value = payload.open;
  };
</script>

<template>
  <div ref="picker" v-click-away="close" class="flex h-[425px] w-[700px] max-w-md bg-white shadow dark:bg-surface-800 dark:shadow-none border dark:border-black rounded-md" data-testid="emoji-panel-picker" @contextmenu.stop.prevent>
    <div class="flex shrink-0 select-none flex-col justify-center gap-y-0.5 overflow-y-auto bg-surface-100 text-xl w-12 dark:border-white/10 dark:bg-surface-950 items-center">
      <button
        v-for="(emoji, key) in emojiGroups"
        :key="`${emoji.category}-${key}-${appStore.settings?.emojiSkinTone}`"
        class="p-1 rounded-md"
        :class="[categoryMap[emoji.category] === selectedCategory ? 'bg-surface-200 dark:bg-surface-700' : 'hover:bg-surface-200 dark:hover:bg-surface-700']"
        @click="setEmojiGroup(categoryMap[emoji.category] as SimpleCategory)"
      >
        <div class="emojiSprite-32" :style="emojiManager.getBackgroundPosition(emoji.unified, 32)"></div>
      </button>
    </div>
    <EmojiRenderer class="w-full flex-grow" :category="selectedCategory" :picker-open="tonePickerOpen" @emoji-selected="selectEmoji" @close="close" @tone-picker-open="toggleTonePicker" />
  </div>
</template>
