import { MtiModule } from '@modules/index';
import { App } from 'vue';
import { createPinia } from 'pinia';
import { TauriSyncPlugin } from '@src/store/tauri_sync';
import { createSentryPiniaPlugin } from '@sentry/vue';

export default <MtiModule>{
  onVueAppInit(app: App) {
    const pinia = createPinia();
    pinia.use(TauriSyncPlugin);
    if (import.meta.env.MODE !== 'development') {
      pinia.use(createSentryPiniaPlugin());
    }
    app.use(pinia);
  },
};
