<script setup lang="ts">
  import { computed, onMounted, ref, toRefs, watch } from 'vue';
  import InputText from 'primevue/inputtext';
  import { useAppStore } from '@src/store/app';
  import EmojiSkinTonePicker from '@components/EmojiPicker/EmojiSkinTonePicker.vue';
  import { EmojiManager } from '@utils/chat/emoji-manager';

  const emojiManager = EmojiManager.getInstance();
  const emojiCategories = emojiManager.getUiGroups();
  const emojiGroups = emojiManager.getGroupedEmojis();
  const emojiMap = emojiManager.getDefaultEmojiMap();

  const appStore = useAppStore();

  const emits = defineEmits(['emoji-selected', 'close', 'tonePickerOpen']);
  const searchInput = ref<null | InstanceType<typeof InputText>>(null);
  const hoveringEmoji = ref<Emoji>();

  const props = defineProps<{
    category: SimpleCategory;
    pickerOpen: boolean;
  }>();

  const { category } = toRefs(props);
  const query = ref<string>('');
  const selectEmoji = (emoji: Emoji) => {
    emits('emoji-selected', emoji);
  };

  const tempSelected = ref<number>(0);

  const emojis = computed((): Emoji[] => {
    let filteredEmojis: Emoji[] = [];
    if (!emojiCategories.length) {
      return [];
    }
    if (!query.value || query.value.length < 3) {
      let emojisInCategory: Emoji[] = emojiGroups[props.category];
      emojisInCategory.forEach((emoji) => {
        filteredEmojis.push(emoji);
      });
      return filteredEmojis;
    }

    for (const [key, emoji] of emojiMap) {
      if (emoji.short_name?.includes(':' + query.value)) {
        filteredEmojis.push(emoji);
      }
      if (emoji.short_names) {
        emoji.short_names?.forEach((name) => {
          if (name.toLowerCase().includes(query.value)) {
            if (!filteredEmojis.find((e) => e.unified === emoji.unified)) {
              //only add if it's not there already
              filteredEmojis.push(emoji);
            }
          }
        });
      }
    }
    return filteredEmojis;
  });

  watch(category, () => {
    tempSelected.value = 0;
    document.getElementById(`emoji-index-${tempSelected.value}`)?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
    requestAnimationFrame(() => {
      searchInput.value?.$el?.focus();
    });
    query.value = '';
  });

  const selectEmoteEnter = () => {
    if (emojis.value.length === 1) {
      selectEmoji(emojis.value[0]);
    } else {
      if (emojis.value.length) {
        selectEmoji(emojis.value[tempSelected.value]);
      }
    }
  };

  const close = () => {
    emits('close');
  };

  const handleUp = (e: KeyboardEvent) => {
    e.preventDefault();
    if (tempSelected.value - 7 >= 0) {
      tempSelected.value -= 7;
    } else if (tempSelected.value - 7 < 0) {
      tempSelected.value = 0;
    }
    document.getElementById(`emoji-index-${tempSelected.value}`)?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
  };
  const handleDown = (e: KeyboardEvent) => {
    e.preventDefault();
    if (tempSelected.value + 7 <= emojis.value.length - 1) {
      tempSelected.value += 7;
    } else if (tempSelected.value + 7 > emojis.value.length - 1) {
      tempSelected.value = emojis.value.length - 1;
    }
    document.getElementById(`emoji-index-${tempSelected.value}`)?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
  };
  const handleLeft = (e: KeyboardEvent) => {
    e.preventDefault();
    if (tempSelected.value - 1 >= 0) {
      tempSelected.value -= 1;
    }
    document.getElementById(`emoji-index-${tempSelected.value}`)?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
  };
  const handleRight = (e: KeyboardEvent) => {
    e.preventDefault();
    if (tempSelected.value + 1 <= emojis.value.length - 1) {
      tempSelected.value += 1;
    }
    document.getElementById(`emoji-index-${tempSelected.value}`)?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
  };

  const handleTab = (e: KeyboardEvent) => {
    e.preventDefault();
    if (e.shiftKey) {
      handleLeft(e);
    } else {
      handleRight(e);
    }
  };

  const emitTonePickerOpen = (payload: { open: boolean }) => {
    emits('tonePickerOpen', payload);
  };

  const emitTonePickerClosed = () => {
    emits('tonePickerOpen', { open: false });
  };
  const handleEmojiLeave = () => {
    hoveringEmoji.value = undefined;
  };
  const handleEmojiEnter = (emoji: Emoji) => {
    hoveringEmoji.value = emoji;
  };

  onMounted(() => {
    requestAnimationFrame(() => {
      searchInput.value?.$el?.focus();
    });
  });
</script>

<template>
  <div class="flex flex-col">
    <div class="flex flex-grow flex-col overflow-hidden p-2 dark:bg-surface-800">
      <div class="flex items-center gap-2">
        <div class="relative grow">
          <i class="absolute top-2/4 right-3 -mt-2 pi pi-search text-surface-400 dark:text-surface-600" />
          <InputText
            ref="searchInput"
            v-model="query"
            size="small"
            class="w-full"
            placeholder="Search for an emoji."
            spellcheck="false"
            autocapitalize="off"
            @keydown.enter="selectEmoteEnter"
            @keydown.esc="close"
            @keydown.right="handleRight"
            @keydown.left="handleLeft"
            @keydown.up="handleUp"
            @keydown.down="handleDown"
            @keydown.tab="handleTab"
          />
        </div>
        <div class="relative flex w-12 shrink-0 items-center">
          <EmojiSkinTonePicker v-click-away="emitTonePickerClosed" :open="pickerOpen" @open="emitTonePickerOpen" />
        </div>
      </div>

      <div class="uppercase text-2xs font-medium text-surface-500 my-1">
        {{ category }}
      </div>
      <div class="grid select-none grid-cols-7 overflow-y-auto overflow-x-hidden text-3xl small-scroll">
        <div v-for="(emoji, emojiIndex) in emojis" :key="emoji.unified + `-` + appStore.settings.emojiSkinTone">
          <button
            :id="`emoji-index-${emojiIndex}`"
            class="rounded-md p-1 picker-emoji hover:bg-surface-200 dark:hover:bg-surface-700"
            :class="[tempSelected === emojiIndex ? 'bg-surface-200 dark:bg-surface-700' : '']"
            @click="selectEmoji(emoji)"
            @mouseenter="handleEmojiEnter(emoji)"
            @mouseleave="handleEmojiLeave"
          >
            <div class="emojiSprite-32" :style="emojiManager.getBackgroundPosition(emoji.unified, 32)" />
          </button>
        </div>
      </div>
    </div>
    <div class="flex h-12 shrink-0 items-center bg-surface-50 dark:text-surface-200 dark:bg-surface-900 dark:border-white/10">
      <div v-if="emojis && emojis.length" class="flex items-center gap-1 truncate p-2">
        <template v-if="hoveringEmoji">
          <div class="text-3xl">
            <div class="emojiSprite-32" :style="emojiManager.getBackgroundPosition(hoveringEmoji.unified, 32)" />
          </div>
          <div class="truncate text-sm max-w-72">:{{ hoveringEmoji.short_name }}:</div>
        </template>
        <template v-else>
          <div :key="tempSelected">
            <div class="emojiSprite-32" :style="emojiManager.getBackgroundPosition(emojis[tempSelected].unified, 32)" />
          </div>
          <div class="truncate text-sm max-w-72">:{{ emojis[tempSelected].short_name }}:</div>
        </template>
      </div>
    </div>
  </div>
</template>
