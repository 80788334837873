import { Node } from '@tiptap/core';
import { Plugin, PluginKey } from 'prosemirror-state';
import { DOMSerializer } from 'prosemirror-model';

export default Node.create({
  name: 'copyHandler',

  addProseMirrorPlugins() {
    return [
      new Plugin({
        key: new PluginKey('copyHandler'),

        props: {
          handleDOMEvents: {
            copy: (view, event) => {
              // Prevent the default copy behavior
              // Default will make us copy and duplicate new lines
              event.preventDefault();

              // Get the selected content range
              const { from, to } = view.state.selection;

              // Extract HTML content
              const slice = view.state.doc.slice(from, to);
              const div = document.createElement('div');
              const fragment = DOMSerializer.fromSchema(view.state.schema).serializeFragment(slice.content);
              div.appendChild(fragment);
              const html = div.innerHTML;

              // Extract plain text content, preserving newlines
              const plainText = slice.content.textBetween(0, slice.content.size, '\n');

              // Set the clipboard data
              event.clipboardData?.setData('text/html', html);
              event.clipboardData?.setData('text/plain', plainText);

              return false;
            },
          },
        },
      }),
    ];
  },
});
