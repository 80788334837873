import { KnownChatChannel, KnownChatUser } from '@src/types/ChatAndMessaging';

class ChatService {
  private static instance: ChatService;
  private worker: Worker;

  private constructor() {
    this.worker = new Worker(new URL('@src/workers/chat-cache-worker.ts', import.meta.url));
    this.worker.onmessage = this.handleWorkerMessage.bind(this);
    this.worker.onerror = (e) => {
      console.error(e);
    };
  }

  public static getInstance(): ChatService {
    if (!ChatService.instance) {
      ChatService.instance = new ChatService();
    }
    return ChatService.instance;
  }

  private handleWorkerMessage(event: MessageEvent) {
    if (event.data.type === 'debug') {
      console.log(event.data.message);
      return;
    }

    switch (event.data.type) {
      case 'data':
        this.handleDataMessage(event.data.message);
        break;
      default:
        console.warn(`Unknown message type: ${event.data.type}`);
    }
  }

  private handleDataMessage(message: any) {
    switch (message.type) {
      case 'debug':
        console.log(message.message);
        break;
      case 'users-data':
        this.resolvePromise('get-users', message.users);
        break;
      case 'channels-data':
        this.resolvePromise('get-channels', message.channels);
        break;
      case 'channel-order-data':
        this.resolvePromise('get-channel-order', message.channelOrder);
        break;
      default:
        console.warn(`Unknown data message type: ${message.type}`);
    }
  }

  private promiseResolvers: { [key: string]: (value: any) => void } = {};
  private promiseRejectors: { [key: string]: (reason?: any) => void } = {};

  private createPromise(type: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.promiseResolvers[type] = resolve;
      this.promiseRejectors[type] = reject;
    });
  }

  private resolvePromise(type: string, value: any) {
    if (this.promiseResolvers[type]) {
      this.promiseResolvers[type](value);
      delete this.promiseResolvers[type];
      delete this.promiseRejectors[type];
    }
  }

  private rejectPromise(type: string, reason: any) {
    if (this.promiseRejectors[type]) {
      this.promiseRejectors[type](reason);
      delete this.promiseResolvers[type];
      delete this.promiseRejectors[type];
    }
  }

  public async getUsers(): Promise<KnownChatUser[]> {
    this.worker.postMessage({ type: 'get-users' });
    return this.createPromise('get-users');
  }

  public async addUsers(users: KnownChatUser[]): Promise<void> {
    this.worker.postMessage({ type: 'put-users', users });
    return this.createPromise('put-users');
  }

  public async addUser(user: KnownChatUser): Promise<void> {
    this.worker.postMessage({ type: 'put-user', user });
    return this.createPromise('put-user');
  }

  public async getChannels(): Promise<KnownChatChannel[]> {
    this.worker.postMessage({ type: 'get-channels' });
    return this.createPromise('get-channels');
  }

  public async addChannel(channel: KnownChatChannel): Promise<void> {
    this.worker.postMessage({ type: 'put-channel', channel });
    return this.createPromise('put-channel');
  }

  public async addChannels(channels: KnownChatChannel[]): Promise<void> {
    this.worker.postMessage({ type: 'put-channels', channels });
    return this.createPromise('put-channels');
  }

  public async setChannelOrder(channelOrder: string[]): Promise<void> {
    console.log('[chat-service] setting channel order');
    this.worker.postMessage({ type: 'set-channel-order', channelOrder });
    return this.createPromise('set-channel-order');
  }

  public async getChannelOrder(): Promise<string[]> {
    console.log('[chat-service] getting channel order');
    this.worker.postMessage({ type: 'get-channel-order' });
    console.log('[chat-service] waiting for reply');
    return this.createPromise('get-channel-order');
  }
}

export default ChatService;
