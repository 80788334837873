<script setup lang="ts">
  import { useChatStore } from '@src/store/chat';
  import { calculateTooltipPosition } from '@utils/chat/tooltip-calculator';

  const emits = defineEmits(['click']);
  const chatStore = useChatStore();

  const props = defineProps<{
    tooltip?: string;
  }>();

  const handleClick = (e: MouseEventInit) => {
    emits('click', e);
  };

  const showMessageOptionTooltip = (event: MouseEvent) => {
    chatStore.messageOptionTooltipMessage = props.tooltip as string;
    chatStore.showMessageOptionTooltip = true;

    requestAnimationFrame(() => {
      let success = calculateTooltipPosition(event.target as HTMLElement, 'chat-layer2', 'message-options-tooltip', 'top', 10, 10);
      if (!success) {
        chatStore.messageOptionTooltipMessage = null;
        chatStore.showMessageOptionTooltip = false;
      }
    });
  };

  const hideMessageOptionTooltip = () => {
    chatStore.showMessageOptionTooltip = false;
  };
</script>

<template>
  <div
    class="message-option-button flex h-full w-8 items-center justify-center text-surface-600 hover:bg-surface-200 dark:text-surface-400 hover:active:text-surface-900 dark:hover:bg-surface-700 dark:hover:active:text-surface-200 dark:bg-surface-800"
    @mouseenter="showMessageOptionTooltip"
    @mouseleave="hideMessageOptionTooltip"
  >
    <button type="button" class="w-full h-full" :data-testid="`message-option-${tooltip}`" @click="handleClick">
      <div class="flex h-full w-full items-center justify-center active:translate-y-[1px] pointer-events-none">
        <slot name="icon" />
      </div>
    </button>
  </div>
</template>
