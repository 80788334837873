<script setup lang="ts">
  import { Message } from '@pubnub/chat';
  import injectStrict from '@utils/vue/utils';
  import { MarkdownServiceKey } from '@utils/vue/symbols';
  import { toRefs } from 'vue';

  const markdownService = injectStrict(MarkdownServiceKey);

  const props = defineProps<{
    message: Message;
  }>();

  const { message } = toRefs(props);
</script>

<template>
  <div class="px-6 py-2 text-sm text-center select-text">
    <div class="font-bold mb-2">Admin Notice</div>
    <div v-html="markdownService.render(message.text, `${message.timetoken}_1`)" />
  </div>
</template>

<style scoped></style>
