const emojiUIGroups: Emoji[] = [
  {
    name: 'GRINNING FACE',
    unified: '1F600',
    non_qualified: null,
    docomo: null,
    au: null,
    softbank: null,
    google: null,
    image: '1f600.png',
    sheet_x: 32,
    sheet_y: 46,
    short_name: 'grinning',
    short_names: ['grinning'],
    text: ':D',
    texts: null,
    category: 'Smileys & Emotion',
    subcategory: 'face-smiling',
    sort_order: 1,
    added_in: '1.0',
    has_img_apple: true,
    has_img_google: true,
    has_img_twitter: true,
    has_img_facebook: true,
  },
  {
    name: 'HAMBURGER',
    unified: '1F354',
    non_qualified: null,
    docomo: 'E673',
    au: 'E4D6',
    softbank: 'E120',
    google: 'FE960',
    image: '1f354.png',
    sheet_x: 6,
    sheet_y: 16,
    short_name: 'hamburger',
    short_names: ['hamburger'],
    text: null,
    texts: null,
    category: 'Food & Drink',
    subcategory: 'food-prepared',
    sort_order: 763,
    added_in: '0.6',
    has_img_apple: true,
    has_img_google: true,
    has_img_twitter: true,
    has_img_facebook: true,
  },
  {
    name: 'MONKEY',
    unified: '1F412',
    non_qualified: null,
    docomo: null,
    au: 'E4D9',
    softbank: 'E528',
    google: 'FE1CE',
    image: '1f412.png',
    sheet_x: 11,
    sheet_y: 13,
    short_name: 'monkey',
    short_names: ['monkey'],
    text: null,
    texts: null,
    category: 'Animals & Nature',
    subcategory: 'animal-mammal',
    sort_order: 560,
    added_in: '0.6',
    has_img_apple: true,
    has_img_google: true,
    has_img_twitter: true,
    has_img_facebook: true,
  },
  {
    name: 'GUITAR',
    unified: '1F3B8',
    non_qualified: null,
    docomo: null,
    au: 'E506',
    softbank: 'E041',
    google: 'FE816',
    image: '1f3b8.png',
    sheet_x: 7,
    sheet_y: 54,
    short_name: 'guitar',
    short_names: ['guitar'],
    text: null,
    texts: null,
    category: 'Objects',
    subcategory: 'musical-instrument',
    sort_order: 1217,
    added_in: '0.6',
    has_img_apple: true,
    has_img_google: true,
    has_img_twitter: true,
    has_img_facebook: true,
  },
  {
    name: 'SOCCER BALL',
    unified: '26BD',
    non_qualified: null,
    docomo: 'E656',
    au: 'E4B6',
    softbank: 'E018',
    google: 'FE7D4',
    image: '26bd.png',
    sheet_x: 59,
    sheet_y: 11,
    short_name: 'soccer',
    short_names: ['soccer'],
    text: null,
    texts: null,
    category: 'Activities',
    subcategory: 'sport',
    sort_order: 1092,
    added_in: '0.6',
    has_img_apple: true,
    has_img_google: true,
    has_img_twitter: true,
    has_img_facebook: true,
  },
  {
    name: 'Philippines Flag',
    unified: '1F1F5-1F1ED',
    non_qualified: null,
    docomo: null,
    au: null,
    softbank: null,
    google: null,
    image: '1f1f5-1f1ed.png',
    sheet_x: 3,
    sheet_y: 27,
    short_name: 'flag-ph',
    short_names: ['flag-ph'],
    text: null,
    texts: null,
    category: 'Flags',
    subcategory: 'country-flag',
    sort_order: 1825,
    added_in: '2.0',
    has_img_apple: true,
    has_img_google: true,
    has_img_twitter: true,
    has_img_facebook: true,
  },
  {
    name: 'INPUT SYMBOL FOR LATIN LETTERS',
    unified: '1F524',
    non_qualified: null,
    docomo: null,
    au: 'EB55',
    softbank: null,
    google: 'FEB80',
    image: '1f524.png',
    sheet_x: 30,
    sheet_y: 28,
    short_name: 'abc',
    short_names: ['abc'],
    text: null,
    texts: null,
    category: 'Symbols',
    subcategory: 'alphanum',
    sort_order: 1566,
    added_in: '0.6',
    has_img_apple: true,
    has_img_google: true,
    has_img_twitter: true,
    has_img_facebook: true,
  },
  {
    name: 'EARTH GLOBE AMERICAS',
    unified: '1F30E',
    non_qualified: null,
    docomo: null,
    au: null,
    softbank: null,
    google: null,
    image: '1f30e.png',
    sheet_x: 5,
    sheet_y: 8,
    short_name: 'earth_americas',
    short_names: ['earth_americas'],
    text: null,
    texts: null,
    category: 'Travel & Places',
    subcategory: 'place-map',
    sort_order: 848,
    added_in: '0.7',
    has_img_apple: true,
    has_img_google: true,
    has_img_twitter: true,
    has_img_facebook: true,
  },
];

export default emojiUIGroups;
