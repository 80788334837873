export const serializeMap = (mapObject: Map<any, any>): string => {
  // if there is key that is an empty string, remove it
  for (const key of mapObject.keys()) {
    if (key === '') {
      mapObject.delete(key);
    }
  }
  return JSON.stringify([...mapObject.entries()]);
};

export const restoreSerializedMap = <K, V>(serialized: string): Map<K, V> => {
  try {
    return new Map<K, V>(JSON.parse(serialized));
  } catch (e) {
    return new Map<K, V>();
  }
};
