<script setup lang="ts">
  import { computed } from 'vue';
  import { StarIcon, ChevronUpDownIcon } from '@heroicons/vue/24/outline';
  import { Channel } from '@pubnub/chat';
  import { useAppStore } from '@src/store/app';
  import { useChatStore } from '@src/store/chat';
  import MuteIcon from '@components/svgs/MuteIcon.vue';

  const props = withDefaults(
    defineProps<{
      channel: Channel;
      currentChannelUrl: string;
      mentions: undefined | number;
      alerts: undefined | number;
      favorite: boolean;
      canFavorite?: boolean;
      draggable?: boolean;
    }>(),
    {
      mentions: undefined,
      alerts: undefined,
      favorite: false,
      canFavorite: true,
    }
  );

  const emit = defineEmits(['change-channel', 'hide', 'favorite', 'unfavorite', 'leave']);
  const appStore = useAppStore();
  const chatStore = useChatStore();

  const toggleFavorite = () => {
    if (props.favorite) {
      emit('unfavorite', props.channel.id);
      return;
    }
    emit('favorite', props.channel.id);
  };

  const isConversation = computed(() => {
    return props.channel.id.startsWith('group') || props.channel.id.startsWith('direct');
  });

  const isDirectMessage = computed(() => {
    return props.channel.id.startsWith('direct');
  });

  const isFavorite = computed(() => {
    return appStore.favoriteChannels.find((channel) => channel.id === props.channel.id);
  });

  const channelName = computed(() => {
    if (!props.channel.id.includes('direct.') && !props.channel.id.includes('group.')) {
      return props.channel.name ?? '';
    }

    const participantSet = chatStore.channelParticipantsMinimal.get(props.channel.id);

    if (!participantSet || participantSet.size === 0) {
      return props.channel.name ?? '...';
    }

    const participants = Array.from(participantSet.values());
    if (participants.length === 1 && participants[0] === chatStore.currentUserId) {
      return chatStore.knownUsersByUuid.get(chatStore.currentUserId)?.name ?? '...';
    }

    const userNames = participants
      .filter((user) => user !== chatStore.currentUserId)
      .map((user) => chatStore.knownUsersByUuid.get(user)?.name ?? '...')
      .sort();

    return userNames.join(', ');
  });

  const changeChannel = () => {
    if (props.currentChannelUrl === props.channel.id) {
      return;
    }
    emit('change-channel', props.channel.id);
  };

  const recipientStatus = computed(() => {
    if (!isConversation.value) {
      return 'Offline';
    }

    let userSet = chatStore.channelParticipantsMinimal.get(props.channel.id);

    if (!userSet) return 'Offline';

    for (let user of userSet) {
      if (user !== chatStore.currentUserId) {
        let isOnline = chatStore.onlineUserIds.has(user);

        if (isOnline) {
          return 'Online';
        }

        let isIdle = chatStore.idleUserIds.has(user);
        if (isIdle) {
          return 'Idle';
        }
      }
    }

    return 'Offline';
  });
</script>

<template>
  <div>
    <button
      :key="`group-${channel.id}`"
      class="w-full px-4 py-1 text-left transition duration-150 group line-clamp-1 text-surface-600 dark:text-surface-400"
      :class="[currentChannelUrl === channel.id ? 'bg-primary-100 dark:bg-primary-100/10' : 'hover:bg-primary-50 hover:dark:bg-primary-100/5']"
      @click="changeChannel"
    >
      <div class="flex items-center gap-2">
        <div v-if="isConversation" class="shrink-0 flex items-center justify-center relative">
          <div v-if="isDirectMessage && recipientStatus === 'Online'" class="size-2 rounded-full bg-green-500"></div>
          <div v-else-if="isDirectMessage && recipientStatus === 'Offline'" class="size-2 rounded-full bg-surface-500"></div>
          <div v-else-if="isDirectMessage && recipientStatus === 'Idle'" class="size-2 rounded-full bg-yellow-500"></div>
          <div v-else class="size-2">
            <!--Placeholder to keep spacing-->
          </div>
        </div>
        <div v-else class="shrink-0 text-xl mr-0.5 relative">
          <span>#</span>
          <div v-if="alerts" class="absolute -left-5 top-2 z-10 bg-black dark:bg-white rounded-r-full size-2">&nbsp;</div>
        </div>
        <div class="relative flex-1 truncate flex items-center text-sm">
          <p class="truncate flex-grow" :class="[alerts ? 'font-extrabold dark:text-surface-100 text-black' : 'font-light']">
            {{ channelName }}
          </p>
          <div class="relative shrink-0 w-8 h-6 flex items-center text-right">
            <div class="absolute right-1 -z-10 w-full group-hover:hidden">
              <div v-if="isConversation">
                <div v-if="alerts" class="inline-flex items-center justify-center rounded-full font-black outline outline-1 text-[9px] size-4" :class="[mentions ? 'bg-red-500 text-red-50 dark:text-red-950 outline-red-600' : 'bg-surface-500 text-white dark:bg-surface-100 dark:text-surface-950']">
                  {{ alerts }}
                </div>
              </div>
              <div v-else>
                <div v-if="mentions" class="inline-flex items-center justify-center rounded-full font-black outline outline-1 text-[9px] size-4 bg-red-500 text-red-50 dark:text-red-950 outline-red-600">
                  {{ mentions }}
                </div>
              </div>
            </div>
            <button v-if="draggable" type="button" aria-haspopup="false" class="group/drag drag-handle">
              <ChevronUpDownIcon class="size-5" />
            </button>
            <div v-if="canFavorite || draggable" class="absolute right-0 top-0.5 z-50 hidden shrink-0 group-hover:block">
              <button v-show="canFavorite" type="button" aria-haspopup="true" aria-controls="overlay_menu" class="group/favorite" @click.stop="toggleFavorite">
                <StarIcon class="size-5" :class="[!isFavorite ? 'text-yellow-500 group-hover/favorite:fill-yellow-500' : 'text-yellow-500 fill-yellow-500 group-hover/favorite:text-white-500 group-hover/favorite:fill-transparent']" />
              </button>
            </div>
            <div>
              <MuteIcon v-if="chatStore.mutedChannels.has(channel.id)" class="size-3 text-zinc-400/80" />
            </div>
          </div>
        </div>
      </div>
    </button>
  </div>
</template>
