<script setup lang="ts">
  import { computed, nextTick, onMounted, ref, toRefs } from 'vue';
  import InputText from 'primevue/inputtext';
  import Dialog from 'primevue/dialog';
  import { MagnifyingGlassIcon } from '@heroicons/vue/24/outline';
  import { useChatStore } from '@src/store/chat';
  import { KnownChatUser } from '@src/types/ChatAndMessaging';

  const props = defineProps<{
    visible: boolean;
  }>();

  const chatStore = useChatStore();
  const emit = defineEmits(['update', 'close']);
  const { visible } = toRefs(props);
  const searchQuery = ref('');
  const addMemberInput = ref<HTMLInputElement>();

  const updateChannelForm = ref({
    participants: [] as KnownChatUser[],
    exposeHistory: false as boolean,
  });

  const searchResults = computed(() => {
    if (searchQuery.value === '') {
      return chatStore.fullNetwork;
    }
    return chatStore.fullNetwork.filter((user) => {
      return user.name?.toLowerCase().includes(searchQuery.value.toLowerCase());
    });
  });

  const close = () => {
    emit('close');
  };

  const addParticipant = (user: KnownChatUser) => {
    updateChannelForm.value.participants.push(user);
    emit('update', updateChannelForm.value);
    updateChannelForm.value.participants = [];
  };

  const removeParticipant = (id: string) => {
    updateChannelForm.value.participants = updateChannelForm.value.participants.filter((participant) => participant.id !== id);
    emit('update', updateChannelForm.value);
  };

  const isParticipating = (id: string) => {
    return chatStore.currentChannelParticipants.has(id);
  };

  const getInitials = (name: string | undefined) => {
    if (!name) return '';
    return name
      .split(' ')
      .map((word) => word[0])
      .join('')
      .slice(0, 2);
  };

  onMounted(async () => {
    if (!chatStore.fullNetwork.length) {
      await chatStore.refreshChatNetwork();
    }
    nextTick(() => {
      if (addMemberInput.value) {
        addMemberInput.value.$el.focus();
      }
    });
  });
</script>

<template>
  <Dialog :visible="visible" header="Invite users to chat" modal :draggable="false" class="w-2/5 dark:text-surface-300 select-none md:w-2/3 xl:w-1/3" @update:visible="close">
    <p class="text-xs mb-4">When adding users to direct conversations (between 2 people) - a separate channel will be made.</p>
    <div class="relative group">
      <InputText ref="addMemberInput" v-model="searchQuery" placeholder="Search for users" autocomplete="false" class="w-full pr-10" />
      <MagnifyingGlassIcon class="size-6 absolute top-2 right-2 text-surface-400" />
    </div>
    <hr class="-mx-6 my-4 border-t dark:border-surface-700" />
    <div class="max-h-48 overflow-y-auto pr-1 space-y-3 small-scroll">
      <div v-for="user in searchResults" :key="user.id" class="flex w-full items-center rounded-md px-3 py-1 group hover:bg-surface-100 dark:hover:bg-surface-700/30">
        <div class="flex grow items-center gap-x-2">
          <img v-if="user.profileUrl" :src="user.profileUrl" class="rounded-full size-8" alt="" />
          <div v-else class="flex items-center justify-center rounded-full outline outline-1 size-8 bg-surface-200 outline-surface-400 dark:bg-surface-700 dark:outline-black">
            {{ getInitials(user.name) }}
          </div>
          <div class="text-sm font-light dark:text-surface-400">
            {{ user.name }}
          </div>
        </div>
        <button
          v-if="!isParticipating(user.id)"
          class="rounded-md border px-6 py-2 text-xs border-primary-500 group-hover:bg-primary-500 hover:bg-primary-500 hover:text-white group-hover:text-white dark:border-primary-700 dark:group-hover:bg-primary-700"
          @click="addParticipant(user as KnownChatUser)"
        >
          Invite
        </button>
      </div>
    </div>
  </Dialog>
</template>
