import { getVersion } from '@tauri-apps/api/app';
import { invoke } from '@tauri-apps/api/core';
import { platform } from '@tauri-apps/plugin-os';
import { defineStore } from 'pinia';

function getInitialState() {
  return {
    version: '',
    platform: '',
    instanceType: 'external',
    primaryMachine: true,
    newerVersionAvailable: false,
    legacyInstallationsExists: false,
    currentMachine: null as ComputerSpecs | null,

    hasScreenRecordingPermission: false,
    hasAccessibilityPermission: false,
    isBooting: true,
  };
}
export const useRuntimeConfigurationStore = defineStore('runtime_configuration', {
  state: () => getInitialState(),
  actions: {
    async boot() {
      this.platform = platform();
      this.version = await getVersion();
      try {
        const permissions = await this.getPermissions();
        this.hasAccessibilityPermission = permissions.accessibility;
        this.hasScreenRecordingPermission = permissions.screen_recording;
      } catch (e) {
        this.hasAccessibilityPermission = false;
        this.hasScreenRecordingPermission = false;
      }
      this.isBooting = false;
    },
    async getPermissions() {
      return invoke<AppPermissions>('check_permissions');
    },
    checkScreenRecordingPermission() {
      invoke<boolean>('check_screen_recording_permission')
        .then((response: boolean) => {
          if (!response) {
            invoke<boolean>('open_screen_recording_preferences').catch(() => {});
          }
          this.hasScreenRecordingPermission = response;
        })
        .catch(() => {
          this.hasScreenRecordingPermission = false;
        });
    },

    checkAccessibilityPermission() {
      invoke<boolean>('check_accessibility_permissions')
        .then((response: boolean) => {
          if (!response) {
            invoke<boolean>('open_accessibility_preferences').catch(() => {});
          }
          this.hasAccessibilityPermission = response;
        })
        .catch(() => {
          this.hasAccessibilityPermission = false;
        });
    },
  },
  getters: {
    isPrimaryMachine: (state) => {
      return state.primaryMachine;
    },
    hasAllPermissions: (state) => {
      return state.hasAccessibilityPermission && state.hasScreenRecordingPermission;
    },
    isDevelopment: (_state) => {
      return import.meta.env.MODE !== 'production';
    },
    isReadyToRun: (state) => {
      return state.hasAccessibilityPermission && state.hasScreenRecordingPermission && state.currentMachine !== null && state.version !== '' && state.platform !== '';
    },
  },
});
