// src/modules/services.ts
import { MtiModule } from '@modules/index';
import { App } from 'vue';
import { SentinelKey, MarkdownServiceKey } from '@utils/vue/symbols';
import { Sentinel } from '@src/sentinel';
import { MarkdownService } from '@src/services/markdown-service';
export default <MtiModule>{
  onVueAppInit(app: App) {
    app.provide(SentinelKey, new Sentinel());
    app.provide(MarkdownServiceKey, new MarkdownService());
  },
};
