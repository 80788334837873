import emojiRegexFn from 'emoji-regex';
import hljs from 'highlight.js';
import linkifyHtml from 'linkify-html';
import { init, mdToHtml } from 'md4w';

// import all the highlight js languages
import { EmojiManager } from '@utils/chat/emoji-manager';
import { Logger } from '@utils/logger';
import 'highlight.js/lib/languages/csharp';
import 'highlight.js/lib/languages/go';
import 'highlight.js/lib/languages/java';
import 'highlight.js/lib/languages/javascript';
import 'highlight.js/lib/languages/kotlin';
import 'highlight.js/lib/languages/php';
import 'highlight.js/lib/languages/python';
import 'highlight.js/lib/languages/ruby';
import 'highlight.js/lib/languages/rust';
import 'highlight.js/lib/languages/scala';
import 'highlight.js/lib/languages/swift';
import 'highlight.js/lib/languages/typescript';
import wasmUrl from '../../node_modules/md4w/js/md4w-fast.wasm?url';

const log = new Logger('MARKDOWN_SERVICE');
const emojiRegex = emojiRegexFn();

await init(wasmUrl);

export class MarkdownService {
  /**
   * Extracts the proper language and adds syntax highlighting
   *
   * @param html
   */
  highlightCodeBlocks = (html: string) => {
    return html.replace(/<pre><code(?: class="language-(\w+)")?>([\s\S]*?)<\/code><\/pre>/g, (_match, lang, code) => {
      const language = hljs.getLanguage(lang) ? lang : 'javascript';
      const highlightedCode = hljs.highlight(code, { language }).value;
      return `<pre><code class="small-scroll language-${language} hljs">${highlightedCode}</code></pre>`;
    });
  };

  /**
   * Converts markdown text to html.
   *
   * @param markdownText
   */
  processMarkdownToHtml(markdownText: string) {
    // const html = markdown.parse(markdownText);
    const html = mdToHtml(markdownText, {
      parseFlags: ['PERMISSIVE_ATX_HEADERS', 'PERMISSIVE_URL_AUTO_LINKS', 'STRIKETHROUGH', 'TABLES', 'UNDERLINE', 'HARD_SOFT_BREAKS'],
    });

    return this.highlightCodeBlocks(html);
  }

  /**
   * Returns true if the text content is a single emoji - to make that emoji size larger by default (like discord)
   *
   * @param textContent
   */
  isSingleEmoji(textContent: string | undefined) {
    if (!textContent) {
      return false;
    }

    let reconstructedString = '';
    const matches = textContent.matchAll(emojiRegex);
    for (const match of matches) {
      reconstructedString += match[0];
    }
    return reconstructedString.toLowerCase() === textContent.split(' ').join('').trim().toLowerCase();
  }

  enhanceHtml(html: string) {
    html = linkifyHtml(html);
    return this.replaceEmojisInHtmlString(html);
  }

  replaceEmojisInHtmlString(html: string) {
    const emojiManager = EmojiManager.getInstance();

    // Parse the string into an HTML DOM structure
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');
    const isEmojiOnly = doc.body.textContent ? this.isSingleEmoji(doc.body.textContent) : false;

    // Function to replace emoji in a text node
    const replaceEmojiInText = (text: string) => {
      return text.replace(emojiRegex, (match) => {
        const normalizedIcon = emojiManager.normalizeEmojiInput(match);
        const emoji = emojiManager.getEmojiMap().get(normalizedIcon);

        if (!emoji) {
          log.warn('Emoji not found in map:', match);
          return match; // Return original emoji if not found in map
        }

        const searchTerm = ':' + emoji.name.split(':')[0].split(' ').join('_').toLowerCase() + ':';
        //Case Sensitive
        const src = `emoji/64/${normalizedIcon.toLowerCase()}.png`;

        const cssClass = isEmojiOnly ? 'large-emoji' : 'emoji';

        // Return an img tag as a string to replace the emoji
        return `<img src="${src}" class="${cssClass}" data-name="${searchTerm}" draggable="false" alt="${match}"/>`;
      });
    };

    // Traverse the DOM and find all text nodes
    const traverseAndReplace = (node: Node) => {
      node.childNodes.forEach((child) => {
        if (child.nodeType === Node.TEXT_NODE) {
          // Replace emoji in the text node
          const newText = replaceEmojiInText(child.nodeValue as string);
          const newFragment = document.createRange().createContextualFragment(newText);
          child.replaceWith(newFragment);
        } else if (child.nodeType === Node.ELEMENT_NODE) {
          // Recursively process child nodes
          traverseAndReplace(child);
        }
      });
    };

    traverseAndReplace(doc.body);

    // Serialize the DOM back to a string
    return doc.body.innerHTML;
  }

  /**
   * Returns rendered html from markdown text, with URL support & cross-platform emoji support.
   * Cache is based on combination of the reqId and updated_at timestamp. Cache will auto-clear after 1 hour.
   *
   * @param markdownText
   */
  render(markdownText: string) {
    if (!markdownText) {
      return '';
    }

    const html = this.processMarkdownToHtml(markdownText);
    return this.enhanceHtml(html);
  }
}
