import { DateTime } from 'luxon';
import { Logger } from '@utils/logger';
const log = new Logger('TIMESTAMP-ALERT-MANAGER');

export default class TimestampAlertManager {
  private processedTimestamps: string[] = [];
  private serverTimestamps: string[] = [];

  constructor(timestamps: string[] = []) {
    this.loadProcessedFromLocalStorage();
    this.setServerTimestamps(timestamps);
    this.removeExpiredAndProcessedTimestamps();
  }

  setServerTimestamps(timestamps: string[]): void {
    this.serverTimestamps = timestamps ? timestamps : [];
  }

  getServerTimeStamps(): string[] {
    return this.serverTimestamps;
  }

  expireLocalProcessedTimestamps(): void {
    const twentyFourHoursAgo = DateTime.utc().minus({ hours: 24 });

    this.processedTimestamps = this.processedTimestamps.filter((timestamp) => {
      const dateTime = DateTime.fromISO(timestamp, { zone: 'utc' });
      return dateTime >= twentyFourHoursAgo;
    });
  }

  loadProcessedFromLocalStorage(): void {
    const data = localStorage.getItem('processedTimestamps');
    this.processedTimestamps = data ? JSON.parse(data) : [];
  }

  removeExpiredAndProcessedTimestamps(): void {
    const now = DateTime.utc();
    const timestamps = this.getServerTimeStamps().filter((timestamp) => {
      const notifyAt = DateTime.fromISO(timestamp, { zone: 'utc' });
      const nowPlusTen = now.plus({ minutes: 10 });
      const isProcessed = this.processedTimestamps.includes(timestamp);
      const isExpired = notifyAt >= nowPlusTen;

      return !isProcessed && !isExpired;
    });
    this.setServerTimestamps(timestamps);
  }

  saveProcessedTimestamps(): void {
    this.expireLocalProcessedTimestamps();
    localStorage.setItem('processedTimestamps', JSON.stringify(this.processedTimestamps));
  }

  isTimeToAlert(): string {
    log.info('Checking if it is time to check user activity.');
    const now = DateTime.utc();
    const alertWindow = now.plus({ minutes: 10 });
    let alertTimestamp: string = '';

    const timeStamps = this.getServerTimeStamps();
    timeStamps.forEach((timestamp) => {
      const targetTime = DateTime.fromISO(timestamp, { zone: 'utc' });

      if (now >= targetTime && targetTime <= alertWindow) {
        log.info('Alerting user to check activity.');
        alertTimestamp = timestamp;
        this.processedTimestamps.push(timestamp);
        return;
      }

      // Treat as processed if it's too old
      if (targetTime >= alertWindow) {
        log.info('Timestamp is too old to alert. Stashing.');
        this.processedTimestamps.push(timestamp);
      }
    });

    this.saveProcessedTimestamps();
    return alertTimestamp;
  }
}
