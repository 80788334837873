const MAX_WIDTH = 350;
const MAX_HEIGHT = 500;

export const getImageSizeFromBlob = (
  blob: Blob
): Promise<{
  width: number;
  height: number;
  originalWidth: number;
  originalHeight: number;
}> => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      const aspectRatio = img.width / img.height;
      let scaledWidth: number;
      let scaledHeight: number;

      if (img.width > img.height) {
        scaledWidth = Math.round(Math.min(MAX_WIDTH, img.width));
        scaledHeight = Math.round(scaledWidth / aspectRatio);
      } else {
        scaledHeight = Math.round(Math.min(MAX_HEIGHT, img.height));
        scaledWidth = Math.round(scaledHeight * aspectRatio);
      }

      resolve({
        width: scaledWidth,
        height: scaledHeight,
        originalWidth: img.width,
        originalHeight: img.height,
      });
    };
    img.onerror = reject;
    img.src = URL.createObjectURL(blob);
  });
};

interface Dimensions {
  width: number;
  height: number;
  originalWidth: number;
  originalHeight: number;
}

export const getVideoSizeFromBlob = (blob: Blob): Promise<Dimensions> => {
  return new Promise((resolve, reject) => {
    if (!blob || blob.size === 0) {
      return reject(new Error('Invalid or empty Blob.'));
    }

    const video = document.createElement('video');
    video.src = URL.createObjectURL(blob);
    video.onloadedmetadata = () => {
      const aspectRatio = video.videoWidth / video.videoHeight;

      let scaledWidth: number;
      let scaledHeight: number;

      if (video.videoWidth > video.videoHeight) {
        scaledWidth = Math.min(MAX_WIDTH, video.videoWidth);
        scaledHeight = Math.round(scaledWidth / aspectRatio);
      } else {
        scaledHeight = Math.min(MAX_HEIGHT, video.videoHeight);
        scaledWidth = Math.round(scaledHeight * aspectRatio);
      }

      resolve({
        width: scaledWidth,
        height: scaledHeight,
        originalWidth: video.videoWidth,
        originalHeight: video.videoHeight,
      });
    };

    video.onerror = (event) => {
      console.log(event);
      console.error('Video failed to load:', event);
      reject(new Error('Failed to load video metadata.'));
    };
  });
};

export const getDataPreview = async (file: File): Promise<string> => {
  return new Promise<string>((resolve, reject) => {
    if (file.type.startsWith('image/')) {
      const reader = new FileReader();
      reader.onload = (e) => {
        resolve(e.target?.result as string);
      };
      reader.onerror = (e) => {
        reject(new Error('Error reading file'));
      };
      reader.readAsDataURL(file);
    } else if (file.type.startsWith('video/')) {
      getVideoImagePreview(file).then((preview) => {
        resolve(preview);
      });
    } else {
      resolve('');
    }
  });
};

const getVideoImagePreview = async (file: File): Promise<string> => {
  const videoHtmlElement = document.createElement('video');
  const canvasHTMLElement = document.createElement('canvas');

  videoHtmlElement.src = URL.createObjectURL(file);
  videoHtmlElement.load();

  return new Promise((resolve) => {
    const handleLoadedData = () => {
      if (!videoHtmlElement) {
        resolve('');
        return;
      }
      videoHtmlElement.currentTime = 1; // Jump to 1 second to avoid initial black frames
    };

    const handleSeeked = () => {
      if (!canvasHTMLElement || !videoHtmlElement) {
        resolve('');
        return;
      }
      const frame = captureFrame(canvasHTMLElement, videoHtmlElement);
      resolve(frame);
    };

    videoHtmlElement.addEventListener('loadeddata', handleLoadedData, {
      once: true,
    });
    videoHtmlElement.addEventListener('seeked', handleSeeked, { once: true });
  });
};

const captureFrame = (canvas: HTMLCanvasElement, video: HTMLVideoElement): string => {
  canvas.width = video.videoWidth;
  canvas.height = video.videoHeight;
  const ctx = canvas.getContext('2d');
  if (!ctx) {
    return '';
  }
  ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
  return canvas.toDataURL('image/png');
};
