/* eslint-disable prettier/prettier */
import { useAlertStore } from "@src/store/alert";
import { useAppStore } from "@src/store/app";
import { useTimerStore } from "@src/store/timer";
import { ActiveJob, Client, TeamLead } from "@src/types/MyTimeInTypes";
import { invoke } from "@tauri-apps/api/core";
import { withCatch } from '@utils/await-safe';
import { Logger } from "@utils/logger";
import { DateTime } from 'luxon';
import { defineStore } from "pinia";
import TimestampAlertManager from "@src/services/timestamp-alert-manager";

const log = new Logger("STORE:ACTIVEJOB");

export const useActiveJobStore = defineStore("activejob", {
  state: () => ({
  activeJob: {} as ActiveJob,
  additionalDetails: {
      onBreak: false,
      beRightBack: false,
      inMeeting: false,
      clockingIn: false,
      client: {} as Client,
      refreshing: false,
      availablePto: 0,
      seniorTeamLead: {} as TeamLead,
      juniorTeamLead: {} as TeamLead,
      screenshotsTaken: 0,
    },
    alertManager: new TimestampAlertManager(),
  }),
  getters: {
    hasActiveJob: (state) => {
      return state.activeJob !== null;
    },
    saved: (state) => {
      return state.activeJob?.saved === true;
    },
    shouldTrackWebsites: (state) => {
      return (
        state.activeJob &&
        state.activeJob.trackerEnabled &&
        state.activeJob.trackWebsites
      );
    },
    shouldTakeScreenshots: (state) => {
      return (
        state.activeJob &&
        state.activeJob.trackerEnabled &&
        state.activeJob.trackScreenshots
      );
    },
    shouldTrackProcesses: (state) => {
      return (
        state.activeJob &&
        state.activeJob.trackerEnabled &&
        state.activeJob.trackProcesses
      );
    },
    shouldTrackApplications: (state) => {
      return (
        state.activeJob &&
        state.activeJob.trackerEnabled &&
        state.activeJob.trackProcesses
      );
    },
    isClockedIn: (state) => {
      return ["active", "late active"].includes(
        state.activeJob?.decoratedStatus?.toLowerCase() ?? "",
      );
    },
    canClockIn: (state) => {
      if (!state.activeJob || !state.activeJob.decoratedStatus || !state.activeJob.timeFromUnix) {
        return false;
      }

      const isClockedInToday = ["yet to start", "late"].includes(state.activeJob.decoratedStatus.toLowerCase());
      const now = DateTime.now();
      const shiftStartTime = DateTime.fromSeconds(state.activeJob.timeFromUnix);
      const minutesUntilShiftStart = shiftStartTime.diff(now, "minutes").minutes;
      const isWithin30MinutesBeforeOrAfterShiftStart = minutesUntilShiftStart <= 30 || now >= shiftStartTime;

      return isClockedInToday && isWithin30MinutesBeforeOrAfterShiftStart;
    },

    isClockedOut: (state) => {
      return [
        "break",
        "off the clock",
        "pto",
        "pto (mod)",
        "upto (mod)",
        "absent: no activity",
        "undertime",
        "late",
        "rest day",
        "system logged out",
      ].includes(state.activeJob?.decoratedStatus?.toLowerCase() ?? "");
    },
    canUseBrb: (state) => {
      return (
        ["active", "late active"].includes(
          state.activeJob?.status?.toLowerCase() ?? "",
        ) && !state.additionalDetails.beRightBack
      );
    },
    canReturnFromBrb: (state) => {
      return state.additionalDetails.beRightBack;
    },
    canGoOnBreak: (state) => {
      return (["active", "late active"].includes(state.activeJob?.status?.toLowerCase() ?? "",) && !state.additionalDetails.onBreak
      );
    },
    canReturnFromBreak: (state) => {
      return state.additionalDetails.onBreak;
    },
    isOffTheClock: (state) => {
      return ["off the clock"].includes(
        state.activeJob?.decoratedStatus?.toLowerCase() ?? "",
      );
    },
    isLate: (state) => {
      return ["late", "late active"].includes(
        state.activeJob?.status?.toLowerCase() ?? "",
      );
    },
    timeOnBreak: (state) => {
      // foreach this.activeJob.breaks, return duration between time_from, time_to
      if (!state.activeJob) return "0";
      const total_seconds_on_break = state.activeJob.breaks.reduce(
        (acc, cur) => {
          if (cur.time_to && cur.time_from) {
            acc +=
              new Date(cur.time_to).getTime() -
              new Date(cur.time_from).getTime();
          }
          return acc;
        },
        0,
      );

      return (total_seconds_on_break / 1000 / 60).toFixed(2);
    },
  },
  actions: {
    async refresh() {
      const appStateStore = useAppStore();
      const timerStore = useTimerStore();
      const [getActiveJobError, data] = await withCatch(appStateStore.api.getActiveJob());

      if(getActiveJobError || !data){
        const alertStore = useAlertStore();
        alertStore.addAlerts(["Failed to contact MyTimeIn"]);
        return;
      }

      this.activeJob = data.activeJob;
      this.alertManager.setServerTimestamps(data.activeJob.activityCheckTimes ? data.activeJob.activityCheckTimes : []);

      if(this.activeJob.status === "Break"){
          this.additionalDetails.onBreak = true;
          console.log(this.activeJob.breaks[this.activeJob.breaks.length - 1].time_from)
          timerStore.breakStartedAt = this.activeJob.breaks.length ? DateTime.fromSQL(`${this.activeJob.breaks[this.activeJob.breaks.length - 1].time_from}`, {zone: 'utc'}) : DateTime.now()
      }
      else{
          this.additionalDetails.onBreak = false;
      }

      this.additionalDetails.client = data.client;
      this.additionalDetails.availablePto = data.flexiDuration;
      this.additionalDetails.seniorTeamLead = data.seniorTeamLead;
      this.additionalDetails.juniorTeamLead = data.juniorTeamLead;
    },

    async startBreak() {
      const appStateStore = useAppStore();
      const timerStore = useTimerStore();

      if (!this.activeJob) {
        log.error("No active job to start break");
        return;
      }

        const [startBreakError, activeJob] = await withCatch(appStateStore.api.startBreak());

        if(startBreakError || !activeJob){
            log.error("Failed to start break");
            log.error(startBreakError);
            return;
        }

        log.info("Break started");

        this.activeJob = activeJob;
        this.additionalDetails.onBreak = true;
        timerStore.playedBreakReminder = false;
        timerStore.overBreakSnoozeReturnTime = null;

    },
    async endBreak() {
      const appStateStore = useAppStore();
      const timerStore = useTimerStore();
      this.additionalDetails.onBreak = false;
      const [endBreakError, activeJob] = await withCatch(appStateStore.api.endBreak());

      if(endBreakError || !activeJob){
          log.error("Failed to end break");
          log.error(endBreakError);
          return;
      }

      log.info("Break ended");

      this.activeJob = activeJob;
      timerStore.overBreakSnoozeReturnTime = null;
      timerStore.playedBreakReminder = false;
      timerStore.breakStartedAt = null;
    },

    async startShift(payload: ShiftEvent) {
      payload.jobId = this.activeJob?.jobId;
      const appStateStore = useAppStore();
      const [startShiftError, activeJob] = await withCatch(appStateStore.api.startShift(payload));

      if(startShiftError || !activeJob){
          log.error("Failed to start shift");
          log.error(startShiftError);
          return;
      }

      log.info("Shift started");

      this.activeJob = activeJob;
      await invoke("clear_data");
    },
    async endShift(payload: ShiftEvent) {
      payload.jobId = this.activeJob?.jobId;
      const appStateStore = useAppStore();
      const timerStore = useTimerStore();
      const [endShiftError, activeJob] = await withCatch(appStateStore.api.endShift(payload));

      if(endShiftError || !activeJob){
        log.error("Failed to end shift");
        log.error(endShiftError);
        return;
      }

      log.info("Shift ended");
      this.activeJob = activeJob;
      timerStore.$reset();
      await invoke("clear_data");

    },
    async changeBrbState(flag: boolean) {
      this.additionalDetails.beRightBack = flag;
    },
  },
});
