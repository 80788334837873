<script setup lang="ts">
  import { computed, onBeforeMount, onMounted, onRenderTracked, onRenderTriggered, onUnmounted, ref } from 'vue';
  import injectStrict from '@utils/vue/utils';
  import { MarkdownServiceKey } from '@utils/vue/symbols';
  import { useChatStore } from '@src/store/chat';
  import MessageOptions from '@components/Chat/MessageOptions.vue';
  import { Message } from '@pubnub/chat';
  import ChatReaction from '@components/Chat/ChatReaction.vue';
  import ChatMediaContainer from '@components/Chat/ChatMediaContainer.vue';
  import ChatParentMessage from '@components/Chat/ChatParentMessage.vue';
  import { useAppStore } from '@src/store/app';
  import { EmojiManager } from '@utils/chat/emoji-manager';
  import { Logger } from '@utils/logger';
  import { KnownChatUser, MyTimeInChatMessage } from '@src/types/ChatAndMessaging';
  import { calculateTooltipPosition } from '@utils/chat/tooltip-calculator';
  import useHelpersSpace from '@use/helpers';
  import { relativeTimeOfMessage } from '@utils/time';
  const { getInitials } = useHelpersSpace();
  const log = new Logger('ChatMessage');

  const emojiManager = EmojiManager.getInstance();

  const props = defineProps<{
    message: MyTimeInChatMessage;
    reactions: boolean;
    senderAvatar: string | undefined | null;
  }>();

  const getParentUser = (): KnownChatUser | undefined => {
    if (!parentMessage?.userId) {
      return;
    }
    return chatStore.knownUsersByUuid.get(parentMessage.userId);
  };

  const getParentMessage = (): { message: MyTimeInChatMessage; avatar: string | null, userId: string | null } | null => {
    const foundMsg = chatStore.currentChannelMessages.find((msg) => {
      if (!props.message.meta) {
        return false;
      }
      return msg.timetoken === props.message.meta.parentId;
    });

    if (!foundMsg) {
      return null;
    }

    return {
      message: foundMsg as MyTimeInChatMessage,
      avatar: chatStore.knownUsersByUuid.get(foundMsg.userId)?.profileUrl ?? null,
      userId: foundMsg.userId,
    };
  };

  const appStore = useAppStore();
  const chatStore = useChatStore();

  // TODO: Improve non-computed elements that dont change, should not cause re-render
  const isOwnMessage = props.message.userId === chatStore.chatUserId;
  const currentMessageUsersName = chatStore.knownUsersByUuid.get(props.message.userId)?.name;
  const currentMessageDate = relativeTimeOfMessage(`${props.message.timetoken}`);
  const currentMessageUsersInitials = getInitials(chatStore.knownUsersByUuid.get(props.message.userId)?.name);
  const artificialNodesCreated: HTMLElement[] = [];
  const relativeCreatedTime = relativeTimeOfMessage(`${props.message.timetoken}`);
  const parentMessage = getParentMessage();
  const parentUser = getParentUser();

  const tooltipRef = ref();
  const showTooltip = ref(false);
  const avatarTooltip = ref();
  const showAvatarTooltip = ref(false);
  const content = ref<HTMLParagraphElement | null>(null);
  const markdownService = injectStrict(MarkdownServiceKey);
  const me = ref<HTMLDivElement | null>(null);
  const observer = ref<ResizeObserver | null>(null);
  const hasCodeBlocks = ref(false);

  const emit = defineEmits(['will-delete', 'scroll-to-message', 'send-message']);

  function unicodeToEmoji(unicode: string) {
    const codePoints = unicode.split('-').map((u) => parseInt(u, 16));
    return String.fromCodePoint(...codePoints);
  }

  const applyReaction = async (emojiUnicode: string) => {
    let emoji = emojiManager.getEmojiMap().get(emojiUnicode);
    if (!emoji) {
      log.error('Emoji not found');
      return;
    }

    const skinTonePreference = appStore.settings.emojiSkinTone;
    if (emoji.skin_variations && skinTonePreference && emoji.skin_variations[skinTonePreference]) {
      emoji.unified = emoji.skin_variations[skinTonePreference].unified;
    }

    appStore.updateEmojiStats(emoji.unified);
    await chatStore.applyReactionToMessage(`${props.message.timetoken}`, unicodeToEmoji(emoji.unified));
  };

  //@ts-ignore
  const mute = async (timeout: number) => {
    await chatStore.muteUserWhoPostedMessage(props.message, timeout);
  };

  /**
   * Creates the code support node for the code block (language information, copy button support)
   *
   * @param codeBlock - The code block element
   */
  //@ts-ignore
  const createCodeSupportNode = (codeBlock: HTMLElement) => {
    try {
      const preBlock = codeBlock.parentElement;
      if (!preBlock) {
        console.warn('No parent element found for the code block.');
        return;
      }
      preBlock.classList.add('relative', 'block', 'group');

      const wrapperNode = document.createElement('div');
      wrapperNode.classList.add('right-8', 'top-4', 'absolute');

      const copyButton = document.createElement('button');
      copyButton.classList.add('hidden', 'group-hover:block');
      copyButton.innerHTML =
        '<svg fill="currentColor" class="size-4" viewBox="0 0 24 24"><path d="M7.5 3.375c0-1.036.84-1.875 1.875-1.875h.375a3.75 3.75 0 0 1 3.75 3.75v1.875C13.5 8.161 14.34 9 15.375 9h1.875A3.75 3.75 0 0 1 21 12.75v3.375C21 17.16 20.16 18 19.125 18h-9.75A1.875 1.875 0 0 1 7.5 16.125V3.375Z"/><path d="M15 5.25a5.23 5.23 0 0 0-1.279-3.434 9.768 9.768 0 0 1 6.963 6.963A5.23 5.23 0 0 0 17.25 7.5h-1.875A.375.375 0 0 1 15 7.125V5.25ZM4.875 6H6v10.125A3.375 3.375 0 0 0 9.375 19.5H16.5v1.125c0 1.035-.84 1.875-1.875 1.875h-9.75A1.875 1.875 0 0 1 3 20.625V7.875C3 6.839 3.84 6 4.875 6Z"/></svg>';
      copyButton.addEventListener('click', window.tauriCopyCodeHandler);
      wrapperNode.appendChild(copyButton);
      preBlock.appendChild(wrapperNode);
      artificialNodesCreated.push(copyButton);
      artificialNodesCreated.push(wrapperNode);
    } catch (error) {
      console.error('Error creating code support node:', error);
    }
  };

  onMounted(() => {
    // bind to any mentions in the message
    // const mentions = content?.value?.querySelectorAll('.mention');
    // mentions?.forEach((mention) => {});

    // Add code block supporting elements
    // TODO this doesn't work with inline code blocks ore code blocks that are only one line the copy button is off screen
    // const codeBlocks = content?.value?.querySelectorAll('code');
    // codeBlocks?.forEach((codeBlock) => {
    //   createCodeSupportNode(codeBlock as HTMLElement);
    // });
    // prevent default link navigation behavior
    const availableLinks = content?.value?.querySelectorAll('a');
    availableLinks?.forEach((link) => {
      link.addEventListener('click', window.tauriLinkHandler);
    });
  });

  const sendMessageToReplyUserFromToolTip = ({ message }: { message: string }) => {
    if (!parentUser) {
      return;
    }

    emit('send-message', { user: parentUser, message });
    closeTooltip();
  };

  const handleScrollToMessage = ({ message }: { message: Message }) => {
    emit('scroll-to-message', { message });
  };

  const closeTooltip = () => {
    chatStore.composerFocusStealOverride = false;
    if (tooltipRef.value) {
      // tooltipRef.value.tippy.hide();
      showAvatarTooltip.value = false;
    }
    if (avatarTooltip.value) {
      // avatarTooltip.value.hide();
      showTooltip.value = false;
    }
  };

  onBeforeMount(() => {
    hasCodeBlocks.value = props.message.text.includes('```');
  });

  onUnmounted(() => {
    observer.value?.disconnect();
    /**
     * Cleanup our nodes, click handlers, event listeners, etc.
     */
    const availableLinks = content?.value?.querySelectorAll('a');
    availableLinks?.forEach((link) => {
      link.removeEventListener('click', window.tauriLinkHandler);
    });
    artificialNodesCreated.forEach((node) => {
      node.removeEventListener('click', window.tauriCopyCodeHandler);
      node.remove();
    });
  });

  const showMessageContextMenu = (event: MouseEvent, user?: KnownChatUser, placement: 'mouse' | 'left' | 'top' | 'right' = 'mouse') => {
    //Since these share the same tooltip, we need to close the message context menu if it's open
    chatStore.closeAllOpenMenus();
    /**
     * requestAnimationFrame is used to avoid a race condition when opening a new tooltip while another is still open.
     * Without this, the old tooltip might close but the new one may not display. This ensures the old tooltip is fully
     * closed before opening the new one.
     */
    requestAnimationFrame(() => {
      if (!user) {
        return;
      }

      chatStore.messageContextMenuUser = user;
      chatStore.messageContextMenuMessage = props.message;
      chatStore.composerFocusStealOverride = true;
      chatStore.showMessageContextMenu = true;

      /**
       * We request an additional animation frame to ensure the tooltip is in the DOM,
       * allowing us to accurately retrieve its dimensions.
       */

      requestAnimationFrame(() => {
        let success: boolean;
        if (placement === 'mouse') {
          success = calculateTooltipPosition(event, 'chat-layer', 'message-context-menu', placement);
        } else {
          success = calculateTooltipPosition(event.target as HTMLElement, 'chat-layer', 'message-context-menu', placement);
        }

        // If positioning the tooltip fails for any reason, close it and reset the values.
        if (!success) {
          chatStore.showMessageContextMenu = false;
          chatStore.messageContextMenuMessage = null;
          chatStore.messageContextMenuUser = null;
          chatStore.composerFocusStealOverride = false;
        } else {
          document.getElementById('message-context-menu')?.focus();
        }
      });
    });
  };

  const showUserTooltip = (event: MouseEvent, user?: KnownChatUser) => {
    //Since these share the same tooltip, we need to close the message context menu if it's open
    chatStore.closeAllOpenMenus();
    /**
     * requestAnimationFrame is used to avoid a race condition when opening a new tooltip while another is still open.
     * Without this, the old tooltip might close but the new one may not display. This ensures the old tooltip is fully
     * closed before opening the new one.
     */

    requestAnimationFrame(() => {
      if (!user) {
        return;
      }

      chatStore.participantCardUser = user;
      chatStore.composerFocusStealOverride = true;
      chatStore.participantCardOpen = true;

      /**
       * We request an additional animation frame to ensure the tooltip is in the DOM,
       * allowing us to accurately retrieve its dimensions.
       */

      requestAnimationFrame(() => {
        let success = calculateTooltipPosition(event.target as HTMLElement, 'chat-layer', 'participant-card', 'right', 10);

        // If positioning the tooltip fails for any reason, close it and reset the values.
        if (!success) {
          chatStore.participantCardUser = null;
          chatStore.composerFocusStealOverride = false;
          chatStore.participantCardOpen = false;
        }
      });
    });
  };

  onRenderTriggered((e) => {
    console.log('ChatMessage.vue render tracked: ' + props.message.timetoken, e);
  });
</script>

<template>
  <div ref="me" class="px-4 group/chat-message relative group" @contextmenu.prevent="showMessageContextMenu($event, chatStore.knownUsersByUuid.get(message.userId))">
    <ChatParentMessage v-if="parentMessage && parentUser" :parent-message="parentMessage" :parent-user="parentUser" @scroll-to-message="handleScrollToMessage" @send-message="sendMessageToReplyUserFromToolTip" />
    <div class="flex relative">
      <MessageOptions v-show="message.meta?.customType !== 'admin'" class="absolute invisible -top-5 -right-0.5 group-hover:visible" :message="message" :timetoken="message.timetoken" :reactions="reactions" :sender-id="message.userId" :is-own-message="isOwnMessage" @simple-react="applyReaction" @show-message-context-menu="showMessageContextMenu($event, chatStore.knownUsersByUuid.get(message.userId))" />
      <div>
        <button class="mr-2 shrink-0 w-12" @click="showUserTooltip($event, chatStore.knownUsersByUuid.get(message.userId))">
          <div v-if="!senderAvatar" class="size-10 rounded-full object-cover pointer-events-none flex items-center bg-primary-100 dark:bg-primary-700 dark:text-surface-100 justify-center text-surface-900 text-sm tracking-tighter font-semibold">
            {{ currentMessageUsersInitials }}
          </div>
          <img v-else :src="senderAvatar" alt="profile" class="rounded-full size-10 object-cover pointer-events-none" draggable="false" />
        </button>
      </div>

      <div class="overflow-hidden w-full">
        <!-- sender information -->
        <div class="flex flex-wrap lg:flex-nowrap gap-x-2 items-baseline">
          <div class="">
            <button class="font-bold text-sm text-surface-900 dark:text-surface-100 max-w-48 truncate hover:underline" @click="showUserTooltip($event, chatStore.knownUsersByUuid.get(message.userId))">
              {{ currentMessageUsersName }}
            </button>
          </div>
          <!-- show time Today at HH:MM A or M/DD/YYYY, HH:MM A -->
          <p class="text-[11px] text-surface-500 dark:text-surface-400 select-text shrink-0 font-semibold">
            {{ currentMessageDate }}
          </p>
        </div>
        <!-- body -->
        <div class="message">
          <p v-if="message.meta?.customType === 'admin'">
            <span class="text-xs text-surface-500 dark:text-surface-400">{{ relativeCreatedTime }}</span>
            <span class="text-xs text-surface-500 dark:text-surface-400">{{ message.text }}</span>
          </p>
          <!-- eslint-disable-next-line vue/no-v-html-->
          <div v-if="message.text" v-once ref="content" class="select-text w-full pr-[4.275rem] mb-2" v-html="markdownService.render(message.text)" />
          <ChatMediaContainer v-if="!message.deleted && message.meta?.mytimeinFiles?.length" :files="message.meta.mytimeinFiles" :meta="message.meta" />
          <ChatReaction v-if="!message.deleted" :message="message" :current-user-id="chatStore.currentUserId as string" @react="applyReaction" />
        </div>
      </div>
    </div>
  </div>
</template>
