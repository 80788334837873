import { Message } from '@pubnub/chat';
import { MyTimeInChatMessage } from '@src/types/ChatAndMessaging';

export const wrapPubnubMessageToFriendlyObservable = (message: Message): MyTimeInChatMessage => {
  let text = message.content.text;
  // fish out edits
  const messageEdits = message.actions?.['edited'];
  if (messageEdits) {
    const flatEdits = Object.entries(messageEdits)
      .map(([k, v]) => ({ value: k, ...v[0] }))
      .filter((v) => v && v.actionTimetoken);
    if (flatEdits.length === 0) {
      const lastEdit = flatEdits.reduce((a, b) => (a.actionTimetoken > b.actionTimetoken ? a : b));
      text = lastEdit.value;
    }
  }

  return {
    timetoken: `${message.timetoken}`,
    meta: message.meta,
    channelId: message.channelId,
    userId: message.userId,
    mentions: message.mentionedUsers,
    content: message.content,
    deleted: message.deleted,
    reactions: message.reactions,
    mentionedUsers: message.mentionedUsers,
    text: text,
    files: message.content.files,
    actions: message.actions,
  };
};
