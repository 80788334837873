<script setup lang="ts">
  const props = defineProps<{
    show: boolean;
  }>();

  const beforeEnter = (element: Element) => {
    const HTMLElement = element as HTMLElement;
    HTMLElement.style.height = '0';
    HTMLElement.style.opacity = '0';
  };
  const enter = (element: Element, done: () => void) => {
    const HTMLElement = element as HTMLElement;
    HTMLElement.offsetHeight; // force reflow
    HTMLElement.style.transition = 'height 0.1s ease-out, opacity 0.1s ease-out';
    HTMLElement.style.height = HTMLElement.scrollHeight + 'px';
    HTMLElement.style.opacity = '1';
    HTMLElement.addEventListener('transitionend', function handler() {
      HTMLElement.style.height = 'auto';
      HTMLElement.removeEventListener('transitionend', handler);
      done();
    });
  };
  const leave = (element: Element, done: () => void) => {
    const HTMLElement = element as HTMLElement;
    HTMLElement.style.height = HTMLElement.scrollHeight + 'px';
    HTMLElement.offsetHeight; // force reflow
    HTMLElement.style.transition = 'height 0.1s ease-in, opacity 0.1s ease-in';
    requestAnimationFrame(() => {
      HTMLElement.style.height = '0';
      HTMLElement.style.opacity = '0';
    });
    HTMLElement.addEventListener('transitionend', function handler() {
      HTMLElement.removeEventListener('transitionend', handler);
      done();
    });
  };
</script>

<template>
  <transition-group class="animate-slide-y" tag="div" @before-enter="beforeEnter" @enter="enter" @leave="leave">
    <div v-if="props.show" class="item">
      <slot name="default"></slot>
    </div>
  </transition-group>
</template>

<style scoped>
  .animate-slide-y .item {
    overflow: hidden;
    position: relative;
    transition: transform 0.1s ease-out; /* Ensure smooth transform transition */
  }
  .animate-slide-y .item-enter-active,
  .animate-slide-y .item-leave-active {
    transition: transform 0.1s ease-out;
  }

  .animate-slide-y .item-enter,
  .animate-slide-y .item-leave-to {
    transform: translateY(0%);
  }

  .animate-slide-y .item-enter-from {
    transform: translateY(-100%);
  }

  .animate-slide-y .item-leave {
    transform: translateY(0%);
  }

  .animate-slide-y .item-leave-to {
    transform: translateY(100%);
  }
</style>
