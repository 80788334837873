import { DateTime } from 'luxon';

export const createDateFromSeconds = (seconds: number, options = {}, format: LuxonDateString = 'DATE_FULL'): string => {
  if (!seconds) {
    return 'Invalid Date';
  }
  return DateTime.fromSeconds(seconds, options).toLocaleString(DateTime[format]);
};

export const formatDateToTimezone = (date: string, time: string, timezone: string, format: LuxonDateString): string => {
  return DateTime.fromISO(`${date}T${time}`, { zone: 'UTC' }).setZone(timezone).toLocaleString(DateTime[format]);
};

export const formatDateToTimezoneFromAnyZone = (date: string, time: string, options: { zone: string }, timezone: string, format: string = 'y-MM-dd'): string => {
  return DateTime.fromISO(`${date}T${time}`, options).setZone(timezone).toFormat(format);
};

export const createDateInFormat = (date: string, options = {}, format = 'y-MM-dd') => {
  if (!date) {
    return 'Invalid Date';
  }
  return DateTime.fromSQL(date, options).setZone('Asia/Manila').toFormat(format);
};

export const shortTz = (timezone: string): string => {
  return DateTime.now().setZone(timezone).toFormat('ZZZZ');
};

export const zeroPad = (num: number, places = 1): string => String(num).padStart(places, '0');

export const convertSecondsToHrMinSec = (seconds: number): string => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  return `${zeroPad(hours)}:${zeroPad(minutes)}:${zeroPad(seconds)}`;
};

export const createDateInLocale = (date: string | null | undefined, options = {}, format: LuxonDateString = 'DATE_MED') => {
  if (!date) {
    return '';
  }
  return DateTime.fromSQL(date, options).toLocaleString(DateTime[format]);
};

// Helper functions to find specific holidays
const findLastMonday = (year: number, month: number): DateTime => {
  let date = DateTime.local(year, month).endOf('month');
  while (date.weekday !== 1) {
    date = date.minus({ days: 1 });
  }
  return date;
};

const findNthWeekday = (year: number, month: number, nth: number, weekday: number): DateTime => {
  let date = DateTime.local(year, month, 1);
  let count = 0;
  while (count < nth) {
    if (date.weekday === weekday) {
      count++;
    }
    if (count < nth) {
      date = date.plus({ days: 1 });
    }
  }
  return date;
};

export const createDateFromUTCToFormat = (date: string, options = { zone: 'UTC' }, format = 'DDD'): string => {
  if (!date) {
    return 'Invalid Date';
  }
  return DateTime.fromSQL(date, options).setZone('local').toFormat(format);
};

export const createDateFromUTCToLocale = (date: string, options = { zone: 'UTC' }, format: LuxonDateString = 'TIME_SIMPLE'): string => {
  if (!date) {
    return 'Invalid Date';
  }
  return DateTime.fromSQL(date, options).setZone('local').toLocaleString(DateTime[format]);
};

export const getUsHolidays = () => {
  // Define the holidays
  const holidays: Holiday[] = [
    { date_string: 'January 1', name: "New Year's Day" },
    { date_string: 'Last Monday in May', name: 'Memorial Day' },
    { date_string: 'July 4', name: 'Independence Day' },
    { date_string: 'First Monday in September', name: 'Labor Day' },
    { date_string: 'Fourth Thursday in November', name: 'Thanksgiving Day' },
    { date_string: 'December 25', name: 'Christmas Day' },
  ];

  // Get current year
  const currentYear: number = DateTime.now().year;

  // Parse the holidays and get their dates for the current year
  const parsedHolidays = holidays.map((holiday) => {
    let date: DateTime | null;
    const [monthStr, dayStr] = holiday.date_string.split(' ');
    const month = DateTime.fromFormat(monthStr, 'MMMM').month;
    const day = parseInt(dayStr);

    switch (holiday.date_string) {
      case 'Last Monday in May':
        date = findLastMonday(currentYear, 5);
        if (DateTime.now() > date) {
          date = findLastMonday(currentYear + 1, 5);
        }
        break;
      case 'First Monday in September':
        date = findNthWeekday(currentYear, 9, 1, 1);
        if (DateTime.now() > date) {
          date = findNthWeekday(currentYear + 1, 9, 1, 1);
        }
        break;
      case 'Fourth Thursday in November':
        date = findNthWeekday(currentYear, 11, 4, 4);
        if (DateTime.now() > date) {
          date = findNthWeekday(currentYear + 1, 9, 4, 4);
        }
        break;
      default:
        date = DateTime.local(currentYear, month, day);
        if (DateTime.now() > date) {
          date = DateTime.local(currentYear + 1, month, day);
        }

        break;
    }
    return { ...holiday, date: date?.toISODate() };
  });
  parsedHolidays.sort((a, b) => {
    const dateA = DateTime.fromISO(a.date!);
    const dateB = DateTime.fromISO(b.date!);
    if (dateA < dateB) return -1;
    if (dateA > dateB) return 1;
    return 0;
  });

  return parsedHolidays;
};

export const relativeTimeOfMessage = (timetoken: string): string => {
  const now = DateTime.now();
  const createdAt = DateTime.fromMillis(parseInt(timetoken) / 10000);
  //see if created at and now are the same day
  if (createdAt.hasSame(now, 'day')) {
    return 'Today at ' + createdAt.toLocaleString(DateTime.TIME_SIMPLE);
  }
  return createdAt.toLocaleString(DateTime.DATETIME_SHORT);
};
