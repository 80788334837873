<script setup lang="ts">
  import { useChatStore } from '@src/store/chat';

  const chatStore = useChatStore();
</script>

<template>
  <div class="bg-white dark:bg-surface-800 px-4 text-xs h-[20px] text-surface-500 dark:text-surface-400 font-medium">
    <p v-if="chatStore.currentlyTypingNames.length === 1" class="truncate line-clamp-1">{{ chatStore.currentlyTypingNames[0] }} is typing...</p>
    <p v-else-if="chatStore.currentlyTypingNames.length === 2" class="truncate line-clamp-1">{{ chatStore.currentlyTypingNames.join(' and ') }} are typing...</p>
    <p v-else-if="chatStore.currentlyTypingNames.length === 3" class="truncate line-clamp-1">{{ chatStore.currentlyTypingNames.slice(0, 2).join(', ') }}, and {{ chatStore.currentlyTypingNames[2] }} are typing...</p>
    <p v-else-if="chatStore.currentlyTypingNames.length > 3" class="truncate line-clamp-1">Several users are typing...</p>
  </div>
</template>
