<script setup lang="ts">
  import { computed, onBeforeMount, ref, toRefs } from 'vue';
  import ChatMediaFile from '@components/Chat/ChatMediaFile.vue';
  import ChatFile from '@components/Chat/ChatFile.vue';
  // import Dialog from 'primevue/dialog';
  import { determineContainerSize } from '@utils/chat/chat-media-size';

  import VueEasyLightbox from 'vue-easy-lightbox/external-css';
  import 'vue-easy-lightbox/external-css/vue-easy-lightbox.css';
  import { useAppStore } from '@src/store/app';

  const appStore = useAppStore();
  const showVideoDialog = ref(false);
  const videoSource = ref('');
  const lightBoxImages: string[] = [];
  const viewIndex = ref(0);
  const singleImageHeight = ref(0);
  const singleImageWidth = ref(0);
  const rowHeight = 161;
  const showLightBox = ref(false);
  const idsToFileLinks = ref<Map<string, string>>(new Map());
  const mediaFiles = ref<{ id: string; name: string; url: string; type: string }[]>([]);
  const imageFiles = ref<{ id: string; name: string; url: string; type: string }[]>([]);
  const otherFiles = ref<{ id: string; name: string; url: string; type: string }[]>([]);
  const props = withDefaults(
    defineProps<{
      files: {
        id: string;
        name: string;
        url: string;
        type: string;
        key: string;
      }[];
      meta: Record<string, any> | undefined;
    }>(),
    {
      meta: () => {
        return { dimensions: [{ height: 0, width: 0 }] };
      },
    }
  );
  const { files, meta } = toRefs(props);

  const gridConfig = computed(() => {
    const length = files.value.length;
    if (length > 10) {
      return { layout: 'triple' };
    } else if (length === 10) {
      return { layout: 'multi', grids: [1, 3] };
    } else if (length === 9 || length === 6) {
      return { layout: 'triple' };
    } else if (length === 8 || length === 5) {
      return { layout: 'multi', grids: [2, 3] };
    } else if (length === 7) {
      return { layout: 'multi', grids: [1, 1, 3] };
    } else if (length === 4) {
      return { layout: 'dual' };
    } else if (length === 3) {
      return { layout: 'multi', grids: [2, 1] };
    } else if (length === 2) {
      return { layout: 'dual' };
    } else if (length === 1) {
      return { layout: 'single' };
    }
    return {};
  });

  const filteredFilesFor3x3 = computed(() => {
    if (mediaFiles.value.length === 7 || mediaFiles.value.length === 10) {
      return mediaFiles.value.slice(1);
    } else if (mediaFiles.value.length === 5) {
      return mediaFiles.value.slice(2);
    } else if (mediaFiles.value.length === 8 || mediaFiles.value.length === 6) {
      return mediaFiles.value.slice(2);
    }
    return [];
  });

  const splitFilesByType = () => {
    // const videoFiles = files.value.filter((file) => file.type?.startsWith('video'));
    const imageFiles = files.value.filter((file) => file.type?.startsWith('image'));
    const otherFiles = files.value.filter((file) => !file.type?.startsWith('image'));
    // return { videos: videoFiles, images: imageFiles, files: otherFiles };
    return { images: imageFiles, files: otherFiles };
  };

  const evaluateSize = () => {
    const sizeInformation = determineContainerSize(meta.value.dimensions);
    if (sizeInformation && sizeInformation.width > 0 && sizeInformation.height > 0) {
      singleImageHeight.value = sizeInformation.height;
      singleImageWidth.value = sizeInformation.width;
      return;
    } else {
      singleImageHeight.value = 400;
      singleImageWidth.value = 600;
    }
  };

  const onHide = () => {
    showLightBox.value = false;
  };

  const toggleLightBox = (id: string) => {
    viewIndex.value = imageFiles.value.findIndex((file) => file.id === id);
    showLightBox.value = true;
  };

  const toggleVideoDialog = ({ src }: { src: string }) => {
    showVideoDialog.value = true;
    videoSource.value = src;
  };

  const closeVideoDialog = () => {
    showVideoDialog.value = false;
    videoSource.value = '';
  };

  onBeforeMount(async () => {
    const { images, files } = splitFilesByType();
    imageFiles.value = images;
    mediaFiles.value = [...images];
    otherFiles.value = files;
    // get all files as an array of ids
    const filesToDownload = images.filter((file) => file.id?.length > 0).map((file) => file.id);

    if (import.meta.env.MODE === 'development') {
      if (filesToDownload.length === 0) {
        evaluateSize();
        return;
      }
      const { data } = await appStore.api.getFileUrls(filesToDownload);

      data.forEach(({ id, link }) => {
        idsToFileLinks.value.set(id, link);
      });

      // // Set videos to some stock photo on dev
      // const stockVideoUrl = 'https://media.istockphoto.com/id/1413207061/video/road-traffic-in-delhi-roads.mp4?s=mp4-640x640-is&k=20&c=KnGos4ZVgHxZSV-zGAJk0mWsjR2kLGumoVcKI-PanEw=';
      // videos.forEach((file) => {
      //   file.type = 'video/mp4';
      //   file.url = stockVideoUrl;
      //   idsToFileLinks.value.set(file.id, stockVideoUrl);
      // });
    } else {
      // Production: use the new CDN links by looking at the file "key" and appending the CDN to it
      props.files.forEach(({ id, key }) => {
        idsToFileLinks.value.set(id, `https://myoutdeskdl.mytimein.com/${key}`);
      });
    }

    for (let image of images) {
      if (!idsToFileLinks.value.get(image.id)) {
        continue;
      }
      lightBoxImages.push(idsToFileLinks.value.get(image.id) as string);
    }
    evaluateSize();
  });

  const getFileSrc = (file: { id: string; name: string; url: string; type: string; meta?: Record<string, any> }) => {
    if (import.meta.env.MODE === 'development' && file.type.includes('video')) {
      return 'https://media.istockphoto.com/id/1413207061/video/road-traffic-in-delhi-roads.mp4?s=mp4-640x640-is&k=20&c=KnGos4ZVgHxZSV-zGAJk0mWsjR2kLGumoVcKI-PanEw=';
    }
    if (idsToFileLinks.value.get(file.id)) {
      return idsToFileLinks.value.get(file.id);
    }

    evaluateSize();

    const width = Math.round(singleImageWidth.value) > 1 ? Math.round(singleImageWidth.value) : 600;
    const height = Math.round(singleImageHeight.value) > 1 ? Math.round(singleImageHeight.value) : 400;

    return `https://placehold.co/${width}x${height}`;
  };

  // const getFileSrc = (file) => {};
</script>

<template>
  <div class="max-w-[550px] select-none overflow-hidden space-y-4">
    <Teleport v-if="showLightBox" to="body">
      <VueEasyLightbox v-if="showLightBox" :visible="showLightBox" :imgs="lightBoxImages" :index="viewIndex" scroll-disabled move-disabled :zoom-scale="1" :min-zoom="0" :max-zoom="5" @hide="onHide" />
    </Teleport>

    <!--    <Dialog v-if="showVideoDialog" :visible="showVideoDialog" modal :draggable="false">-->
    <!--      <template #container>-->
    <!--        <div v-click-away="closeVideoDialog" class="relative w-full h-full">-->
    <!--          <video controls class="w-full h-full aspect-video" autoplay>-->
    <!--            <source :src="videoSource" />-->
    <!--          </video>-->
    <!--        </div>-->
    <!--      </template>-->
    <!--    </Dialog>-->
    <div v-if="mediaFiles.length">
      <!--Single Grid -->
      <div v-if="gridConfig.layout === 'single'">
        <ChatMediaFile
          :file="files[0]"
          :preview="false"
          :src="getFileSrc(files[0])"
          contain
          :image-styles="`height: ${singleImageHeight}px; width: ${singleImageWidth}px;`"
          :video-styles="`height: ${singleImageHeight}px; width: ${singleImageWidth}px;`"
          class="border-red-500"
          @video-click="toggleVideoDialog"
          @image-click="toggleLightBox(files[0].id)"
        />
      </div>

      <!--Dual Grid-->
      <div v-else-if="gridConfig.layout === 'dual'" class="grid grid-cols-2 gap-1">
        <div v-for="(file, index) in mediaFiles" :key="index" class="relative w-full">
          <ChatMediaFile :file="file" :src="getFileSrc(file)" :image-styles="`height: ${rowHeight}px`" :video-styles="`height: ${rowHeight}px`" class="w-full border-sky-500" @video-click="toggleVideoDialog" @image-click="toggleLightBox(file.id)" />
        </div>
      </div>

      <!--Triple Grid-->
      <div v-else-if="gridConfig.layout === 'triple'" class="grid grid-cols-3 gap-1">
        <div v-for="(file, index) in mediaFiles" :key="index" class="relative">
          <ChatMediaFile :file="file" :src="getFileSrc(file)" :image-styles="`height: ${rowHeight}px`" :video-styles="`height: ${rowHeight}px`" @video-click="toggleVideoDialog" @image-click="toggleLightBox(file.id)" />
        </div>
      </div>

      <!--Complex Grid Combos -->
      <div v-else-if="gridConfig.layout === 'multi'" class="grid gap-1">
        <!-- First row with 1 items, each spanning 3 columns -->
        <div v-if="gridConfig?.grids?.includes(1) && gridConfig?.grids?.includes(3)" class="grid grid-cols-1 gap-1">
          <ChatMediaFile
            :file="mediaFiles.slice(0, 1)[0]"
            :preview="false"
            :src="getFileSrc(mediaFiles.slice(0, 1)[0])"
            :image-styles="`height: ${singleImageHeight}px`"
            :video-styles="`height: ${singleImageHeight}px`"
            class="border-amber-500 col-span-1"
            @video-click="toggleVideoDialog"
            @image-click="toggleLightBox(mediaFiles.slice(0, 1)[0].id)"
          />
        </div>

        <!--First row with 2 items 1st spanning 2 columns second spanning 1 column-->
        <div v-if="gridConfig?.grids?.includes(1) && gridConfig?.grids?.includes(2)" class="grid grid-cols-3 gap-1">
          <ChatMediaFile
            :file="mediaFiles.slice(0, 1)[0]"
            :src="getFileSrc(mediaFiles.slice(0, 1)[0])"
            :image-styles="`height: ${singleImageHeight}px`"
            :video-styles="`height: ${singleImageHeight}px`"
            class="col-span-2 relative border-pink-500"
            @video-click="toggleVideoDialog"
            @image-click="toggleLightBox(mediaFiles.slice(0, 1)[0].id)"
          />

          <div class="grid grid-cols-1 cols-span-1 gap-1">
            <ChatMediaFile
              v-for="(file, index) in mediaFiles.slice(1, 3)"
              :key="index"
              class="col-span-1 border-teal-500"
              :file="file"
              :src="getFileSrc(file)"
              :image-styles="`height: ${singleImageHeight / 2}px`"
              :video-styles="`height: ${singleImageHeight / 2}px`"
              @video-click="toggleVideoDialog"
              @image-click="toggleLightBox(file.id)"
            />
          </div>
        </div>

        <!-- First row with 2 items -->
        <div v-else-if="gridConfig?.grids?.includes(2)" class="grid grid-cols-2 gap-1">
          <ChatMediaFile
            v-for="(file, index) in mediaFiles.slice(0, 2)"
            :key="index"
            class="col-span-1 relative border-indigo-500"
            :file="file"
            :src="getFileSrc(file)"
            :image-styles="`height: ${singleImageHeight}px`"
            :video-styles="`height: ${singleImageHeight}px`"
            @video-click="toggleVideoDialog"
            @image-click="toggleLightBox(file.id)"
          />
        </div>
        <!-- Remaining images in a 3x3 grid -->
        <div v-if="gridConfig?.grids?.includes(3)" class="grid grid-cols-3 gap-1">
          <ChatMediaFile
            v-for="(file, index) in filteredFilesFor3x3"
            :key="`trio-${index}`"
            class="col-span-1 relative border-yellow-500"
            :file="file"
            :src="getFileSrc(file)"
            :image-styles="`height: ${rowHeight}px`"
            :video-styles="`height: ${rowHeight}px`"
            @video-click="toggleVideoDialog"
            @image-click="toggleLightBox(file.id)"
          />
        </div>
      </div>
    </div>

    <div v-if="otherFiles.length" class="space-y-2 max-w-[432px]">
      <ChatFile v-for="(file, index) in otherFiles" :key="`file-index-${index}`" :src="idsToFileLinks.get(file.id)" :file="file" :size="meta.size" />
    </div>
  </div>
</template>

<style>
  .vel-toolbar {
    @apply hidden;
  }
</style>
