<script setup lang="ts">
  import { ArrowUturnLeftIcon, FaceSmileIcon, PencilSquareIcon, EllipsisVerticalIcon, BugAntIcon } from '@heroicons/vue/16/solid';
  import MessageOptionButton from '@components/Chat/MessageOptionButton.vue';
  import { computed, onBeforeMount, ref } from 'vue';
  import { useChatStore } from '@src/store/chat';
  import { useAppStore } from '@src/store/app';
  import { useToast } from 'primevue/usetoast';
  import { EmojiManager } from '@utils/chat/emoji-manager';
  import { calculateTooltipPosition } from '@utils/chat/tooltip-calculator';
  import { MyTimeInChatMessage } from '@src/types/ChatAndMessaging';

  const emojiManager = EmojiManager.getInstance();

  const props = defineProps<{
    message: MyTimeInChatMessage;
    senderId: string;
    reactions: boolean;
    isOwnMessage: boolean;
    timetoken: string;
  }>();

  const toast = useToast();
  const emits = defineEmits(['react-message', 'reply-message', 'edit-message', 'simple-react', 'show-message-context-menu']);
  const chatStore = useChatStore();
  const appStore = useAppStore();
  const isModeratorMessage = ref(false);

  const top3MostUsedEmojis = computed(() => {
    return Object.entries(appStore.emojiUsageStats)
      .sort(([, a], [, b]) => b.used - a.used) // Sort by the `used` property in descending order
      .slice(0, 3) // Take the top 3
      .map(([key]) => key); // Extract only the keys
  });

  const toggleEmojiPanel = (event: MouseEvent) => {
    // Close all open menus
    chatStore.closeAllOpenMenus();

    chatStore.composerFocusStealOverride = true;
    chatStore.emojiPickerOpen = true;
    chatStore.messageContextTimeToken = props.timetoken;

    requestAnimationFrame(() => {
      let success = calculateTooltipPosition(event.target as HTMLElement, 'chat-layer', 'floating-chat-emoji-picker', 'left');
      if (!success) {
        chatStore.composerFocusStealOverride = false;
        chatStore.emojiPickerOpen = false;
        chatStore.messageContextTimeToken = props.timetoken;
      }
    });
  };

  const showMessageContextMenu = (event: MouseEvent) => {
    emits('show-message-context-menu', event);
  };

  const simpleReact = (emoji: string) => {
    emits('simple-react', emoji);
  };

  const editMessage = () => {
    chatStore.startEditingMessage(props.timetoken);
  };

  const replyMessage = () => {
    chatStore.replyToMessage(props.timetoken);
  };

  const copyMessageToClipBoard = () => {
    let data = {
      text: props.message.text,
      actions: props.message.actions,
      meta: props.message.meta,
      userId: props.message.userId,
    };
    navigator.clipboard.writeText(JSON.stringify(data, null, 2));
    toast.add({
      severity: 'success',
      summary: 'Success',
      detail: 'Copied message to clipboard!',
      life: 3000,
    });
  };

  onBeforeMount(() => {
    if (props.senderId === 'PUBNUB_INTERNAL_MODERATOR') {
      isModeratorMessage.value = true;
    }
  });
</script>

<template>
  <div class="gap-2 hover:shadow-md dark:hover:shadow-surface-900 rounded-md border bg-white shadow-sm dark:shadow-none dark:bg-surface-800 dark:border-surface-950" @contextmenu.stop.prevent>
    <div class="flex items-center">
      <div class="h-8 flex items-center">
        <MessageOptionButton v-for="(emojiString, index) in top3MostUsedEmojis" :key="`${emojiString}-${index}`" @click="simpleReact(emojiString)">
          <template #icon>
            <div class="12">
              <div class="emojiSprite-20" :style="emojiManager.getBackgroundPosition(emojiString, 20)"></div>
            </div>
          </template>
        </MessageOptionButton>
      </div>
      <div v-if="top3MostUsedEmojis.length" class="border-x border-surface-200 dark:border-surface-600">&nbsp;</div>
      <div class="flex items-center h-8 justify-center">
        <MessageOptionButton v-if="appStore.isDevUser" tooltip="Copy Message to Clipboard" @click="copyMessageToClipBoard">
          <template #icon>
            <BugAntIcon class="size-5" />
          </template>
        </MessageOptionButton>
        <MessageOptionButton tooltip="React" @click="toggleEmojiPanel">
          <template #icon>
            <FaceSmileIcon class="size-5 chat-emoji-opener" />
          </template>
        </MessageOptionButton>
        <template v-if="!isModeratorMessage">
          <MessageOptionButton v-if="!isOwnMessage" tooltip="Reply" @click="replyMessage">
            <template #icon>
              <ArrowUturnLeftIcon class="size-5" />
            </template>
          </MessageOptionButton>

          <MessageOptionButton v-if="isOwnMessage" tooltip="Edit" @click="editMessage">
            <template #icon>
              <PencilSquareIcon class="size-5" />
            </template>
          </MessageOptionButton>
          <MessageOptionButton tooltip="More" @click="showMessageContextMenu">
            <template #icon>
              <EllipsisVerticalIcon class="size-5" />
            </template>
          </MessageOptionButton>
        </template>
      </div>
    </div>
  </div>
</template>
