import * as Sentry from '@sentry/vue';
import { App } from 'vue';
import { MtiModule } from '@modules/index';

export default <MtiModule>{
  onVueAppInit(app: App) {
    if (import.meta.env.MODE === 'development') {
      return;
    }
    Sentry.init({
      app,
      dsn: 'https://57d244b9166a44329684641399688a8b@o29515.ingest.sentry.io/4505354254548992',
      ignoreErrors: ['Failed to fetch dynamically imported module', 'Importing a module script failed', 'Aborting all operations', 'PubNubError'],
      integrations: [
        Sentry.feedbackIntegration({
          colorScheme: 'system',
          isNameRequired: true,
          isEmailRequired: true,
        }),
      ],
      tracesSampleRate: 0.2,
      tracePropagationTargets: ['localhost'],
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
    });
  },
};
