import { Extension } from '@tiptap/core';

export interface CustomEnterStorage {
  disableEnter: boolean;
  mentionPanelOpened: boolean;
  emojisInlinePanelOpened: boolean;
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    customEnter: {
      disableEnter: () => ReturnType;
      enableEnter: () => ReturnType;
      openEmojisInlinePanel: () => ReturnType;
      closeEmojisInlinePanel: () => ReturnType;
      openMentionsPanel: () => ReturnType;
      closeMentionsPanel: () => ReturnType;
    };
  }
}

// This is a callback passed in when creating the extension on the editor
export interface CustomEnterOptions {
  onEnter: () => void;
}

export default Extension.create<CustomEnterOptions, CustomEnterStorage>({
  name: 'customEnter',
  addStorage() {
    return {
      disableEnter: false,
      mentionPanelOpened: false,
      emojisInlinePanelOpened: false,
    };
  },
  addKeyboardShortcuts() {
    const isFormatActive = (formats: string[]): boolean => formats.some((format) => this.editor.isActive(format));

    const listFormats = ['orderedList', 'bulletList'];
    const codeBlockFormats = ['codeBlock', 'codeBlockLine', 'blockquote'];

    const handleMetaControlEnter = () => {
      const isCodeBlockActive = isFormatActive(codeBlockFormats);
      if (isCodeBlockActive) {
        this.options.onEnter();
        return true;
      }

      const isListActive = isFormatActive(listFormats);
      const isListItemActive = this.editor.isActive('listItem');

      if (isListActive && isListItemActive) {
        this.options.onEnter();
        return true;
      }
    };

    const enterHandler = () => {
      if (this.storage.disableEnter) {
        return true;
      }
      if (this.storage.mentionPanelOpened || this.storage.emojisInlinePanelOpened) {
        return false;
      }

      const isHeadingActive = this.editor.isActive('heading');
      if (isHeadingActive) {
        return this.editor.commands.first(({ commands }) => [() => commands.createParagraphNear(), () => commands.liftEmptyBlock(), () => commands.splitBlock()]);
      }

      const isCodeBlockActive = isFormatActive(codeBlockFormats);
      if (isCodeBlockActive) {
        return this.editor.commands.first(({ commands }) => [() => commands.newlineInCode(), () => commands.createParagraphNear(), () => commands.liftEmptyBlock(), () => commands.splitBlock()]);
      }

      const isListActive = isFormatActive(listFormats);
      const isListItemActive = this.editor.isActive('listItem');

      if (isListActive && isListItemActive) {
        return this.editor.commands.first(({ commands }) => [() => commands.splitListItem('listItem'), () => commands.liftEmptyBlock(), () => commands.splitBlock()]);
      }

      this.options.onEnter();
      return true;
    };

    const shiftEnterHandler = () => {
      if (this.storage.disableEnter) {
        return true;
      }

      const isListActive = isFormatActive(listFormats);
      const isListItemActive = this.editor.isActive('listItem');

      if (isListActive && isListItemActive) {
        return this.editor.commands.first(({ commands }) => [() => commands.splitListItem('listItem'), () => commands.liftEmptyBlock(), () => commands.splitBlock()]);
      }

      const isCodeBlockActive = isFormatActive(codeBlockFormats);
      if (isCodeBlockActive) {
        return this.editor.commands.first(({ commands }) => [() => commands.createParagraphNear(), () => commands.liftEmptyBlock(), () => commands.splitBlock()]);
      }

      return this.editor.commands.first(({ commands }) => [
        // 1. If the cursor is inside a code block, insert a new line (\n)
        () => commands.newlineInCode(),

        // 2. Insert a hard line break (<br>) at the cursor's position, useful for breaking lines without starting a new paragraph
        () => commands.setHardBreak(),

        // 3. Create a new paragraph near the current selection.
        // Useful if the cursor is in an area that typically doesn't allow adding paragraphs (like an empty block)
        () => commands.createParagraphNear(),

        // 4. If the current block is empty, lift it out of its enclosing structure.
        // For example, exit a blockquote or a list item when pressing Shift + Enter on an empty block
        () => commands.liftEmptyBlock(),

        // 5. As a fallback, split the current block at the cursor's position.
        // Creates a new block (e.g., a new paragraph or a new list item)
        () => commands.splitBlock(),
      ]);
    };

    return {
      Enter: () => enterHandler(),
      'Meta-Enter': () => handleMetaControlEnter(),
      'Ctrl-Enter': () => handleMetaControlEnter(), //Windows does not have a Meta key, so we use Ctrl instead
      'Shift-Enter': () => shiftEnterHandler(),
    };
  },
  addCommands() {
    return {
      disableEnter: () => () => {
        this.storage.disableEnter = true;
        console.log('set storage value', this.storage.disableEnter);
        return true;
      },
      enableEnter: () => () => {
        this.storage.disableEnter = false;
        console.log('set storage value', this.storage.disableEnter);
        return true;
      },
      openEmojisInlinePanel: () => () => {
        this.storage.emojisInlinePanelOpened = true;
        return true;
      },
      closeEmojisInlinePanel: () => () => {
        this.storage.emojisInlinePanelOpened = false;
        return true;
      },
      openMentionsPanel: () => () => {
        this.storage.mentionPanelOpened = true;
        console.log('set storage value', this.storage.mentionPanelOpened);
        return true;
      },
      closeMentionsPanel: () => () => {
        this.storage.mentionPanelOpened = false;
        console.log('set storage value', this.storage.mentionPanelOpened);
        return true;
      },
    };
  },
});
