import { MtiModule } from '@modules/index';
import { App } from 'vue';
import { makeRouter } from '@src/router';

export default <MtiModule>{
  onVueAppInit(app: App) {
    const router = makeRouter();
    app.use(router);
  },
};
