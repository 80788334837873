import { Channel, Chat, User as ChatUser, EmitEventParams, Membership, Message, MessageMentionedUsers, TimetokenUtils } from '@pubnub/chat';
import AudioService from '@src/services/audio-service';
import ChatService from '@src/services/chat-service';
import { useAppStore } from '@src/store/app';
import { ChatAuthDetails, ChatComposerState, ChatReactionCollection, KnownChatChannel, KnownChatUser, MyTimeInChatMessage, PendingMyTimeInChatMessage } from '@src/types/ChatAndMessaging';
import { invoke } from '@tauri-apps/api/core';
import { sendNotification } from '@tauri-apps/plugin-notification';
import { withCatch } from '@utils/await-safe';
import { Logger } from '@utils/logger';
import { addToArrayInMap, removeFromArrayInMap, removeFromArrayInMapWithPredicate } from '@utils/map-utils';
import { restoreSerializedMap, serializeMap } from '@utils/serializers';
import { DateTime } from 'luxon';
import { defineStore } from 'pinia';
import Pubnub, { LinearRetryPolicy } from 'pubnub';
import { wrapPubnubMessageToFriendlyObservable } from '@utils/chat/message-wrapper';
import { markRaw } from 'vue';

const log = new Logger('STORE:CHAT');

const STATE_REPLYING = 'replying';
const STATE_EDITING = 'editing';
const MESSAGES_TO_LOAD_MAX = 100;
const GLOBAL_IGNORE_CHANNELS = ['modcon_2024'];

const EVENT_USER_ONLINE = 1;
const EVENT_USER_OFFLINE = 2;
const EVENT_USER_IDLE = 3;
const EVENT_MESSAGE_DELETED = 101;
const EVENT_USER_UPDATED = 102;
const MYTIMEIN_BROADCAST_CHANNEL = 'mytimein-broadcast';

// @ts-ignore
const STATE_COMPOSING = 'composing';
// @ts-ignore
const EVENT_USER_DND = 4;

export const useChatStore = defineStore('chat', {
  state: () => ({
    initialLoad: false,
    chatUserId: '',

    channelUrl: '',

    unreadMentions: new Map<string, number>(),
    channelAlerts: new Map<string, number>(),
    channelComposerStates: new Map<string, ChatComposerState>(),

    // SPECIAL FUNCTIONS TO STREAM UPDATES FROM PUBNUB
    currentMessageStreamDisconnectFunction: null as (() => void) | null,
    eventDisconnectFns: [] as (() => void)[],
    currentChannelListDisconnectFunction: null as (() => void) | null,
    currentChannelStopTypingUpdatesDisconnectFunction: null as (() => void) | null,
    // @todo: this actually only returns userIds, not online activity, so don't use it yet.
    currentChannelParticipantListStreamDisconnectFunction: null as (() => void) | null,

    // STREAM FOR PENDING MESSAGE UPDATES THAT ARE *NOT* ON THE CURRENT MESSAGE CHANNEL
    pendingMessageUpdateStreams: new Map<string, (() => void)[]>(),
    pinnedMessagesForChannels: new Map<string, MyTimeInChatMessage[]>(),

    chat: null as Chat | null,

    knownChannels: new Map<string, Channel>(),
    loadingChannels: new Set<string>(),
    finishedLoadingChannels: new Set<string>(),

    channelMessages: new Map<string, MyTimeInChatMessage[]>(),
    channelAttachments: new Map<string, FileUploadDetails[]>(),
    channelParticipantsMinimal: new Map<string, Set<string>>(),
    channelPinnedMessages: new Map<string, MyTimeInChatMessage>(),
    channelDisconnectFns: new Map<string, () => void>(),

    mentionsOpen: false,
    composerFocusStealOverride: false,
    largeEditorMode: false,

    knownUsersByUuid: new Map<string, KnownChatUser>(),
    participantsOnlineInChannel: new Map<string, string[]>(),
    onlineUserIds: new Set(),
    idleUserIds: new Set(),

    // optimized for the current chat view as a way to keep the UI hot and responsive
    currentChannelMutations: 0,
    currentlyTyping: new Map<string, NodeJS.Timeout>(),
    optimizingUploads: false,

    currentRecoveryAttempts: 0,

    // this is strictly to enable mod controls at a later date
    operators: new Map<string, string[]>(),

    // Authentication
    authentication: {
      token: '',
      ttl: 0,
      publish_key: '',
      subscribe_key: '',
    } as ChatAuthDetails,
    tokenExpirationTime: null as null | Date,
    tokenRefreshInterval: null as NodeJS.Timeout | null,
    busyUpdatingToken: false,

    customEventListenerFn: null as any,
    customStatusListenerFn: () => {},
    inviteListenerFn: null as any,

    lastErrors: [] as string[],

    emojiPickerOpen: false,

    participantCardOpen: false,
    participantCardUser: null as KnownChatUser | null,

    showMessageContextMenu: false,
    messageContextMenuUser: null as KnownChatUser | null,
    messageContextMenuMessage: null as MyTimeInChatMessage | null,
    contextMenuImage: null as HTMLImageElement | null,

    showUserStatusTooltip: false,
    userStatusTooltipMessage: null as string | null,

    showMessageOptionTooltip: false,
    messageOptionTooltipMessage: null as string | null,

    showEmojiReactionCard: false,
    reactionEmoji: null as string | null,
    emojiReactionTooltipMessage: null as string | null,

    messageContextTimeToken: null as string | null,

    pendingMessagesMap: new Map<string, PendingMyTimeInChatMessage[]>(),
    knownReactionsToMessages: new Map<string, ChatReactionCollection>(),

    PUBLISH_KEY: '',
    SUBSCRIBE_KEY: '',

    // first draw?
    firstDraw: false,
    fullNetwork: [] as KnownChatUser[],

    globalChannelOrder: [] as string[],
    unreadMessagesPerChannel: new Map<string, number>(),
    lastReadTimestampPerChannel: new Map<string, number>(),

    currentChannelUpdaterInterval: null as null | NodeJS.Timeout,
    currentChannelLastCheckedAt: DateTime.now(),
    mutedChannels: new Set<string>(),

    rawMessages: new Map<string, Message>(),
  }),
  actions: {
    async initSdkIfRequired(userId: number) {
      log.info('Initializing chat sdk');
      this.initialLoad = true;

      if (!userId) {
        this.initialLoad = false;
        throw new Error('No user id provided.');
      }

      loadUserFromStorage(this);
      loadChannelOrderFromStorage(this);

      const appStore = useAppStore();
      const initializedChatToken: boolean = await initializeChatToken(this, appStore);

      if (!initializedChatToken) {
        log.error('Failed to initialize chat token. Returning early');
        this.initialLoad = false;
        return;
      }

      // userId = sanitizeUserId(userId); TODO don't think we need this anymore if this is truly a number
      const initializedChat: boolean = await initializeChat(this, `${userId}`);

      if (!initializedChat) {
        log.error('Failed to initialize chat. Returning early');
        this.initialLoad = false;
        return;
      }

      initializeListeners(this);

      const [memberShipError, success] = await withCatch(handleMemberships(this));

      if (memberShipError || !success) {
        log.error('Failed to get memberships');
        log.error(memberShipError);
      }

      postInitialization(this);
      this.initialLoad = false;
    },
    broadcastOnlineNotice() {
      if (!this.chat) {
        throw new Error('Chat not initialized');
      }

      return true;
    },
    broadcastUpdateNotice() {
      if (!this.chat) {
        throw new Error('Chat not initialized');
      }

      return true;
    },
    broadcastIdleNotice() {
      if (!this.chat) {
        log.error('chat not initialized');
        return false;
      }

      return true;
    },
    async broadcastOfflineNotice() {
      if (!this.chat) {
        throw new Error('Chat not initialized');
      }

      const customEvent: EmitEventParams = {
        method: 'signal',
        channel: MYTIMEIN_BROADCAST_CHANNEL,
        type: 'custom',
        payload: {
          status: EVENT_USER_OFFLINE,
          userId: this.chat.currentUser?.id,
        },
      };

      const [failedToEmitEvent] = await withCatch(this.chat.emitEvent(customEvent));
      if (failedToEmitEvent) {
        log.error('Failed to broadcast offline notice');
        log.error(failedToEmitEvent);
        log.error(failedToEmitEvent.status);
      }
    },
    listenForInviteEvents() {
      log.debug('listening to all chat events');

      if (!this.chat) {
        throw new Error('Chat not initialized');
      }

      const inviteCallback = async (event: any) => {
        log.info(event);
        //Don't listen to events from yourself
        if (event.payload.meta.createdBy === this.chatUserId) {
          log.debug('Ignoring event from self');
          return;
        }

        if (this.knownChannels.has(event.payload.channelId)) {
          log.info('Channel already known, ignoring invite');
          return;
        }

        if (!this.chat) {
          log.error('Chat not initialized, reloading.');
          window.location.href = `${window.location.pathname}?timestamp=${new Date().getTime()}`;
          return;
        }

        const [memberShipError, data] = await withCatch(this.chat.currentUser.getMemberships());

        if (memberShipError || !data) {
          log.error('Failed to get memberships on INVITE');
          log.error(memberShipError);
          log.error(memberShipError.status);
          return;
        }

        log.debug('Got memberships', data);

        const memberships: Membership[] = data.memberships;
        // get the new membership and join the channel
        const newMembership = memberships.find((m) => m.channel.id === event.payload.channelId);

        //Nothing to join just return early
        if (!newMembership) {
          return;
        }

        // Get a new token with updated permissions
        const [refreshTokenError] = await withCatch(this.refreshTokenAsync());

        if (refreshTokenError) {
          //TODO not sure what to do if there is an error here
          throw refreshTokenError;
        }

        if (!this.authentication || !this.authentication.token) {
          log.error('No token found');
          return;
        }

        // Make sure we can join the channel for real
        const authenticationReadyFor = this.chat.sdk.parseToken(this.authentication.token);
        const channelsAvailableToJoin: string[] = authenticationReadyFor?.resources?.channels ? Object.keys(authenticationReadyFor.resources.channels) : [];

        if (channelsAvailableToJoin.length === 0) {
          log.warn('Channels are empty! We probably should abort, but we will try to continue');
        }

        if (!channelsAvailableToJoin.includes(event.payload.channelId)) {
          log.error('bad membership data discovered', event.payload.channelId);
          return;
        }
        // join the channel now
        this.knownChannels.set(newMembership.channel.id, newMembership.channel);
        const [connectToChannelError] = await withCatch(this.connectToChannel(newMembership.channel));

        // add this new channel to the front of the channel order
        this.globalChannelOrder = [newMembership.channel.id, ...this.globalChannelOrder.filter((c) => c !== newMembership.channel.id)];

        if (connectToChannelError) {
          //TODO what should we do if they fail to connect? Retry?
          log.error('Failed to connect to channel');
          log.error(connectToChannelError);
        }
      };

      this.inviteListenerFn = this.chat.listenForEvents({
        channel: this.chat.currentUser?.id,
        type: 'invite',
        callback: inviteCallback,
      });
    },
    restoreLastVisibleChannel() {
      const lastVisibleChannel = localStorage.getItem('current-channel');
      if (lastVisibleChannel) {
        log.debug('restoring last known visible channel', lastVisibleChannel);
        this.changeChannel(lastVisibleChannel).then();
      }
    },
    async setMyStatus(status: string) {
      if (!this.chat) {
        throw new Error('Chat not initialized');
      }
      const payload = {
        custom: {
          salesforce_id: `${this.chat.currentUser.custom?.salesforce_id ?? ''}`,
          role: 'member',
          group: `${this.chat.currentUser.custom?.group ?? ''}`,
          statusMessage: status.substring(0, 100),
        },
      };

      const [updateStatusError] = await withCatch(this.chat.currentUser.update(payload));
      if (updateStatusError) {
        log.error('Failed to update status');
        log.error(updateStatusError);
        return;
      }

      // Update the logged in user for immediate update(s)
      const user = this.knownUsersByUuid.get(this.chat.currentUser.id);
      if (user) {
        user.statusMessage = status;
        this.knownUsersByUuid.set(this.chat.currentUser.id, user);
      }

      this.broadcastUpdateNotice();
    },
    restoreNotifications() {
      const alerts = localStorage.getItem('chat-alerts');
      const mentions = localStorage.getItem('chat-mentions');
      this.channelAlerts = alerts ? restoreSerializedMap(alerts) : new Map<string, number>();
      this.unreadMentions = mentions ? restoreSerializedMap(mentions) : new Map<string, number>();
    },
    disconnect() {
      if (!this.chat) {
        log.warn('Chat not initialized, just resetting store.');
        this.$reset();
        return;
      }

      log.info('Disconnecting from chat');
      this.chat.sdk.destroy(true);
      log.info('Disconnected from chat');
      this.$reset();
    },
    createNotificationParamsFromHtml(message: Message) {
      const htmlToRawTextWithoutMentions = (html: string): string => {
        // Create a temporary DOM element
        const tempElement = document.createElement('div');
        tempElement.innerHTML = html;

        const processNode = (node: Node) => {
          let result = '';

          node.childNodes.forEach((child: ChildNode) => {
            if (child.nodeType === Node.TEXT_NODE) {
              // If it's a text node, append its text content
              result += child.textContent;
            } else if (child.nodeType === Node.ELEMENT_NODE) {
              if ((child as HTMLElement).tagName.toLowerCase() === 'span' && (child as HTMLElement).classList.contains('mention')) {
                // Skip this node, effectively removing the mention
              } else {
                // Otherwise, process its child nodes recursively
                result += processNode(child);
              }
            }
          });

          return result;
        };

        return processNode(tempElement);
      };

      let notificationTitle = 'Received a message';
      notificationTitle = `${this.knownUsersByUuid.get(message.userId)?.name} sent a message`;

      //Send system notification on mention
      return {
        title: notificationTitle,
        body: htmlToRawTextWithoutMentions(message.text),
      };
    },
    handleNewMessageOnChannel(message: Message) {
      this.globalChannelOrder = [message.channelId, ...this.globalChannelOrder.filter((c) => c !== message.channelId)];
      ChatService.getInstance()
        .setChannelOrder([...this.globalChannelOrder])
        .then()
        .catch();

      // TODO: TEMPORARY BLOCK. Sometimes, we see duplicate messages appear in the channels and I'm not sure why. Is pubnub calling this twice?
      const currentMessageStackSize = this.channelMessages.get(message.channelId)?.length;
      if (currentMessageStackSize) {
        const lastMessage = this.channelMessages.get(message.channelId)?.[currentMessageStackSize - 1];
        if (lastMessage && `${lastMessage.timetoken}` === message.timetoken) {
          log.error('Duplicate message processed with matching timetoken', message.timetoken);
          this.reportManualError(`Duplicate message processed with matching timetoken - ${JSON.stringify(this.chat?.sdk.getSubscribedChannels())}`);
        }
      }

      // this needs to happen to set the user who sent the mssage online early
      this.onlineUserIds.add(message.userId);

      this.channelMessages.get(message.channelId)?.push(wrapPubnubMessageToFriendlyObservable(message));
      // @todo test observability
      this.rawMessages.set(message.timetoken, markRaw(message));

      // if we are not on the current channel, we need to stream message updates on this message by itself for now
      if (this.currentChannelUrl !== message.channelId) {
        // check to see if this channel id has any messages currently streaming on it
        if (!this.pendingMessageUpdateStreams.has(message.channelId)) {
          this.pendingMessageUpdateStreams.set(message.channelId, []);
        }
        const individualMessageUpdateStream = message.streamUpdates((message) => {
          this.updateMessage(message);
        });
        // append the message to the pending message update stream
        this.pendingMessageUpdateStreams.get(message.channelId)?.push(individualMessageUpdateStream);
      }

      /**
       * Handle typing updates
       */
      if (this.currentChannelUrl === message.channelId) {
        if (this.currentlyTyping.has(message.userId)) {
          clearTimeout(this.currentlyTyping.get(message.userId));
          this.currentlyTyping.delete(message.userId);
        }
      }

      /**
       * Check if we don't know this user at all
       */
      if (message.userId && !this.knownUsersByUuid.has(message.userId)) {
        this.updateKnownUsers([message.userId]).then().catch();
      } else if (message.userId && this.knownUsersByUuid.has(message.userId)) {
        // update the user status to ACTIVE by hand
        const user = this.knownUsersByUuid.get(message.userId);
        if (user) {
          user.status = 'ACTIVE';
          this.knownUsersByUuid.set(message.userId, user);
        }
      }

      if (GLOBAL_IGNORE_CHANNELS.includes(this.currentChannelUrl)) {
        return;
      }

      const isUserMentioned = Object.values(message.mentionedUsers as MessageMentionedUsers).some((user) => user.id === this.chat?.currentUser?.id);
      console.log(message.meta);
      const mentionEveryone = message?.meta?.mentionEveryone;
      const mentionHere = message?.meta?.mentionHere;
      const isDifferentChannel = this.currentChannelUrl !== message.channelId;
      const isChannelMuted = this.mutedChannels.has(message.channelId);

      if (isDifferentChannel && (isUserMentioned || mentionEveryone || mentionHere)) {
        this.addMention(message.channelId);

        if (!isChannelMuted) {
          AudioService.getInstance().playBoopSound().catch();
          this.bounceDock();
        }
        this.incrementAlerts(message.channelId);
        const params = this.createNotificationParamsFromHtml(message);

        sendNotification(params);
      } else if (isDifferentChannel) {
        if (!isChannelMuted) {
          AudioService.getInstance().playBoopSound().catch();
          this.bounceDock();
        }
        this.incrementAlerts(message.channelId);
      } else {
        this.currentChannelMutations++;
      }
    },
    parseChannelOperators(channel: Channel) {
      if (!channel.custom?.operators) {
        return;
      }
      try {
        const operators = JSON.parse(channel.custom.operators as string);
        if (Array.isArray(operators)) {
          this.operators.set(channel.id, operators);
        }
      } catch (e) {
        log.error('failed to parse operators json');
      }
    },
    async extractPinnedMessagesForChannel(channel: Channel) {
      let channelPins = channel.custom?.pins ? JSON.parse(channel.custom.pins as string) : [];
      if (!Array.isArray(channelPins)) {
        channelPins = [];
      }

      for (const pin of channelPins) {
        const [getMessageError, message] = await withCatch(channel.getMessage(pin));
        if (getMessageError) {
          log.error('Failed to get pinned message');
          log.error(getMessageError);
          continue;
        }
        if (!message) {
          //message is not pinned
          continue;
        }

        addToArrayInMap(this.pinnedMessagesForChannels, channel.id, wrapPubnubMessageToFriendlyObservable(message));
      }
    },
    async connectToChannelFinished(channel: Channel, disconnect: () => void) {
      this.knownChannels.set(channel.id, channel);
      this.channelDisconnectFns.set(channel.id, disconnect);
      await this.loadChannelParticipants(channel.id);
      this.parseChannelOperators(channel);
      await this.extractPinnedMessagesForChannel(channel);
    },
    async forceLoadCurrentParticipants() {
      const now = DateTime.now();
      const THRESHOLD_MINUTES = 5;
      if (now.diff(this.currentChannelLastCheckedAt).as('minutes') < THRESHOLD_MINUTES) {
        log.info('Skipping force load of current participants less than 5 minutes since last check');
        return;
      }
      log.info('Force loading current participants');

      await this.loadChannelParticipants(this.currentChannelUrl);
      this.currentChannelLastCheckedAt = now;
    },
    stopCurrentChannelInterval() {
      if (this.currentChannelUpdaterInterval) {
        log.info('Clearing current channel interval');
        clearInterval(this.currentChannelUpdaterInterval);
        this.currentChannelUpdaterInterval = null;
      }
    },
    startCurrentChannelInterval(channel: Channel) {
      this.stopCurrentChannelInterval();
      if (channel.id.includes('announcements')) {
        return;
      }
      // default threshold of 20 minutes
      let updateEvery = 1000 * 60 * 20;
      // If they are an operator of the channel or the channel is a direct or group channel, update every 5 min
      if (this.operators.get(channel.id)?.includes(this.chatUserId) || !channel.id.includes('team_')) {
        updateEvery = 1000 * 60 * 5;
      }
      log.info('Starting current channel interval with update every ' + updateEvery + 's');
      this.currentChannelUpdaterInterval = setInterval(async () => {
        await this.loadChannelParticipants(channel.id);
        this.currentChannelLastCheckedAt = DateTime.now();
      }, updateEvery);
    },
    async connectToChannel(channel: Channel): Promise<boolean> {
      if (!this.chat) {
        throw new Error('Chat not initialized');
      }
      const currentlySubscribedChannels = this.chat.sdk.getSubscribedChannels();
      if (currentlySubscribedChannels.includes(channel.id)) {
        log.debug('found hot subscription, removing it to prevent double subscribe', channel.id);
        //@ts-ignore this isn't a promise pubnub types are fucking awful
        this.chat.sdk.unsubscribe({ channels: [channel.id] });
      }
      const [error, response] = await withCatch(channel.join(this.handleNewMessageOnChannel));

      if (error || response === undefined) {
        log.error('Failed to join channel');
        log.error(error);

        //retry handler
        for (let attempt = 1; attempt <= 5; attempt++) {
          log.info(`Attempting to connect to channel. Attempt ${attempt} of 5`);

          const [joinError, retryResponse] = await withCatch(channel.join(this.handleNewMessageOnChannel));

          if (joinError || !retryResponse) {
            log.info(`Attempt ${attempt} failed. Retrying in 3000ms...`);
            await new Promise((resolve) => setTimeout(resolve, 3000));
            continue;
          }

          const [connectToChannelError] = await withCatch(this.connectToChannelFinished(channel, retryResponse.disconnect));
          if (connectToChannelError) {
            log.error('Failed in retry channel setup.');
            log.error(error);
          }

          return true;
        }

        log.error(`All 5 attempts failed. Giving up.`);
        return false;
      }

      this.connectToChannelFinished(channel, response.disconnect).catch((error) => {
        log.error('Failed to connect to channel');
        log.error(error);
      });

      return true;
    },
    async loadChannelParticipants(id: string, nextPageLink?: string) {
      const channel = this.knownChannels.get(id);
      if (!channel || GLOBAL_IGNORE_CHANNELS.includes(id)) {
        return;
      }
      const payload = {
        limit: 100,
        page: {
          next: nextPageLink,
        },
      };

      const [getMembersError, members] = await withCatch(channel.getMembers(payload));

      if (getMembersError || !members) {
        log.error('Failed to get channel members');
        log.error(getMembersError);
        return;
      }

      if (!this.channelParticipantsMinimal.has(id)) {
        this.channelParticipantsMinimal.set(id, new Set());
      }
      const nonObservedMembers: KnownChatUser[] = [];

      for (const member of members.members) {
        this.channelParticipantsMinimal.get(id)?.add(member.user.id);
        // console.log(member.user.name, member.user.active);
        if (member.user.active) {
          this.onlineUserIds.add(member.user.id);
          addToArrayInMap(this.participantsOnlineInChannel, id, member.user.id);
        } else {
          removeFromArrayInMap(this.participantsOnlineInChannel, id, member.user.id);
        }

        if (member.user.custom?.trackerStatus === 'IDLE') {
          this.idleUserIds.add(member.user.id);
        }
        const knownUser = {
          id: member.user.id,
          name: member.user.name ?? 'Unknown',
          profileUrl: member.user.profileUrl ?? '',
          status: `${member.user.custom?.trackerStatus ?? 'UNKNOWN'}`,
          statusMessage: `${member.user.custom?.statusMessage ?? ''}`,
        };
        if (member.user.id === 'mytimein-user-23766') {
          console.log('user status: ', member.user.status);
        }
        this.knownUsersByUuid.set(member.user.id, knownUser);
        nonObservedMembers.push(knownUser);
      }

      ChatService.getInstance().addUsers(nonObservedMembers).then().catch();
      syncChannelToCache(this, id);
      if (members.page.next && members.page.next !== nextPageLink) {
        await this.loadChannelParticipants(id, members.page.next);
      }
    },
    deleteMessageFromChannel(channelId: string, timetoken: string) {
      const messageIndexToRemove = this.channelMessages.get(channelId)?.findIndex((m) => m.timetoken === timetoken);
      if (messageIndexToRemove === -1 || messageIndexToRemove === undefined) {
        return;
      }
      this.channelMessages.get(channelId)?.splice(messageIndexToRemove, 1);
    },
    async deleteMessage(timetoken: string) {
      // just prune it from the message list, as its deleted in the channel view
      const messages = this.currentChannelMessages;
      const index = messages.findIndex((msg) => msg.timetoken === timetoken);

      if (index < 0) {
        return;
      }
      // find the raw message
      const originalMessageToRemove = this.rawMessages.values().find((msg) => msg.timetoken === timetoken);
      if (!originalMessageToRemove) {
        return;
      }
      const [deleteError] = await withCatch(originalMessageToRemove.delete({ soft: true }));

      if (deleteError) {
        log.error('Failed to delete message.');
        log.error(deleteError);
        return;
      }
    },
    async removePin(timetoken: string) {
      const message = this.getMessage(timetoken);
      if (!message) {
        return;
      }
      const appStore = useAppStore();
      const channel_id = this.currentChannelUrl;
      const [unpinError] = await withCatch(appStore.api.unpinMessageInChannel(channel_id, timetoken));

      if (unpinError) {
        log.error('Failed to unpin message');
        log.error(unpinError);
        return;
      }

      removeFromArrayInMapWithPredicate(this.pinnedMessagesForChannels, channel_id, (m) => m.timetoken === timetoken);
    },
    async pinMessage(timetoken: string) {
      const message = this.getMessage(timetoken);
      if (!message) {
        return;
      }
      const appStore = useAppStore();
      const channel_id = this.currentChannelUrl;
      const [pinError] = await withCatch(appStore.api.pinMessageInChannel(channel_id, timetoken));

      if (pinError) {
        log.error('Failed to pin message');
        log.error(pinError);
        return;
      }

      addToArrayInMap(this.pinnedMessagesForChannels, channel_id, message);
    },
    streamUpdatesOnCurrentChannelList() {
      if (!this.chat) {
        throw new Error('Chat not initialized');
      }
      if (this.currentChannelListDisconnectFunction) {
        log.debug('removing channel list watch and registering another channel list watch');
        this.currentChannelListDisconnectFunction();
      }

      const channels = Array.from(this.knownChannels.values());
      if (channels.length < 1) {
        log.info('No channels to watch');
        return;
      }

      this.currentChannelListDisconnectFunction = Channel.streamUpdatesOn(Array.from(this.knownChannels.values()) as Channel[], async (channels) => {
        for (const channel of channels) {
          if (!this.chat) {
            log.error('Chat not initialized, reloading.');
            window.location.href = `${window.location.pathname}?timestamp=${new Date().getTime()}`;
            return;
          }
          const currentChannel = this.knownChannels.get(channel.id);

          if (!currentChannel) {
            continue;
          }

          const [getChannelError, updatedChannel] = await withCatch(this.chat.getChannel(channel.id));

          if (getChannelError || !updatedChannel) {
            log.error('Failed to get channel');
            log.error(getChannelError);
            continue;
          }
          log.info('Got a channel update', updatedChannel);
          // @ts-ignore
          currentChannel.name = updatedChannel.name ?? currentChannel.name;
          // @ts-ignore
          currentChannel.custom = updatedChannel.custom ?? currentChannel.custom;
          // @ts-ignore
          currentChannel.description = updatedChannel.description ?? currentChannel.description;
        }
      });
    },
    async leaveChannel(id: string) {
      const channel = this.knownChannels.get(id);
      if (!channel) {
        return;
      }

      const [leaveError] = await withCatch(channel.leave());
      if (leaveError) {
        log.error('Failed to leave channel');
        log.error(leaveError);
        return;
      }

      this.knownChannels.delete(id);
      this.channelMessages.delete(id);
    },
    async muteUserWhoPostedMessage(message: MyTimeInChatMessage, timeout: number) {
      if (!this.chat) {
        throw new Error('Chat not initialized');
      }

      const [setRestrictionError] = await withCatch(this.chat.setRestrictions(message.userId, message.channelId, { mute: true }));
      if (setRestrictionError) {
        log.error('Failed to set mute restrictions');
        log.error(setRestrictionError);
        log.error(setRestrictionError.status);
        return;
      }

      // TODO: Move this to somewhere else, i could just leave and they'd never been un-muted in this channel
      setTimeout(() => {
        this.chat?.setRestrictions(message.userId, message.channelId, {
          mute: false,
        });
      }, timeout * 1000);
    },
    async loadChannelMessages(id: string) {
      const channel = this.knownChannels.get(id);
      if (!channel) {
        throw new Error('Channel not found');
      }
      if (this.loadingChannels.has(id) || this.finishedLoadingChannels.has(id)) {
        log.debug('not loading messages for channel', id, {
          loading: this.loadingChannels.has(id),
          finished: this.finishedLoadingChannels.has(id),
        });
        return;
      }
      let shouldGetMore = true;
      this.loadingChannels.add(id);
      const discoveredMessages: Message[] = [];
      const currentMessageLength = this.channelMessages.get(id)?.length ?? 0;

      while (shouldGetMore) {
        // use channelMessages if discoveredMessages is empty
        let lastKnownMessageTimetoken = this.channelMessages.get(id)?.[0]?.timetoken ?? null;
        if (discoveredMessages.length !== 0) {
          lastKnownMessageTimetoken = discoveredMessages[0]?.timetoken ?? null;
        }
        const historyParameters = {
          count: MESSAGES_TO_LOAD_MAX,
          startTimetoken: lastKnownMessageTimetoken ? `${lastKnownMessageTimetoken}` : undefined,
        };
        log.debug('loading messages for channel', id, historyParameters);
        const [getHistoryError, history] = await withCatch(channel.getHistory(historyParameters));
        console.log(history);
        if (getHistoryError || !history) {
          log.error('Failed to get history');
          log.error(getHistoryError);
          shouldGetMore = false;
          continue;
        }

        try {
          const { messages } = await channel.getHistory(historyParameters);
          // prepend to discovered messages
          discoveredMessages.unshift(...messages);
          // these are loaded in chunks of 25, so if we get less than 25, we're done
          if (messages.length < 20 || discoveredMessages.length >= 50) {
            shouldGetMore = false;
          }
        } catch (error) {
          log.error('failed to load more messages');
          log.error(error);
          shouldGetMore = false;
        }
      }
      const knownMessages = [...discoveredMessages, ...(this.channelMessages.get(id) ?? [])];
      // deduplicate knownMessages by user id and timetoken
      const deDupedMessages = new Map<string, MyTimeInChatMessage>();

      for (const message of knownMessages) {
        deDupedMessages.set(`${message.timetoken}-${message.userId}`, wrapPubnubMessageToFriendlyObservable(message as Message));
        // @ts-ignore
        this.rawMessages.set(message.timetoken, markRaw(message));
      }

      this.channelMessages.set(id, Array.from(deDupedMessages.values()));
      // compare the old currentMessagesLength to the new one
      if (this.channelMessages.get(id)?.length === currentMessageLength) {
        this.finishedLoadingChannels.add(id);
      }
      // see what missing ids we have from knownUsers vs de-duplicated Messages
      const missingUserIds = Array.from(new Set([...deDupedMessages.values()].map((m) => m.userId))).filter((userId) => !this.knownUsersByUuid.has(userId));
      // build out the message reactions that are known
      for (const message of knownMessages) {
        this.knownReactionsToMessages.set(`${message.timetoken}`, message.reactions);
        for (const reaction of Object.keys(message.reactions)) {
          for (const user of message.reactions[reaction]) {
            if (!this.knownUsersByUuid.has(user.uuid)) {
              missingUserIds.push(user.uuid);
            }
          }
        }
      }
      this.loadingChannels.delete(id);
      this.currentChannelMutations++;
      this.updateKnownUsers(missingUserIds).then().catch();
    },
    replyToMessage(timeToken: string) {
      const messages = this.currentChannelMessages;
      const message = messages.find((m) => m.timetoken === timeToken);
      if (!message) {
        return;
      }
      this.channelComposerStates.set(message.channelId, {
        channelUrl: message.channelId,
        composerState: STATE_REPLYING,
        replyingToMessage: message,
        editingMessage: null,
      });
    },
    getMessage(timetoken: string): MyTimeInChatMessage | undefined {
      const messages = this.currentChannelMessages;
      return messages.find((m) => m.timetoken === timetoken) as MyTimeInChatMessage | undefined;
    },
    async refreshMessageFromPubnub(timetoken: string) {
      const currentChannel = this.knownChannels.get(this.channelUrl);

      if (!currentChannel) {
        throw new Error('Channel not found');
      }

      const message = this.getMessage(timetoken);
      if (!message) {
        return;
      }

      const [getMessageError, updatedMessage] = await withCatch(currentChannel.getMessage(`${message.timetoken}`));
      if (getMessageError || !updatedMessage) {
        log.error('Failed to get message');
        log.error(getMessageError);
        return;
      }

      this.updateMessage(updatedMessage);
    },
    updateMessage(message: Message) {
      // log.debug('updating message', message.timetoken);
      const messages = this.channelMessages.get(message.channelId);
      if (!messages) {
        return;
      }
      const index = messages.findIndex((m) => m.timetoken === message.timetoken);
      if (index > -1) {
        messages.splice(index, 1, wrapPubnubMessageToFriendlyObservable(message));
      } else {
        messages.push(wrapPubnubMessageToFriendlyObservable(message));
      }
    },
    stopReplyingToMessage(channelUrl: string) {
      this.channelComposerStates.delete(channelUrl);
    },
    startEditingMessage(timeToken: string) {
      const message = this.getMessage(timeToken);
      if (!message) {
        log.error('Message not found. Can not edit null message');
        return;
      }
      this.channelComposerStates.set(message.channelId, {
        channelUrl: message.channelId,
        composerState: STATE_EDITING,
        replyingToMessage: null,
        editingMessage: message,
      });
    },
    stopEditingCurrentMessage() {
      this.stopEditingMessage(this.channelUrl);
    },
    stopEditingMessage(channelUrl: string) {
      this.channelComposerStates.delete(channelUrl);
    },
    incrementAlerts(channelUrl: string) {
      const currentAlertCount = this.channelAlerts.get(channelUrl) ?? 0;
      this.channelAlerts.set(channelUrl, currentAlertCount + 1);
      localStorage.setItem('chat-alerts', serializeMap(this.channelAlerts));
    },
    bounceDock() {
      invoke('bounce_dock_icon').catch((error) => {
        log.error('failed to bounce dock icon');
        log.error(error);
      });
    },
    addMention(channelUrl: string) {
      const currentMentionCount = this.unreadMentions.get(channelUrl) ?? 0;
      this.unreadMentions.set(channelUrl, currentMentionCount + 1);
      localStorage.setItem('chat-mentions', serializeMap(this.unreadMentions));
    },
    removeAlerts(channelUrl: string) {
      this.channelAlerts.delete(channelUrl);
      localStorage.setItem('chat-alerts', serializeMap(this.channelAlerts));
    },
    removeMentions(channelUrl: string) {
      this.unreadMentions.delete(channelUrl);
      localStorage.setItem('chat-mentions', serializeMap(this.unreadMentions));
    },
    async changeChannel(channelId: string) {
      log.info('Changing channel to', channelId);
      this.channelUrl = channelId;

      const channel = this.knownChannels.get(channelId) as Channel;

      if (channel && !this.channelDisconnectFns.has(channelId)) {
        log.info('Channel already known, connecting to it', channelId);

        // TODO: what do we do here?
        const [connectError] = await withCatch(this.connectToChannel(channel));
        if (connectError) {
          log.error('Failed to connect to known channel.');
          log.error(connectError);
          return;
        }

        this.startCurrentChannelInterval(channel);
      }

      const isChannelMessagesEmpty = !this.channelMessages.has(channelId) || this.channelMessages.get(channelId)?.length === 0;
      if (isChannelMessagesEmpty) {
        log.info('Loading messages for channel. ', channelId);
        const [loadMessageError] = await withCatch(this.loadChannelMessages(channelId));

        if (loadMessageError) {
          log.error('Failed to load channel messages');
          log.error(loadMessageError);
        }
      }

      this.dumpCurrentlyTypingList();
      // this.watchTyping(channelId);
      await this.watchPresence(channelId);
      this.removeAlerts(channelId);
      this.removeMentions(channelId);
      // clear local mutations
      this.currentChannelMutations = 0;
      localStorage.setItem('current-channel', channelId);
    },
    dumpCurrentlyTypingList() {
      for (const timeout of this.currentlyTyping.values()) {
        clearTimeout(timeout);
      }
      this.currentlyTyping.clear();
    },
    async watchPresence(id: string) {
      //I think this could exist in some rare circumstance where the chat sdk is not initialized
      if (this.currentChannelParticipantListStreamDisconnectFunction) {
        log.info('Unregistering the current presence watch.');
        this.currentChannelParticipantListStreamDisconnectFunction();
      }

      if (!this.chat) {
        throw new Error('Chat not initialized');
      }

      const channel = this.knownChannels.get(id);

      if (!channel) {
        throw new Error('Channel not found');
      }

      const streamPresenceCallback = (userIds: string[]) => {
        if (userIds.length === 0) {
          log.warn('got empty response from streamPresence?');
          return;
        }
      };

      const [presenceError, disconnect] = await withCatch(channel.streamPresence(() => streamPresenceCallback));
      if (presenceError || !disconnect) {
        log.error('Failed to stream presence');
        log.error(presenceError);
        return;
      }

      this.currentChannelParticipantListStreamDisconnectFunction = disconnect;
    },
    watchTyping(id: string) {
      //I think this could exist in some rare circumstance where the chat sdk is not initialized
      if (this.currentChannelStopTypingUpdatesDisconnectFunction) {
        log.info('Unregistering the current typing watch.');
        this.currentChannelStopTypingUpdatesDisconnectFunction();
      }

      if (!this.chat) {
        throw new Error('Chat not initialized');
      }

      const channel = this.knownChannels.get(id);
      if (!channel) {
        throw new Error('Channel not found');
      }

      this.dumpCurrentlyTypingList();

      const typingCallback = (typing: string[]) => {
        if (this.currentChannelRaw?.id !== channel.id) {
          log.warn('I should not have received this event');
          return;
        }

        for (const typingUser of typing) {
          if (typingUser === this.chat?.currentUser?.id) {
            return;
          }
          if (this.currentlyTyping.has(typingUser)) {
            clearTimeout(this.currentlyTyping.get(typingUser));
          }
          this.currentlyTyping.set(
            typingUser,
            setTimeout(() => {
              this.currentlyTyping.delete(typingUser);
            }, 5000)
          );
        }
      };

      this.currentChannelStopTypingUpdatesDisconnectFunction = channel.getTyping(() => typingCallback);
    },
    async applyReactionToMessage(timetoken: string, emoji: string) {
      const message = this.getMessage(timetoken);
      if (!message) {
        return;
      }

      // find the raw message in the set
      const discoveredMessage = this.rawMessages.get(timetoken);
      if (!discoveredMessage) {
        log.error('Could not find message in rawMessages');
        return;
      }
      const [toggleReactionError] = await withCatch(discoveredMessage.toggleReaction(emoji));

      if (toggleReactionError) {
        if (toggleReactionError.includes('status of 409 (Conflict)')) {
          this.refreshMessageFromPubnub(timetoken);
          return;
        }
        log.error('Failed to toggle reaction');
        log.error(toggleReactionError);
        log.error(toggleReactionError.status);
        return;
      }
    },
    async refreshChatNetwork(): Promise<void> {
      // TODO: REMOVE THIS FUNCTION?
      if (!this.chat) {
        throw new Error('Chat not initialized');
      }
      const appStore = useAppStore();
      const [getChatNetworkError, data] = await withCatch(appStore.api.getChatNetwork());
      if (getChatNetworkError || !data) {
        log.error('Failed to get chat network');
        log.error(getChatNetworkError);
        return;
      }

      const users = data.users;
      this.fullNetwork = users;
      ChatService.getInstance().addUsers(users).catch();
    },
    async createGroupConversation(participants: ChatUser[]) {
      if (!this.chat) {
        throw new Error('Chat not initialized');
      }

      if (!this.chat.currentUser?.name) {
        log.error('User not known');
        return;
      }

      const appStore = useAppStore();
      const payload = {
        invite_uuids: participants.map((user) => user.id),
        type: 'group',
      };

      const [createChatChannelError, data] = await withCatch(appStore.api.createChatChannel(payload));
      if (createChatChannelError || !data) {
        log.error('Failed to create group channel');
        log.error(createChatChannelError);
        log.error(createChatChannelError.status);
        return;
      }

      const { channel_id } = data;
      await this.refreshTokenAsync();
      await this.joinNewlyCreatedChannel(channel_id);

      return channel_id;
    },
    async createDirectConversation(user: ChatUser) {
      if (!this.chat || !this.chat.currentUser?.name) {
        throw new Error('Chat not initialized');
      }

      const appStore = useAppStore();
      const [createDirectChannelError, data] = await withCatch(appStore.api.createDirectChatChannel(user.id));

      if (createDirectChannelError || !data) {
        log.error('Failed to create direct channel');
        log.error(createDirectChannelError);
        return;
      }

      const { channel_id } = data;

      await this.refreshTokenAsync();
      log.info('Joining newly created channel', channel_id);
      await this.joinNewlyCreatedChannel(channel_id);

      return channel_id;
    },
    async joinNewlyCreatedChannel(channel_id: string) {
      if (!this.chat) {
        throw new Error('Chat not initialized');
      }

      const currentlySubscribedChannels = this.chat?.sdk.getSubscribedChannels() ?? [];
      if (currentlySubscribedChannels.includes(channel_id)) {
        this.changeChannel(channel_id).then();
        return;
      }

      const [error, channel] = await withCatch(this.chat.getChannel(channel_id));
      if (error || !channel) {
        log.error('failed to get channel information');
        log.error(error);
        return;
      }

      const joinResult = await this.connectToChannel(channel);
      if (!joinResult) {
        log.error('failed to join channel');
        return;
      }

      this.changeChannel(channel.id).then();
    },
    async createChannel(participants: ChatUser[]) {
      if (participants.length === 1) {
        log.info('Creating direct conversation');
        return this.createDirectConversation(participants[0]);
      }
      log.info('Creating group conversation');
      return this.createGroupConversation(participants);
    },
    scheduleTokenRefresh() {
      if (this.tokenRefreshInterval) {
        clearInterval(this.tokenRefreshInterval);
      }
      this.tokenRefreshInterval = setInterval(async () => {
        if (this.tokenExpirationTime && Date.now() > this.tokenExpirationTime.getTime()) {
          await this.refreshChatToken();
        }
      }, 5000);
    },
    async refreshTokenAsync() {
      if (!this.chat) {
        throw new Error('Chat not initialized');
      }

      const appStore = useAppStore();
      log.info('Refreshing chat token async');
      const [refreshTokenError, authentication] = await withCatch(appStore.api.refreshChatToken());

      if (refreshTokenError || !authentication) {
        log.error('Failed to refresh chat token.');
        log.error(refreshTokenError);
        throw refreshTokenError;
      }

      this.authentication = authentication;
      const [getSignedCookieError, _signedCookieData] = await withCatch(appStore.api.getSignedCookie(this.authentication.token));

      if (getSignedCookieError) {
        log.error('Failed to get signed cookie.');
        log.error(getSignedCookieError);
        throw getSignedCookieError;
      }

      this.tokenExpirationTime = new Date(Date.now() + this.authentication.ttl * 1000 - 10000);

      log.info('Applying new token to chat SDK');
      this.chat.sdk.setToken(this.authentication.token);
    },
    async refreshChatToken(fromErrorState = false) {
      log.info('Refreshing chat token');

      if (this.busyUpdatingToken) {
        log.info('Token refresh already in progress');
        return;
      }

      const appStore = useAppStore();
      if (!appStore.currentUser) {
        log.error('User not known. Could not refresh token.');
        return;
      }

      this.busyUpdatingToken = true;

      if (fromErrorState && this.currentRecoveryAttempts > 5) {
        log.error('Too many recovery attempts, giving up.');
        return;
      }

      if (fromErrorState) {
        //TODO I don't think our recovery attempts is properly resetting is this code ever getting hit more than once?
        log.info('Recovering from error state');
        this.currentRecoveryAttempts++;
        this.disconnect();

        log.info(`Re-initializing entire chat app after token refresh due to lost connection. User ID: mytimein-user-${appStore.currentUser.id}`);

        const [reinitError] = await withCatch(this.initSdkIfRequired(appStore.currentUser.id));
        this.busyUpdatingToken = false;
        this.currentRecoveryAttempts = 0;

        if (reinitError) {
          log.error('Failed to reinitialize chat app');
          log.error(reinitError);
          return;
        }

        return;
      }

      log.info('Fetching another token');

      const [refreshError, auth] = await withCatch(appStore.api.refreshChatToken());
      this.busyUpdatingToken = false;

      if (refreshError || !auth) {
        log.error('Failed to refresh chat token');
        log.error(refreshError);
        return;
      }

      log.debug(`Received authentication token response: `, auth);
      this.authentication = auth;
      this.tokenExpirationTime = new Date(Date.now() + auth.ttl * 1000 - 10000);
      if (this.chat && this.chat.sdk) {
        this.chat.sdk.setToken(auth.token);
      }

      // set our cookie
      const [getCookieError] = await withCatch(appStore.api.getSignedCookie(auth.token));
      if (getCookieError) {
        log.error('Failed to get signed cookie');
        log.error(getCookieError);
      }
    },
    addPendingSend(channelId: string, pendingMessage: PendingMyTimeInChatMessage): number {
      // perform some additional mutations to make it look like a partial<Message>
      pendingMessage.timetoken = TimetokenUtils.dateToTimetoken(new Date());
      pendingMessage.content = {
        files: [],
        text: 'Sending Message...',
        type: 'text',
        meta: {
          type: 'pending',
        },
      };
      pendingMessage.mentionedUsers = [];
      pendingMessage.channelId = channelId;
      pendingMessage.userId = this.currentUserId;
      pendingMessage.meta = { type: 'pending' };
      pendingMessage.customStatus = 'Sending Message...';
      addToArrayInMap(this.pendingMessagesMap, channelId, pendingMessage);

      return pendingMessage.timetoken;
    },
    updatePendingSendCustomStatus(channelId: string, pendingTimetoken: number, status: string) {
      const pendingMessage = this.pendingMessagesMap.get(channelId)?.find((m) => m.timetoken === `${pendingTimetoken}`);

      if (!pendingMessage || !pendingMessage.content) {
        return;
      }

      pendingMessage.content.text = pendingMessage.customStatus = status;
    },
    decrementPendingSend(channelId: string, pendingTimetoken: number) {
      removeFromArrayInMapWithPredicate(this.pendingMessagesMap, channelId, (m) => m.timetoken === pendingTimetoken);
    },
    closeAllOpenMenus() {
      this.showMessageContextMenu = false;

      this.emojiPickerOpen = false;

      this.participantCardOpen = false;
      this.participantCardUser = null;

      this.composerFocusStealOverride = false;

      const layer1 = document.getElementById('chat-layer');
      const layer2 = document.getElementById('chat-layer');

      if (layer1) {
        layer1.style.visibility = 'hidden';
        layer1.style.top = '0px';
        layer1.style.left = '0px';
      }

      if (layer2) {
        layer2.style.visibility = 'hidden';
        layer2.style.top = '0px';
        layer2.style.left = '0px';
      }
    },
    pruneEditMessageActions(parentTimetoken: string, timetokens: string[]) {
      if (!this.chat) {
        throw new Error('Chat not initialized');
      }

      const pendingRequests = [] as Promise<any>[];

      for (const timeToken of timetokens) {
        pendingRequests.push(
          this.chat.sdk.removeMessageAction({
            channel: this.currentChannelUrl,
            messageTimetoken: parentTimetoken,
            actionTimetoken: timeToken,
          })
        );
      }

      return Promise.all(pendingRequests);
    },
    async updateChannel(id: string, newParticipants: ChatUser[]) {
      const channel = this.knownChannels.get(id);

      if (!channel) {
        throw new Error('Channel not found');
      }

      if (newParticipants.length === 0) {
        return;
      }

      if (channel.id.startsWith('direct.')) {
        // if the original channel was a direct channel and we are adding users to it, we need to actually make a new group channel instead
        const currentParticipants = (await channel.getMembers()).members.map((member) => member.user);
        return this.createChannel([...newParticipants, ...currentParticipants]);
      }

      const appStore = useAppStore();
      const payload = {
        channel_id: channel.id,
        invite_uuids: newParticipants.map((user) => user.id),
      };

      const [inviteMembersError] = await withCatch(appStore.api.inviteMembersToChatChannel(payload));

      if (inviteMembersError) {
        log.error('failed to invite multiple users');
        log.error(inviteMembersError);
        return;
      }
    },
    async updateKnownUsers(missingIds: string[]) {
      if (!this.chat) {
        throw new Error('Chat not initialized');
      }

      for (const id of missingIds) {
        if (id === 'unknown-user') {
          // too early?
          return;
        }
        if (id === 'PUBNUB_INTERNAL_MODERATOR') {
          this.knownUsersByUuid.set(id, {
            id: id,
            name: 'Moderator',
            profileUrl: '',
            statusMessage: '',
          });
          return;
        }
        const [getUserError, user] = await withCatch(this.chat.getUser(id));

        if (getUserError || !user) {
          this.lastErrors.push(JSON.stringify(getUserError));
          log.error('Failed to query user');
          log.error(getUserError);
          log.error(getUserError.status);
          return;
        }
        if (user.active && !this.onlineUserIds.has(user.id)) {
          this.onlineUserIds.add(user.id);
        }
        const unobservedUser = {
          id: user.id,
          name: user.name ?? 'Unknown',
          profileUrl: user.profileUrl ?? '',
          statusMessage: `${user.custom?.statusMessage ?? ''}`,
        };
        this.knownUsersByUuid.set(user.id, unobservedUser);
        ChatService.getInstance().addUser(unobservedUser).catch();
      }
    },
    reportManualError(error: string) {
      this.lastErrors.push(error);
    },
  },
  getters: {
    currentlyReplyingTo: (state): Message | null => {
      return (state.channelComposerStates.get(state.channelUrl)?.replyingToMessage ?? null) as Message | null;
    },
    currentlyEditingMessage: (state): Message | null => {
      return (state.channelComposerStates.get(state.channelUrl)?.editingMessage ?? null) as Message | null;
    },
    currentChannelParticipants: (state): Set<string> => {
      return state.channelParticipantsMinimal.get(state.channelUrl) ?? new Set<string>();
    },
    currentChannelUsers: (state): KnownChatUser[] => {
      const idsInCurrentChannel = state.channelParticipantsMinimal.get(state.channelUrl) ?? new Set<string>();
      return Array.from(idsInCurrentChannel)
        .map((id) => state.knownUsersByUuid.get(id) as KnownChatUser)
        .filter((user): user is KnownChatUser => user !== undefined); // Filters out undefined users
    },
    isLoadingCurrentChannelUrl: (state): boolean => {
      return state.loadingChannels.has(state.channelUrl);
    },
    currentChannelUrl: (state): string => {
      return state.channelUrl;
    },
    currentChannelMessages: (state): MyTimeInChatMessage[] => {
      return (state.channelMessages.get(state.channelUrl) ?? []) as MyTimeInChatMessage[];
    },
    currentPendingMessages: (state): PendingMyTimeInChatMessage[] => {
      return state.pendingMessagesMap.get(state.channelUrl) ?? [];
    },
    currentlyTypingNames: (state): string[] => {
      return Array.from(state.currentlyTyping.keys()).map((id) => state.knownUsersByUuid.get(id)?.name ?? '');
    },
    currentChannelRaw: (state): Channel | undefined => {
      return state.knownChannels.get(state.channelUrl) as Channel | undefined;
    },
    currentChannelOnlineUserIds: (state): string[] => {
      return state.participantsOnlineInChannel.get(state.channelUrl) ?? [];
    },
    currentUserId: (state): string | null => {
      return state.chatUserId;
    },
    currentChannelPins: (state): MyTimeInChatMessage[] => {
      return (state.pinnedMessagesForChannels.get(state.channelUrl) ?? []) as MyTimeInChatMessage[];
    },
    currentChannelPinnedTimetokens: (state): string[] => {
      return state.pinnedMessagesForChannels.get(state.channelUrl)?.map((m) => m.timetoken) ?? [];
    },
    getUser:
      (state) =>
      (id: string): KnownChatUser | undefined => {
        return state.knownUsersByUuid.get(id);
      },
    canOperateOnCurrentChannel: (state): boolean => {
      return state.operators.get(state.channelUrl)?.includes(state.chatUserId ?? '') ?? false;
    },
    isOperatorOnCurrentChannel:
      (state) =>
      (id: string): boolean => {
        return state.operators.get(state.channelUrl)?.includes(id) ?? false;
      },
    getUserStatus:
      (state) =>
      (id: string): string => {
        return state.knownUsersByUuid.get(id)?.statusMessage ?? '';
      },
    reactionsForMessage:
      (state) =>
      (timetoken: string | number): ChatReactionCollection => {
        return state.knownReactionsToMessages.get(`${timetoken}`) ?? {};
      },
  },
});

function loadUserFromStorage(store: ReturnType<typeof useChatStore>) {
  log.info('Getting users');
  const chatInstance = ChatService.getInstance();
  chatInstance
    .getUsers()
    .then((users) => {
      if (users && users.length) {
        store.knownUsersByUuid = new Map(users.map((user) => [user.id, user]));
      }
    })
    .catch((usersErrors) => {
      if (usersErrors) {
        log.error('Failed to get users');
        log.error(usersErrors);
        return;
      }
    });
}

function loadChannelOrderFromStorage(store: ReturnType<typeof useChatStore>) {
  log.info('Getting Channel Order');
  const chatInstance = ChatService.getInstance();
  chatInstance
    .getChannelOrder()
    .then((channelOrder) => {
      log.info('Got order', channelOrder);

      if (channelOrder) {
        store.globalChannelOrder = channelOrder;
      }
    })
    .catch((chanelOrderError) => {
      if (chanelOrderError) {
        log.error('Failed to get channel order');
        log.error(chanelOrderError);
        return;
      }
    });
}

async function initializeChatToken(store: ReturnType<typeof useChatStore>, appStore: ReturnType<typeof useAppStore>): Promise<boolean> {
  log.info('Getting chat token');
  const [refreshError, chatToken] = await withCatch(appStore.api.refreshChatToken());

  if (refreshError || !chatToken) {
    log.error('Failed to get chat token', refreshError);
    return false;
  }

  log.info('Got new chat token');
  store.authentication = chatToken;

  log.info('Getting Signed Cookie');
  const [cookieFetchError] = await withCatch(appStore.api.getSignedCookie(store.authentication.token));
  if (cookieFetchError) {
    log.error('Failed to get signed cookie', cookieFetchError);
    return false;
  }

  log.info('Got Signed Cookie');

  const { publish_key, subscribe_key, ttl } = store.authentication;
  store.tokenExpirationTime = new Date(Date.now() + ttl * 1000 - 10000);
  store.scheduleTokenRefresh();

  store.PUBLISH_KEY = publish_key;
  store.SUBSCRIBE_KEY = subscribe_key;

  return true;
}

async function initializeChat(store: ReturnType<typeof useChatStore>, userId: string): Promise<boolean> {
  log.info(`Initializing chat with user id`, userId);
  const linearRetryPolicy = LinearRetryPolicy({
    delay: 5000,
    maximumRetry: 10, //This cannot exceed more than 10 or Pubnub yells at you
  });
  const [chatInitError, chat] = await withCatch(
    Chat.init({
      publishKey: store.PUBLISH_KEY,
      subscribeKey: store.SUBSCRIBE_KEY,
      userId: `mytimein-user-${userId}`,
      storeUserActivityTimestamps: true,
      authKey: store.authentication.token,
      restore: true,
      listenToBrowserNetworkEvents: true,
      retryConfiguration: linearRetryPolicy,
      subscribeRequestTimeout: 55,
    })
  );

  if (chatInitError) {
    log.error('Failed to initialize chat', chatInitError);
    return false;
  }

  store.chat = chat as Chat;
  store.chatUserId = store.chat.currentUser.id;
  store.onlineUserIds.add(store.chatUserId);
  log.info('Chat Intialized');

  return true;
}

function initializeListeners(store: ReturnType<typeof useChatStore>): void {
  addStatusListener(store);
  addCustomReactionHandler(store);
}

function addStatusListener(store: ReturnType<typeof useChatStore>): void {
  const statusListener = (statusEvent: any) => {
    if (isErrorStatus(statusEvent)) {
      log.error('Status error', statusEvent);
    } else {
      log.debug('Status', statusEvent);
    }

    if (statusEvent.operation === 'PNUnsubscribeOperation') {
      log.info('Unsubscribed from known channel', statusEvent.affectedChannels);
    } else if (statusEvent.category === 'PNAccessDeniedCategory' || statusEvent.statusCode === 403) {
      handleAccessDenied(store, statusEvent);
    } else if (statusEvent.operation === 'PNTimeoutCategory') {
      // store.reinitializeChat();
      // TODO: this should auto restore with linearRetryPolicy that is set above
    }
  };

  if (!store.chat) {
    return;
  }

  //@ts-ignore this is an internal function and not in the types ignore this warning this is correct
  store.customStatusListenerFn = store.chat.addListener({ status: statusListener });
}

/**
 * Adds a custom event listener for message actions.
 *
 * @param store - The chat store instance.
 */
function addCustomReactionHandler(store: ReturnType<typeof useChatStore>): void {
  if (!store.chat) {
    return;
  }

  const messageActionCallback = (event: Pubnub.MessageActionEvent) => {
    // find the message in all our channels, somehow?
    // iterate over our channels
    store.knownChannels.forEach((channel) => {
      const messages = store.channelMessages.get(channel.id);
      if (!messages) {
        return;
      }
      const index = messages.findIndex((m) => m.timetoken === event.data.messageTimetoken);
      const message = messages[index];
      const rawMessage = store.rawMessages.get(event.data.messageTimetoken);
      if (!message) {
        return;
      }
      let actions: any = {};

      if (event.event === 'added' && event.data.type === 'edited') {
        // simply refresh the message and push it into the stack
        // @ts-ignore
        channel.getMessage(event.data.messageTimetoken).then((editedMessage) => {
          // @ts-ignore
          editedMessage.force_update = true;
          messages.splice(index, 1, wrapPubnubMessageToFriendlyObservable(editedMessage));
        });
        return;
      }
      if (event.event === 'added') {
        // @ts-ignore
        actions = rawMessage.assignAction(event.data);
      }
      if (event.event === 'removed') {
        console.log('got removal of an event', event.data);
        // @ts-ignore
        actions = rawMessage.filterAction(event.data);
      }
      actions = JSON.parse(JSON.stringify(actions));
      // parse over the first set of keys
      for (const type of Object.keys(actions)) {
        // console.log('examining type', type);
        for (const appliedAction of Object.keys(actions[type])) {
          // console.log('examining action key', appliedAction);
          if (actions[type][appliedAction].length === 0) {
            // console.log('should prune action key', actions[type][appliedAction]);
            delete actions[type][appliedAction];
          }
        }
      }

      // @ts-ignore
      const updatedMessage = rawMessage.clone({ actions });
      updatedMessage.force_update = true;
      if (index > -1) {
        messages.splice(index, 1, wrapPubnubMessageToFriendlyObservable(updatedMessage));
      } else {
        messages.push(wrapPubnubMessageToFriendlyObservable(updatedMessage));
      }
      store.rawMessages.set(updatedMessage.timetoken, updatedMessage);
      store.knownReactionsToMessages.set(updatedMessage.timetoken, updatedMessage.reactions);
    });
  };

  //@ts-ignore this is an internal function and not in the types ignore this warning this is correct
  store.customEventListenerFn = store.chat.sdk.addListener({
    messageAction: messageActionCallback,
  });
  return;

  // TODO: Do we still need this signal check?
  // store.customEventListenerFn = store.chat.listenForEvents<'custom'>({
  //   channel: MYTIMEIN_BROADCAST_CHANNEL,
  //   method: 'signal',
  //   type: 'custom',
  //   callback: (event: any) => handleCustomEvent(store, event),
  // });
}

function handleAccessDenied(store: ReturnType<typeof useChatStore>, statusEvent: any): void {
  if (statusEvent.category === 'PNAccessDeniedCategory') {
    const channels = statusEvent.errorData.payload.channels;
    console.log('Broken channels discovered, we cannot subscribe to these', channels);
  }

  if (statusEvent.errorData?.message === 'Token is expired.') {
    log.error('Access denied, token expired');
    store.refreshChatToken(true).catch((refreshError) => {
      log.error('Unkown error occured while refresshing chat token.');
      log.error(refreshError);
    });
  }
}

function sanitizeUserId(userId: string): string {
  return userId.replace('mytimein-user-', '');
}

function isErrorStatus(statusEvent: any): boolean {
  return ['PNUnknownCategory', 'PNBadRequestCategory', 'PNAccessDeniedCategory', 'PNTimeoutCategory', 'PNNetworkDownCategory'].includes(statusEvent.category);
}

function handleCustomEvent(store: ReturnType<typeof useChatStore>, event: any): void {
  const { status, userId, channelId, timetoken } = event.payload;
  switch (status) {
    case EVENT_USER_ONLINE:
      store.onlineUserIds.add(userId);
      store.idleUserIds.delete(userId);
      break;
    case EVENT_USER_OFFLINE:
      store.onlineUserIds.delete(userId);
      break;
    case EVENT_USER_IDLE:
      store.onlineUserIds.delete(userId);
      store.idleUserIds.add(userId);
      break;
    case EVENT_MESSAGE_DELETED:
      store.deleteMessageFromChannel(channelId, timetoken);
      break;
    case EVENT_USER_UPDATED:
      store.updateKnownUsers([userId]).catch(() => {
        log.error('Failed to update known users');
      });
      break;
  }
}
async function handleMemberships(store: ReturnType<typeof useChatStore>): Promise<boolean> {
  log.info('Handling Memberships');

  if (!store.chat) {
    throw 'Chat not initialized';
  }

  const [membershipsError, data] = await withCatch(store.chat.currentUser.getMemberships());
  const [unreadMessagesError, unreadMessagesInChannels] = await withCatch(store.chat.getUnreadMessagesCounts());

  if (unreadMessagesInChannels && !unreadMessagesError) {
    unreadMessagesInChannels.forEach((unreadInformation) => {
      if (store.channelAlerts.get(unreadInformation.channel.id) && store.channelAlerts.get(unreadInformation.channel.id)! > unreadInformation.count) {
        return;
      }
      store.channelAlerts.set(unreadInformation.channel.id, unreadInformation.count);
    });
  }

  if (membershipsError || !data) {
    log.warn('Failed to get memberships.', membershipsError);
    return false;
  }

  const channelsEnabled = getChannelsEnabledByACM(store);
  const memberships: Membership[] = data.memberships.filter((membershipAvailable) => {
    return channelsEnabled.includes(membershipAvailable.channel.id);
  });

  // populate last raed message timestamps
  memberships
    .filter((membership) => Boolean(membership.lastReadMessageTimetoken))
    .forEach((membership) => {
      store.lastReadTimestampPerChannel.set(membership.channel.id, parseInt(`${membership.lastReadMessageTimetoken}`));
    });

  const joins = [];

  for (const membership of memberships) {
    joins.push(joinChannel(store, membership.channel));
  }

  const [joinErrors] = await withCatch(Promise.all(joins));
  if (joinErrors) {
    log.error('Join Errors');
    log.error(joinErrors);
  }

  log.info('Memberships handled');

  return true;
}

/**
 * Ensures all channels are part of the channel order, otherwise, adds them to the channel order based on lastMessageRead
 *
 * @param store
 */
// @ts-ignore
async function validateChannelOrder(store: ReturnType<typeof useChatStore>): Promise<void> {
  // TODO: this is weird because all channels last read timestamps could be around the same time, so the "order" is not really reliable?
  // ChatService.getInstance().setChannelOrder(store.globalChannelOrder).then().catch();
}

/**
 * Get unread message counts from Pubnub. This is based on when messages were sent during offline periods,
 * and may conflict w/ unread notifications in our app (messages sent on channels we're not looking at)
 * We should probably take the higher of the two values when booting our app? Adding here for experimentation.
 *
 * @param store
 *
 * @ref https://www.pubnub.com/docs/chat/chat-sdk/build/features/messages/unread#get-unread-messages-count-all-channels
 */
async function getUnreadMessageCounts(store: ReturnType<typeof useChatStore>) {
  if (!store.chat) {
    throw 'Chat not initialized';
  }

  // TODO: this says it should support params like { page: { next } } in the docs, but i dont see that it does give us any information that we have *more* to read?
  const [getUnReadError, unreadChannels] = await withCatch(store.chat.getUnreadMessagesCounts());

  if (getUnReadError || !unreadChannels) {
    log.error('Failed to get unread message counts.');
    log.error(getUnReadError);
    log.error(getUnReadError.status);
    return;
  }

  for (const unreadInformation of unreadChannels) {
    // if we have channel alerts and that channel alert is < the unread information.count, then skip it. Otherwise, use unreadInformation.count
    if (store.channelAlerts.get(unreadInformation.channel.id) && store.channelAlerts.get(unreadInformation.channel.id)! > unreadInformation.count) {
      return;
    }
    store.channelAlerts.set(unreadInformation.channel.id, unreadInformation.count);
  }
}

async function joinChannel(store: ReturnType<typeof useChatStore>, channel: any): Promise<void> {
  log.info('Joining channel', channel.id);
  store.knownChannels.set(channel.id, channel);

  const [joinError] = await withCatch(store.connectToChannel(channel));

  if (joinError) {
    log.error('Failed to join channel.');
    log.error(joinError);
    return;
  }

  log.info('Joined channel: ', channel.id);
}
function postInitialization(store: ReturnType<typeof useChatStore>): void {
  store.listenForInviteEvents();
  store.streamUpdatesOnCurrentChannelList();
  store.restoreNotifications();
  store.broadcastOnlineNotice();
  getUnreadMessageCounts(store).catch((error) => {
    log.error('Failed to get unread message counts.', error);
  });
  resetRecoveryAttempts(store);
  restoreLastVisibleChannelAfterDelay(store);
  restoreMutedChannels(store);
  subscribeToSignalsOnDev(store);
}

function subscribeToSignalsOnDev(store: ReturnType<typeof useChatStore>): void {
  if (!store.chat) {
    return;
  }

  // store.chat.sdk.addListener({
  //   signal: function (event) {
  //     log.debug('Got Signal', event);
  //     // console.log('[DEBUG] GOT SIGNAL', event);
  //   },
  // });
}

function resetRecoveryAttempts(store: ReturnType<typeof useChatStore>): void {
  store.currentRecoveryAttempts = 0;
}
function restoreLastVisibleChannelAfterDelay(store: ReturnType<typeof useChatStore>): void {
  setTimeout(() => {
    store.restoreLastVisibleChannel();
  }, 2000);
}
function getChannelsEnabledByACM(store: ReturnType<typeof useChatStore>): string[] {
  if (!store.chat) {
    return [];
  }

  const channels = store.chat.sdk.parseToken(store.authentication.token)?.resources?.channels || {};
  if (Object.keys(channels).length === 0) {
    log.warn('Channels are empty! We probably should abort, but we will try to continue');
  }
  return Object.keys(channels);
}

function syncChannelToCache(store: ReturnType<typeof useChatStore>, id: string) {
  // TODO: we cant pass reactive objects here
  const channel = store.knownChannels.get(id);
  if (!channel) {
    return;
  }
  const channelMembers = store.channelParticipantsMinimal.get(id);
  const members = channelMembers ? Array.from(channelMembers) : [];
  const knownChannel: KnownChatChannel = {
    id: `${channel.id}`,
    name: `${channel.name}`,
    description: `${channel.description}`,
    custom: {
      cover: channel.custom?.cover as string,
      type: channel.custom?.type as string,
      team_id: channel.custom?.team_id as string,
      operators: channel.custom?.operators as string,
    },
    members: members,
  };
  ChatService.getInstance().addChannel(knownChannel).then().catch();
}

// @ts-ignore
function syncChannelsToCache(store: ReturnType<typeof useChatStore>) {
  const allChannelsToSync: KnownChatChannel[] = [];
  store.knownChannels.forEach((channel) => {
    const channelMembers = store.channelParticipantsMinimal.get(channel.id);
    const members = channelMembers ? Array.from(channelMembers) : [];
    allChannelsToSync.push({
      id: `${channel.id}`,
      name: `${channel.name}`,
      description: `${channel.description}`,
      custom: {
        cover: channel.custom?.cover as string,
        type: channel.custom?.type as string,
        team_id: channel.custom?.team_id as string,
        operators: channel.custom?.operators as string,
      },
      members: members,
    });
  });
  ChatService.getInstance().addChannels(allChannelsToSync).then().catch();
}

const restoreMutedChannels = (store: ReturnType<typeof useChatStore>) => {
  const mutedChannels = localStorage.getItem('chat-muted-channels');
  if (!mutedChannels) {
    return;
  }
  const parsed = JSON.parse(mutedChannels);
  store.mutedChannels = new Set(parsed);
};
