import AudioService from '@src/services/audio-service';
import { useActiveJobStore } from '@src/store/activejob';
import { useAlertStore } from '@src/store/alert';
import { useAppStore } from '@src/store/app';
import { useChatStore } from '@src/store/chat';
import { useGptStore } from '@src/store/gpt';
import { useOperationsStore } from '@src/store/operations';
import { useRuntimeConfigurationStore } from '@src/store/runtimeconfiguration';
import { useTimerStore } from '@src/store/timer';
import { useUserStore } from '@src/store/user';
import { getVersion } from '@tauri-apps/api/app';
import { invoke } from '@tauri-apps/api/core';
import { listen, UnlistenFn } from '@tauri-apps/api/event';
import { BaseDirectory, readFile, remove } from '@tauri-apps/plugin-fs';
import { isPermissionGranted, requestPermission, sendNotification } from '@tauri-apps/plugin-notification';
import { platform } from '@tauri-apps/plugin-os';
import { exit } from '@tauri-apps/plugin-process';
import { check, Update } from '@tauri-apps/plugin-updater';
import { withCatch } from '@utils/await-safe';
import { Logger } from '@utils/logger';
import { CronJob } from 'cron';
import { DateTime, Duration } from 'luxon';

const log = new Logger('SENTINEL');
const BREAK_DURATION_MINUTES = 60;

export class Sentinel {
  activeJobStore: ReturnType<typeof useActiveJobStore>;
  runtimeConfigurationStore: ReturnType<typeof useRuntimeConfigurationStore>;
  appStateStore: ReturnType<typeof useAppStore>;
  timerStore: ReturnType<typeof useTimerStore>;
  ongoingOperationsStore: ReturnType<typeof useOperationsStore>;
  alertStore: ReturnType<typeof useAlertStore>;
  userStore: ReturnType<typeof useUserStore>;
  chatStore: ReturnType<typeof useChatStore>;
  gptStore: ReturnType<typeof useGptStore>;
  update: Update | null;
  previousIdleState: Boolean;

  listeners: UnlistenFn[];
  sentryInstance: null;
  failedUploads: number[];
  frequency: number;
  jobs: CronJob[];

  BREAK = 'Break' as string;
  OFF_THE_CLOCK = 'Off The Clock' as string;
  YET_TO_START = 'Yet to start' as string;
  LATE = 'Late' as string;
  LATE_ACTIVE = 'Late active' as string;
  ABSENT = 'Absent: no activity' as string;
  ACTIVE = 'Active' as string;
  REST_DAY = 'Rest day' as string;
  SYSTEM_LOGGED_OUT = 'System logged out' as string;
  PTO = 'PTO' as string;
  PTO_MOD = 'PTO (mod)' as string;
  UPTO_MOD = 'Upto (mod)' as string;
  UNDERTIME = 'Undertime' as string;

  constructor() {
    // get all the stores it needs
    this.activeJobStore = useActiveJobStore();
    this.runtimeConfigurationStore = useRuntimeConfigurationStore();
    this.timerStore = useTimerStore();
    this.ongoingOperationsStore = useOperationsStore();
    this.appStateStore = useAppStore();
    this.alertStore = useAlertStore();
    this.userStore = useUserStore();
    this.chatStore = useChatStore();
    this.gptStore = useGptStore();
    this.previousIdleState = false;
    this.update = null;

    this.listeners = [];
    this.failedUploads = [];
    this.sentryInstance = null;
    this.jobs = [];
    this.frequency = this.getRandomFrequency();
    this.prepare();
  }

  prepare = () => {
    log.info('Preparing Sentinel');
    log.info('Resetting State');

    invoke('reset_state').catch((error) => {
      log.error('Failed to reset state');
      log.error(error);
    });

    log.info('State Reset');
    log.info('Unregistering Listeners');

    this.unregisterListeners();

    log.info('Listeners Unregistered');
    log.info('Registering Listeners');

    this.registerListeners().catch((error) => {
      log.error('Failed to register listeners');
      log.error(error);
    });

    log.info('Listeners Registered');
    log.info('Gathering Environment Information');

    this.gatherEnvironmentInformation().catch((error) => {
      log.error('Failed to gather environment information');
      log.error(error);
    });

    log.info('Environment Information Gathered');
    log.info('Starting Monitoring Loop');

    this.startMonitoringLoop();

    log.info('Monitoring Loop Started');
    log.info('Loading Runtime Configuration');

    this.runtimeConfigurationStore.boot().then(() => {
      this.appStateStore.boot().then(() => {});
    });

    log.info('Runtime Configuration Loaded');

    log.warn('Game list and Excluded Process List are not implemented');
    //TODO Implement these
    //this.loadGameList();
    //this.loadExcludedProcessList();

    log.info('Sentinel Booted');
  };

  reset = async () => {
    this.activeJobStore.$reset();
    this.runtimeConfigurationStore.$reset();
    this.timerStore.$reset();
    this.ongoingOperationsStore.$reset();
    this.appStateStore.$reset();
    this.alertStore.$reset();
    this.chatStore.$reset();
    this.gptStore.$reset();
    this.userStore.$reset();

    const [runtimeConfigError] = await withCatch(this.runtimeConfigurationStore.boot());

    if (runtimeConfigError) {
      log.error('FATAL: Failed to reboot runtime configuration store');
      log.error(runtimeConfigError);
      throw 'FATAL: Failed to reboot runtime configuration store';
    }

    const [appStateError] = await withCatch(this.appStateStore.boot());

    if (appStateError) {
      log.error('FATAL: Failed to reboot app store');
      log.error(appStateError);
      throw 'FATAL: Failed to reboot app store';
    }
  };

  getRandomFrequency = () => {
    return Math.random() * (6 - 5) + 5;
  };

  gatherEnvironmentInformation = async () => {
    this.runtimeConfigurationStore.platform = platform();
    const [getVersionError, version] = await withCatch(getVersion());

    if (getVersionError || !version) {
      log.error('Failed to get version');
      log.error(getVersionError);
      throw 'Failed to get version';
    }

    this.runtimeConfigurationStore.version = version;

    const [getSysInfoError, systemInfo] = await withCatch(this.getSystemInformation());
    if (getSysInfoError) {
      log.error('Failed to get system information');
      log.error(getSysInfoError);
      return false;
    }

    if (typeof systemInfo === 'undefined') {
      log.error('System information is undefined');
      return false;
    }

    this.runtimeConfigurationStore.currentMachine = systemInfo;
    return true;
  };

  reportMachineInformation = async () => {
    if (!this.runtimeConfigurationStore.currentMachine) {
      log.info('No machine information to report');
      return false;
    }

    log.info('Uploading system information');
    const [uploadError] = await withCatch(this.appStateStore.api.uploadSystemInformation(this.runtimeConfigurationStore.currentMachine));

    if (uploadError) {
      log.error('Failed to upload system information');
      log.error(uploadError);
      return false;
    }

    log.info('System information uploaded.');
    log.info('Getting last machine from server');

    const [lastMachineError, response] = await withCatch(this.appStateStore.api.getLastMachine());

    if (lastMachineError || !response) {
      log.error('Failed to get last machine');
      log.error(lastMachineError);

      if (lastMachineError.code === 404 && this.runtimeConfigurationStore.currentMachine) {
        const [mainComputerError] = await withCatch(this.appStateStore.api.setMainComputer(this.runtimeConfigurationStore.currentMachine.system_uuid));
        if (mainComputerError) {
          log.error('Failed set main computer in error handler');
          log.error(mainComputerError);
          return false;
        }

        log.error('Main computer set in error handler');
        return true;
      }

      return false;
    }

    log.info('Last machine fetched');

    const lastMachine = response;

    if (!lastMachine) {
      log.info('No last machine found. Setting new last machine.');
      const [mainComputerError] = await withCatch(this.appStateStore.api.setMainComputer(this.runtimeConfigurationStore.currentMachine.system_uuid));

      if (mainComputerError) {
        log.error('Failed to set main computer');
        log.error(mainComputerError);
        return false;
      }

      log.info('Main computer set.');
      return true;
    }
    // @ts-ignore
    //current computer did not match the one form the server
    if (lastMachine.is_primary === 1 && this.runtimeConfigurationStore.currentMachine.system_uuid !== lastMachine.uuid) {
      log.info('Current machine is not the primary machine');
      this.runtimeConfigurationStore.primaryMachine = false;
    }
    return true;
  };

  getSystemInformation = async (): Promise<ComputerSpecs> => {
    const info: ComputerSpecs = await invoke('get_system_info');
    info.version = this.runtimeConfigurationStore.version;
    return info;
  };

  registerListeners = async () => {
    const mtiEventListener = await listen('mti_event', (event: { payload: MtiEvent }) => {
      this.handleMtiEvent(event.payload);
    });
    this.listeners.push(mtiEventListener);
  };

  unregisterListeners = () => {
    this.listeners.forEach((dropListener) => dropListener());
  };

  handleMtiEvent(payload: MtiEvent): void {
    log.debug(payload);
    if (payload.name === 'machine_locked' || payload.name === 'machine_unlocked') {
      this.handleScreenLockedEvent(payload);
    }
    if (payload.name === 'machine_sleep' || payload.name === 'machine_wake') {
      this.handleMachineSleepEvent(payload);
    }
    if (payload.name === 'activity_check_complete') {
      this.handleActivityCheckComplete(payload);
    }
    if (payload.name === 'close_app') {
      this.handleCloseRequestEvent(payload).catch((error: any) => {
        log.error('failed to handle close request');
        log.error(error);
      });
    }
  }

  handleUserIdleEvent = async (event: any) => {
    if (!this.activeJobStore.activeJob || !this.activeJobStore.activeJob.saved) {
      log.debug('No active job to report idle event');
      return false;
    }
    if (this.activeJobStore.additionalDetails.onBreak || this.activeJobStore.additionalDetails.beRightBack) {
      log.debug('User is on break or be right back');
      return false;
    }

    const metaKey = event.payload.isIdle ? 'idle_start' : 'idle_end';
    const meta: Record<string, any> = {};
    meta[metaKey] = DateTime.now().toISO();

    const trackerEvent: TrackerEvent = {
      eventType: event.payload.isIdle ? 'user_idle_start' : 'user_idle_end',
      meta,
    };

    log.debug('Reporting user idle event to MTI');
    const [uploadError] = await withCatch(this.appStateStore.api.reportEvent(trackerEvent));
    if (uploadError) {
      log.error('Failed to repoirt user idle event');
      log.error(uploadError);
      return false;
    }
    log.debug('Idle event reported to MTI');

    return true;
  };

  handleCloseRequestEvent = async (data: MtiEvent) => {
    const promises = [];
    promises.push(this.chatStore.disconnect());

    if (this.activeJobStore.activeJob && !this.activeJobStore.activeJob.saved) {
      const trackerEvent: TrackerEvent = {
        eventType: 'tracker_closed',
        meta: { data },
      };
      promises.push(this.appStateStore.api.reportEvent(trackerEvent));
    }

    Promise.all(promises)
      .catch((error) => {
        log.error('Failed to report close event - Closing App');
        log.error(error);
      })
      .finally(async () => {
        await exit(0);
      });
  };

  handleScreenLockedEvent = (event: MtiEvent) => {
    // Check if the machine is unlocked or has been locked for an extended period.
    // If either condition is true, broadcast an 'online' notice.
    if (!event.data.locked || this.timerStore.machineLockedTooLong) {
      this.timerStore.clearMachineLockedTime();
      this.chatStore.broadcastOnlineNotice();
    } else {
      this.timerStore.setMachineLockedTime();
      this.chatStore.broadcastIdleNotice();
    }

    // Exit if there is no active job or if the active job has already been saved.
    if (!this.activeJobStore.activeJob || this.activeJobStore.activeJob.saved) {
      return;
    }

    // Exit if the user is on a break or has marked themselves as 'be right back.'
    if (this.activeJobStore.additionalDetails.onBreak || this.activeJobStore.additionalDetails.beRightBack) {
      return;
    }

    // Create a tracker event to log the machine's lock or unlock status,
    // attaching the event data as metadata.
    const trackerEvent: TrackerEvent = {
      eventType: event.data.locked ? 'machine_locked' : 'machine_unlocked',
      meta: {
        event,
      },
    };

    // Report the tracker event to the API, logging an error if the request fails.
    this.appStateStore.api
      .reportEvent(trackerEvent)
      .then(() => {
        log.info('Lock event reported to MTI');
      })
      .catch((error) => {
        log.error('Failed to report lock event');
        log.error(error);
      });
  };

  handleActivityCheckComplete = (event: MtiEvent) => {
    const trackerEvent: TrackerEvent = {
      eventType: 'activity_check',
      meta: {
        event,
      },
    };

    this.appStateStore.api
      .reportEvent(trackerEvent)
      .then(() => {
        const msg = event.data.activityCheckPass ? 'Activity check finished. User completed check.' : 'Activity check finished. User failed check.';
        log.info(msg);
      })
      .catch((error) => {
        log.error('Failed to report activity check completion');
        log.error(error);
      });
  };

  handleMachineSleepEvent = (event: MtiEvent) => {
    if (event.data.sleep) {
      //write timestamp to local storage so we can check this value when we hit the dash
      localStorage.setItem('machineSleep', new Date().toISOString());

      this.chatStore.disconnect();
      // stop all our timers for now
      this.jobs.forEach((job) => {
        job.stop();
      });
    } else {
      return;
    }

    if (!this.activeJobStore.activeJob || !this.activeJobStore.activeJob.saved) {
      return;
    }
    if (this.activeJobStore.additionalDetails.onBreak || this.activeJobStore.additionalDetails.beRightBack) {
      return;
    }

    const trackerEvent: TrackerEvent = {
      eventType: event.data.sleep ? 'machine_sleep' : 'machine_wake',
      meta: {
        event,
      },
    };

    this.appStateStore.api
      .reportEvent(trackerEvent)
      .then(() => {})
      .catch((error) => {
        log.error('Failed to report sleep event');
        log.error(error);
      });
  };

  processTick = () => {
    this.shiftStartingSoon().catch((error) => {
      log.error('Failed to check if shift is starting soon');
      log.error(error);
    });
    this.checkIfUserIsLate();
    this.shiftHasEnded();
    this.checkIfOverBreak();
    this.checkIfMidShift();
    this.runTrackerFeatures();
  };

  whisper = () => {
    this.activeJobStore
      .refresh()
      .then(() => {
        log.info('whisper sent');
      })
      .catch((error) => {
        log.error('Failed to send whisper');
        log.error(error);
      });
  };

  checkUserActivity = () => {
    log.info("Checking user's activity");
    if (!this.activeJobStore.additionalDetails.inMeeting) {
      const timeToAlert = this.activeJobStore.alertManager.isTimeToAlert();
      if (timeToAlert) {
        invoke('activity_check')
          .then(() => {
            log.info('Activity check invoked');
          })
          .catch((error) => {
            log.error('Failed to invoke activity_check');
            log.error(error);
          });
      }
    } else {
      log.info('In meeting skipping activity check');
    }
  };

  runTrackerFeatures = () => {
    if (!this.activeJobStore.activeJob || !this.activeJobStore.activeJob.trackerEnabled || !this.activeJobStore.isClockedIn) {
      return;
    }

    this.checkIfInMeeting().catch((error) => {
      log.error('Failed to check if in meeting');
      log.error(error);
    });

    this.checkUserActivity();

    this.checkIdleState().catch((error) => {
      log.error('Failed to check idle state');
      log.error(error);
    });

    this.processUploadQueue().catch((processQueueError) => {
      log.error('Failed to process upload queue');
      log.error(processQueueError);
    });

    const currentTime = DateTime.now();

    if (this.timerStore.nextCaptureTime === null) {
      this.updateNextCaptureTime();

      return;
    }

    if (currentTime > this.timerStore.nextCaptureTime) {
      this.updateNextCaptureTime();
      this.callHome().catch((error) => {
        log.error('Failed to call home');
        log.error(error);
      });
      this.captureScreens().catch((error) => {
        log.error('Failed to capture screens');
        log.error(error);
      });
      this.captureProcesses().catch((error) => {
        log.error('Failed to capture processes');
        log.error(error);
      });
      this.captureApplicationActivity().catch((error) => {
        log.error('Failed to capture application activity');
        log.error(error);
      });
      this.captureWebsites().catch((error) => {
        log.error('Failed to capture websites');
        log.error(error);
      });
      this.captureMouseData().catch((error) => {
        log.error('Failed to capture mouse data');
        log.error(error);
      });
    }
  };

  captureWebsites = async () => {
    if (!this.activeJobStore.shouldTrackWebsites) return false;

    log.debug('Getting browser history');
    const [browserHistoryError, websites] = await withCatch(invoke<Website[]>('get_browser_history'));

    if (browserHistoryError || !websites) {
      log.error('Failed to get browser history.');
      log.error(browserHistoryError);
      return false;
    }

    log.debug('Got browser history. Uploading...');

    const payload = {
      job_id: this.activeJobStore.activeJob?.jobId ?? -1,
      websites,
    };

    this.ongoingOperationsStore.uploadingWebsites = true;
    const [uploadError] = await withCatch(this.appStateStore.api.uploadWebsites(payload));

    if (uploadError) {
      log.error('Failed to upload websites');
      log.error(uploadError);
      this.ongoingOperationsStore.uploadingWebsites = false;
      return false;
    }

    log.debug('Browser history uploaded.');

    this.ongoingOperationsStore.uploadingWebsites = false;
    return true;
  };

  dockBounce = () => {
    log.debug('Will bounce dock icon in 2 seconds. If window not in focus.');
    setTimeout(async () => {
      log.debug('Bouncing dock icon.');
      const [bounceError] = await withCatch(invoke('bounce_dock_icon'));

      if (bounceError) {
        log.error('Failed to bounce dock via');
        log.error(bounceError);
      }

      log.debug('Dock icon bounced.');
    }, 2000);
  };

  captureProcesses = async (force = false) => {
    if (!this.activeJobStore.shouldTrackProcesses && !force) {
      log.debug('Process Tracking off. Skipping capture.');
      return false;
    }
    const [error, processes] = await withCatch(invoke<Process[]>('get_running_processes'));
    if (error || !processes) {
      log.error('Failed to capture processes');
      log.error(error);
      return false;
    }

    const filteredProcesses = processes.filter((process: Process) => {
      if (this.isProcessGame(process)) {
        process.category = 'game';
      }
      return !this.isProcessExcluded(process);
    });
    return this.uploadProcesses(filteredProcesses);
  };

  uploadProcesses = async (processes: Process[]) => {
    this.ongoingOperationsStore.uploadingProcesses = true;
    const payload = {
      job_id: this.activeJobStore.activeJob?.jobId ?? -1,
      processes: processes,
    };
    log.debug('Uploading processes');
    const [error] = await withCatch(this.appStateStore.api.uploadProcesses(payload));
    if (error) {
      log.error('Failed to upload processes');
      log.error(error);
      this.ongoingOperationsStore.uploadingProcesses = false;
      return false;
    }
    log.debug('Processes uploaded');
    this.ongoingOperationsStore.uploadingProcesses = false;
    return true;
  };

  captureApplicationActivity = async (force = false) => {
    if (!this.activeJobStore.shouldTrackApplications && !force) {
      return false;
    }

    const [activityError, applications] = await withCatch(invoke<Application[]>('get_activity'));

    if (activityError || !applications) {
      log.error('Failed to capture application activity');
      log.error(activityError);
      return false;
    }

    const applicationsWithUsage = this.calculateTotalApplicationUsage(applications);
    return this.uploadApplicationActivity(applicationsWithUsage);
  };

  uploadApplicationActivity = async (applications: Application[]) => {
    this.ongoingOperationsStore.uploadingApplicationActivity = true;
    const payload = {
      job_id: this.activeJobStore.activeJob?.jobId ?? -1,
      applications: applications,
      icons: [],
    };
    const [uploadError] = await withCatch(this.appStateStore.api.uploadApplications(payload));

    if (uploadError) {
      log.error('Failed to upload application activity');
      log.error(uploadError);
      this.ongoingOperationsStore.uploadingApplicationActivity = false;
      return false;
    }

    log.debug('Application activity uploaded');
    this.ongoingOperationsStore.uploadingApplicationActivity = false;
    return true;
  };

  calculateTotalApplicationUsage = (applications: Application[]): Application[] => {
    return applications.map((app) => this.calculateTimePerWindow(app));
  };

  calculateTimePerWindow = (app: Application): Application => {
    app.windows = app.windows.map((window) => {
      const tempMilliseconds = this.calculateWindowDuration(window.captureTimes);
      window.seconds = tempMilliseconds / 1000;
      return window;
    });
    return app;
  };

  calculateWindowDuration = (timeSeries: CaptureTime[]): number => {
    let sum = Duration.fromMillis(0);
    for (const { start, end } of timeSeries) {
      const startDateTime = DateTime.fromMillis(start);
      const endDateTime = end === 0 ? DateTime.utc() : DateTime.fromMillis(end);
      const duration = endDateTime.diff(startDateTime);
      sum = sum.plus(duration);
    }
    return sum.valueOf();
  };

  isProcessExcluded = (process: Process) => {
    return this.appStateStore.excludedProcessList.some((p: string) => p.toLowerCase() === process.path.toLowerCase());
  };

  isProcessGame = (process: Process) => {
    return this.appStateStore.gameList.some((game: GameMedia) => {
      return (game?.executables ?? []).some((executable: Executable) => {
        return process.path.toLowerCase().includes(executable.name.toLowerCase());
      });
    });
  };

  captureScreens = async () => {
    if (!this.activeJobStore.activeJob || !this.activeJobStore.activeJob.trackerEnabled || this.activeJobStore.additionalDetails.onBreak || this.activeJobStore.additionalDetails.beRightBack) {
      log.info('No screenshots needed.');
      return;
    }

    log.debug('Capturing screen data.');

    const payload = {
      trackScreenshots: this.activeJobStore.activeJob.trackScreenshots,
      screenshotsTaken: this.activeJobStore.additionalDetails.screenshotsTaken,
      osRelease: this.runtimeConfigurationStore.currentMachine?.os_release,
      OSSysArch: this.runtimeConfigurationStore.currentMachine?.os_platform,
      inMeeting: this.activeJobStore.additionalDetails.inMeeting,
    };

    const [error] = await withCatch(invoke('capture_screenshot', payload));

    if (error) {
      log.error('Failed to capture screenshots');
      log.error(error);
      return;
    }

    log.debug('Screenshots captured');
  };

  captureScreensAsync = async () => {
    if (!this.activeJobStore.activeJob) {
      log.debug('No active job found. Skipping screenshot capture.');
      return false;
    }

    const payload = {
      trackScreenshots: this.activeJobStore.activeJob.trackScreenshots,
      screenshotsTaken: this.activeJobStore.additionalDetails.screenshotsTaken,
      osRelease: this.runtimeConfigurationStore.currentMachine?.os_release,
      OSSysArch: this.runtimeConfigurationStore.currentMachine?.os_platform,
      inMeeting: this.activeJobStore.additionalDetails.inMeeting,
    };

    log.debug('Capturing screen data.');

    const [error] = await withCatch(invoke('capture_screenshot', payload));

    if (error) {
      log.error('Failed to capture screenshots');
      log.error(error);
      return false;
    }
    log.debug('Screenshots captured');

    return this.processUploadQueue();
  };

  captureMouseData = async () => {
    log.debug('Getting mouse data');
    const [mouseError, data] = await withCatch(invoke<Mouse>('get_mouse_position'));
    if (mouseError || !data) {
      log.error('Failed to invoke input data');
      log.error(mouseError);
      return false;
    }

    log.debug('Mouse data captured');

    const payload = {
      mouse: data,
    };

    log.debug('Uploading input data');
    const [inputErrorEvent] = await withCatch(this.appStateStore.api.uploadInputData(payload));

    if (inputErrorEvent) {
      log.error('Failed to send input data');
      log.error(inputErrorEvent);
      return false;
    }

    log.info('Input data processed');
    return true;
  };

  updateNextCaptureTime = () => {
    this.timerStore.updateCaptureTime(this.frequency);
  };

  callHome = async (): Promise<void> => {
    const [error] = await withCatch(this.appStateStore.api.callHome());
    if (error) {
      log.error('Failed to call home');
      log.error(error);
    }
  };

  checkIdleState = async () => {
    log.debug('Checking idle time');
    const [idleStatusError, idle] = await withCatch(invoke<{ isIdle: boolean; idleTime: number; shouldReport: boolean }>('check_if_user_idle'));
    if (idleStatusError || !idle) {
      log.error('Failed to check idle state');
      log.error(idleStatusError);
      return;
    }

    log.debug('Fetched idle time');
    log.debug(idle);

    if (idle.shouldReport) {
      await this.handleUserIdleEvent({ payload: idle });
    }

    // if the state of being idle changed, we need to update this
    if (idle.isIdle !== this.previousIdleState) {
      if (idle.isIdle) {
        this.chatStore.broadcastIdleNotice();
      } else {
        this.chatStore.broadcastOnlineNotice();
      }
      this.previousIdleState = idle.isIdle;
    }
  };

  processUploadQueue = async () => {
    log.debug('Processing upload queue');
    const [error, data] = await withCatch(invoke<string>('get_capture_group'));
    if (error || !data) {
      log.error('Failed to get capture group');
      log.error(error);
      return false;
    }

    log.debug('Got Capture Group');
    log.debug(data);
    const captureGroup = JSON.parse(data);

    const [uploadError] = await withCatch(this.serverlessUpload(captureGroup));

    if (uploadError) {
      log.error('Failed to upload screenshots');
      log.error(uploadError);
      return false;
    }

    log.debug('Serverless Upload Complete');

    return true;
  };

  takeScreenshotsAndUploadImmediately = () => {
    return this.captureScreensAsync();
  };

  shiftHasEnded = () => {
    if (!this.activeJobStore.activeJob || !this.activeJobStore.activeJob.timeToUnix) {
      return;
    }
    const shiftEndTime = DateTime.fromSeconds(this.activeJobStore.activeJob.timeToUnix);
    const timeTillShiftEnds = shiftEndTime.diffNow(['minutes']);

    if (DateTime.now() > shiftEndTime && timeTillShiftEnds.minutes >= this.appStateStore.settings.shiftAfterEndReminderTime) {
      this.playAlarm().catch((error: unknown) => {
        log.error("Couldn't play alarm");
        log.error(error);
      });
      this.sendDynamicNotification('Shift has ended', 'Your shift has ended. Please remember to clock out and have a GREAT day!').catch((error: unknown) => {
        log.error('Failed to send notification');
        log.error(error);
      });
    }
  };

  checkIfMidShift = () => {
    if (!this.activeJobStore.activeJob || this.activeJobStore.activeJob.isBreak || this.timerStore.playedHalfwayThroughShiftReminder || this.activeJobStore.activeJob.saved) {
      return;
    }
    if (![this.YET_TO_START, this.LATE].includes(this.activeJobStore.activeJob.decoratedStatus)) {
      return;
    }
    const shiftEndTime = DateTime.fromSeconds(this.activeJobStore.activeJob.timeToUnix, { zone: 'UTC' });
    const shiftStartTime = DateTime.fromSeconds(this.activeJobStore.activeJob.timeFromUnix, { zone: 'UTC' });
    const shiftDuration = shiftEndTime.diff(shiftStartTime, ['minutes']).minutes;

    const now = DateTime.utc();
    const halfwayMark = shiftStartTime.plus({ minutes: shiftDuration / 2 });

    if (now <= halfwayMark) {
      this.sendDynamicNotification('Halfway There!', 'You are halfway through your shift. Please remember to clock back in.').catch((error: unknown) => {
        log.error('Failed to send notification');
        log.error(error);
      });
      this.timerStore.playedHalfwayThroughShiftReminder = true;
    }
  };

  updateTimers = () => {
    this.timerStore.localTime = DateTime.now();
    // check if there is a client timezone available in the active job store
    if (this.activeJobStore.activeJob && this.activeJobStore.activeJob.clientTimezone) {
      // @ts-ignore
      this.timerStore.clientTime = DateTime.now().setZone(this.activeJobStore.activeJob.clientTimezone);
    }

    this.timerStore.utcTime = DateTime.utc();
    //@ts-ignore
    this.timerStore.manilaTime = DateTime.local().setZone('Asia/Manila');
  };

  startMonitoringLoop = () => {
    this.jobs.forEach((job) => {
      job.stop();
    });
    this.jobs = [];

    // Schedule Timers (every second)
    this.jobs.push(
      new CronJob(
        '* * * * * *',
        () => {
          this.refreshTokens();
        },
        null,
        true
      )
    );

    // Schedule regular process tick (every minute)
    this.jobs.push(
      new CronJob(
        '0 * * * * *',
        () => {
          this.updateTimers();
          this.processTick();
        },
        null,
        true
      )
    );

    // Schedule a whisper (10 minutes)
    this.jobs.push(
      new CronJob(
        '0 */10 * * * *',
        () => {
          this.whisper();
        },
        null,
        true
      )
    );

    // sync NTP time from Google
    this.jobs.push(
      new CronJob(
        '0 0/10 * * * *',
        () => {
          this.syncUtcTime().catch((error) => {
            log.error('Failed to sync time');
            log.error(error);
          });
        },
        null,
        true
      )
    );
    this.checkForApplicationUpdates().catch((error) => {
      log.error('Failed to check for application updates');
      log.error(error);
    });

    // get announcements & check for updates every hour
    this.jobs.push(
      new CronJob(
        '0 0 * * * *',
        () => {
          this.appStateStore.getAnnouncements();
          this.checkForApplicationUpdates().catch((error) => {
            log.error('Failed to check for application updates');
            log.error(error);
          });
        },
        null,
        true
      )
    );
  };

  refreshTokens = () => {
    if (this.appStateStore.accessTokenExpired && !this.appStateStore.refreshingAccessToken) {
      this.appStateStore.api.refreshTokens().catch((refreshError) => {
        log.error('Failed to refresh access token');
        log.error(refreshError);
        window.location.href = `${window.location.pathname}?timestamp=${new Date().getTime()}`;
      });
    }
  };

  syncUtcTime = async () => {
    log.debug('Syncing UTC time from time.google.com');
    const [timeError, result] = await withCatch(invoke<ClockInformation>('get_utc_time'));

    if (timeError || !result) {
      log.error('Failed to sync time');
      log.error(timeError);
      return false;
    }

    this.timerStore.utcTime = DateTime.fromSeconds(result.utc);
    log.info(`Synced UTC time from time.google.com`);

    //Do this for our own sanity
    const minutes = 2;
    const seconds = 60;
    const microseconds = 1000000;

    if (result.offset > minutes * seconds * microseconds) {
      log.info('Clock drift detected');
      this.alertStore.addClockDriftAlert();
    } else {
      this.alertStore.removeAlert(this.alertStore.alerts.findIndex((alert) => alert.includes('Clock drift detected')));
    }
    return true;
  };

  checkForApplicationUpdates = async () => {
    const [checkUpdateError, update] = await withCatch(check());
    if (checkUpdateError) {
      this.runtimeConfigurationStore.newerVersionAvailable = false;
      log.error('Failed to check for updates');
      log.error(checkUpdateError);
      return;
    }

    if (!update) {
      this.runtimeConfigurationStore.newerVersionAvailable = false;
      log.info('No updates available');
      return;
    }

    log.info('Update available');

    const [downloadError] = await withCatch(update.download());

    if (downloadError) {
      this.runtimeConfigurationStore.newerVersionAvailable = false;
      log.error('Failed to download update');
      log.error(downloadError);
      return;
    }

    this.update = update;
    // mark update as ready to install until they click the update button
    this.runtimeConfigurationStore.newerVersionAvailable = true;
    log.info('Downloaded update');
  };

  shiftStartingSoon = async () => {
    if (!this.activeJobStore.activeJob || this.activeJobStore.activeJob.decoratedStatus !== this.YET_TO_START) {
      return;
    }
    const shiftStartTime = DateTime.fromSeconds(this.activeJobStore.activeJob.timeFromUnix);
    const timeTillShiftStarts = shiftStartTime.diffNow(['minutes']);

    if (timeTillShiftStarts.minutes <= 1) {
      return;
    }

    if (timeTillShiftStarts.minutes <= this.appStateStore.settings.shiftReminderTime) {
      this.sendShiftStartingSoonNotification(Math.round(timeTillShiftStarts.minutes));
    }
  };

  checkIfUserIsLate = () => {
    if (!this.activeJobStore.activeJob || ![this.YET_TO_START, this.LATE].includes(this.activeJobStore.activeJob.decoratedStatus) || this.activeJobStore.additionalDetails.clockingIn) {
      return;
    }
    if (this.activeJobStore.activeJob.timeFromUnix - DateTime.now().toSeconds() < 0) {
      this.chatStore.composerFocusStealOverride = true;
      this.activeJobStore.additionalDetails.clockingIn = true;
      this.playAlarm().catch((error: unknown) => {
        log.error('Failed to play alarm');
        log.error(error);
      });
      this.sendDynamicNotification('Clock In Reminder', 'Please remember to clock in.').catch((error: unknown) => {
        log.error('Failed to send clock in reminder');
        log.error(error);
      });
    }
  };

  checkIfOverBreak = () => {
    if (!this.activeJobStore.activeJob || !this.timerStore.breakStartedAt || !this.activeJobStore.activeJob.isBreak) {
      log.info('Not over break returning.');
      return false;
    }

    const now = DateTime.now();
    const currentBreakDuration = now.diff(this.timerStore.breakStartedAt, ['minutes']).minutes;

    log.warn(this.timerStore.playedBreakReminder);

    if (currentBreakDuration >= BREAK_DURATION_MINUTES && !this.timerStore.playedBreakReminder) {
      this.timerStore.playedBreakReminder = true;
      this.playAlarm().catch((error) => {
        log.error('Failed to play alarm');
        log.error(error);
      });
      this.sendDynamicNotification('Break has ended', 'Please remember to clock back in.').catch((error: unknown) => {
        log.error('Failed to send notification');
        log.error(error);
      });
      return true;
    } else {
      log.info('Over break but alarm already played');
    }
    return true;
  };

  playAlarm = async () => {
    if (!this.activeJobStore.activeJob) {
      return;
    }

    const focusPromise = invoke('focus');
    const playWarningSoundPromise = AudioService.getInstance().playWarningSound();

    await Promise.all([focusPromise, playWarningSoundPromise]);
  };

  sendShiftStartingSoonNotification = (minutesUntilStart: number) => {
    if (!this.activeJobStore.activeJob || this.timerStore.playedStartingShiftReminder) {
      return;
    }

    const body = `You're scheduled to begin your shift with ${this.activeJobStore.activeJob.clientName} in about ${minutesUntilStart} minutes.`;
    this.sendDynamicNotification('Shift starting soon', body).catch((error: unknown) => {
      log.error('Failed to send notification');
      log.error(error);
    });
    AudioService.getInstance()
      .playBoopSound()
      .catch((error: unknown) => {
        log.error('Failed to play alarm');
        log.error(error);
      });
    this.timerStore.playedStartingShiftReminder = true;
  };

  sendDynamicNotification = async (title: string, body: string) => {
    // Do you have permission to send a notification?
    let permissionGranted = await isPermissionGranted();

    // If not we need to request it
    if (!permissionGranted) {
      const permission = await requestPermission();
      permissionGranted = permission === 'granted';
    }

    const notificationParams = {
      title,
      body,
      sound: this.runtimeConfigurationStore.platform === 'darwin' ? 'Blow' : 'Default',
    };

    // Once permission has been granted we can send the notification
    if (permissionGranted) {
      sendNotification(notificationParams);
      log.debug('Notification Sent');
      return true;
    }
    return false;
  };

  forcePanic = async () => {
    invoke('test_panic').catch();
  };

  createActivityCheckWindow = () => {
    invoke('activity_check').catch();
  };

  checkIfInMeeting = async () => {
    if ((this.timerStore.nextMeetingCheckAt && DateTime.now() < this.timerStore.nextMeetingCheckAt) || this.ongoingOperationsStore.performingMeetingCheck) {
      log.info('Meeting check skipped - to soon or ongoing meeting check');
      return false;
    }

    this.ongoingOperationsStore.performingMeetingCheck = true;

    log.info('checking if in meeting');
    const [checkMeetingError, response] = await withCatch(invoke('check_in_meeting'));

    if (checkMeetingError) {
      log.error('Failed to check if in meeting');
      log.error(checkMeetingError);
      this.activeJobStore.additionalDetails.inMeeting = true;
      this.ongoingOperationsStore.performingMeetingCheck = false;
      this.timerStore.nextMeetingCheckAt = DateTime.now().plus({
        minutes: 1,
      });
      return false;
    }

    log.info('Fetched meeting status');

    if (response === 1) {
      log.info('In meeting');
      this.activeJobStore.additionalDetails.inMeeting = true;
    } else {
      log.info('Not in meeting');
      this.activeJobStore.additionalDetails.inMeeting = false;
    }

    const [reportError] = await withCatch(this.appStateStore.api.reportMeetingStatus(response === 1));
    if (reportError) {
      log.error('Failed to report meeting status');
      log.error(reportError);
    }

    this.ongoingOperationsStore.performingMeetingCheck = false;
    this.timerStore.nextMeetingCheckAt = DateTime.now().plus({
      minutes: 1,
    });

    return this.activeJobStore.additionalDetails.inMeeting;
  };

  deleteImagesFromDisk = (captureGroup: CaptureGroup) => {
    captureGroup.screenshots.forEach((display: DisplayDefinition) => {
      remove(display.path)
        .then(() => {})
        .catch((error) => {
          log.error("Couldn't delete image from disk");
          log.error(error);
        })
        .finally(() => {});
    });
  };

  uploadToS3 = async (screens: GenericObject, captureGroup: CaptureGroup): Promise<void> => {
    for (const [index, screen] of screens.entries()) {
      let path = captureGroup.screenshots[index].path;
      path = `mti/` + path.split('mti/')[1];

      // const stream = await invoke<Uint8Array>('read_screenshot', { path });
      // THIS DOES NOT WORK? NO IDEA.
      const [readError, stream] = await withCatch(readFile(path, { baseDir: BaseDirectory.Temp }));

      if (readError) {
        log.error(`Failed to read screenshot ${path}`);
        log.error(readError);
        this.failedUploads.push(screen.mytimein_id);
        continue;
      }

      if (!stream || stream.length === 0) {
        log.error('Empty buffer for screen');
        this.failedUploads.push(screen.mytimein_id);
        continue;
      }

      if (!screen || !screen.uploadKey) {
        log.error('No upload key');
        continue;
      }

      const [s3Error] = await withCatch(this.appStateStore.api.uploadToS3(screen.uploadKey, stream, screen.compliance_mode));

      if (s3Error) {
        log.error('Failed to upload to S3');
        log.error(s3Error);
        this.failedUploads.push(screen.mytimein_id);
      }
    }
  };

  serverlessUpload = async (captureGroup?: CaptureGroup): Promise<void> => {
    if (!captureGroup || !captureGroup.screenshots || this.ongoingOperationsStore.uploadingScreenshots) {
      return;
    }

    this.ongoingOperationsStore.uploadingScreenshots = true;
    log.debug('Starting Screenshot Cycle');
    const [startScreenError, data] = await withCatch(this.appStateStore.api.startScreenshotCycle(captureGroup));

    if (startScreenError || !data) {
      log.error('Failed to start screenshot cycle');
      log.error(startScreenError);
      this.ongoingOperationsStore.uploadingScreenshots = false;
      return;
    }

    if (!this.activeJobStore.shouldTakeScreenshots) {
      this.ongoingOperationsStore.uploadingScreenshots = false;
      this.deleteImagesFromDisk(captureGroup);
      return;
    }

    const [s3Error] = await withCatch(this.uploadToS3(data, captureGroup));

    if (s3Error) {
      log.error('Failed to upload to S3');
      log.error(s3Error);
      this.ongoingOperationsStore.uploadingScreenshots = false;
      return;
    }

    if (this.failedUploads.length > 0) {
      log.error('Failures occurred during upload cycle');

      const payload = {
        abandoned: this.failedUploads.map((id) => ({ id })),
      };
      const [screenshotCycleError] = await withCatch(this.appStateStore.api.abandonScreenshotCycle(payload));

      if (screenshotCycleError) {
        log.error('Failed to abandon screenshot cycle');
        log.error(screenshotCycleError);
      } else {
        this.ongoingOperationsStore.uploadingScreenshots = false;
        this.deleteImagesFromDisk(captureGroup);
      }
    }

    // remove the failures form the display group
    captureGroup.screenshots = captureGroup.screenshots.filter((screen: DisplayDefinition) => {
      return !this.failedUploads.includes(screen.screen_id);
    });

    /**
     * Wait 30 seconds to tell MTI we completed the cycle.
     */
    setTimeout(async () => {
      const screenshots_that_successfully_uploaded = data.filter((screen: any) => {
        return !this.failedUploads.includes(screen.mytimein_id ?? -1);
      });

      const payload = {
        screen_data: screenshots_that_successfully_uploaded.map((screen: any) => {
          return {
            id: screen.mytimein_id,
          };
        }),
      };

      const [completeCycleError] = await withCatch(this.appStateStore.api.completeScreenshotCycle(payload));
      if (completeCycleError) {
        log.error('Failed to complete screenshot cycle');
        log.error(completeCycleError);
        this.ongoingOperationsStore.uploadingScreenshots = false;
        return;
      }

      this.ongoingOperationsStore.uploadingScreenshots = false;
    }, 1000 * 30);
  };

  loadGameList = () => {
    // TODO: Load games, is cache supported in this edgeview?
  };

  loadExcludedProcessList = () => {
    // TODO: Load excluded, is cache supported in this edgeview?
  };
}
