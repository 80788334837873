<script setup lang="ts">
  import DashboardWarning from '@components/Dashboard/DashboardWarning.vue';
  import { useAlertStore } from '@src/store/alert';
  import { onMounted } from 'vue';

  const alerts = useAlertStore();

  const clearWarning = (index: number) => {
    alerts.dashboardWarnings.splice(index, 1);
  };

  onMounted(() => {
    alerts.refreshWarnings();
  });
</script>

<template>
  <div class="space-y-3">
    <DashboardWarning v-for="(warning, index) in alerts.dashboardWarnings" :key="`warning-${index}`" :warning="warning" :index="index" @clear="clearWarning(index)" />
  </div>
</template>
